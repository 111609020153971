import {configure} from 'mobx';

import {enrollStore} from "./enroll";
import {loadingStore} from "./loading";
import {myEnrollStore} from "./enroll/myEnroll"

// configure({enforceActions: "always"});//严格模式
const store = {
    enrollStore,
    loadingStore,
    myEnrollStore,
};

export default store;
