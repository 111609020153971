import React, {Component} from 'react';
import Label from "./label";
import {List, Picker} from 'antd-mobile';
import IDataEntry from "./IDataEntry";

class Cascade extends Component {
	state = {
	    value: this.props.field_value,
		level:this.props.level,
	};
	
	constructor(props) {
	    super(props);
	}
	
	componentWillReceiveProps(nextProps) {
	    if (nextProps.field_value != this.state.value) {
	        this.setState({value: nextProps.field_value,level:nextProps.level});
	    }
	}
	
	onChange(value) {
	    this.props.onFieldChange(value, this.props.field_key);
	    this.setState({value: value});
	}
	
	getData() {
	    let result = [];
	    this.props.options.map((d) => {
	        result.push({
	            label: d.title,
	            value: d.title,
				children:d.children.map( data2 => {
					return {
						label: data2.title,
						value: data2.title,
						children:data2.children.map(data3 => {
							return {
								label: data3.title,
								value: data3.title,
								children:data3.children.map(data4 => {
									return {
										label: data4.title,
										value: data4.title,
									}
								})
							}
						})
					}
					
				})
			})
	    }); 
	    return result;
	}
	
	
	render() {
		if(this.state.value && this.state.value == '请选择'){
			this.state.value = [];
		}
	    return (
	        <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
	            <Picker extra="请选择(可选)" 
				data={this.getData()} 
				cols={this.state.level}
				value={this.state.value} 
				onChange={v => this.onChange(v)}
				disabled={this.props.disabled}
	            >
	                <List.Item arrow="horizontal">{this.props.field_name}</List.Item>
	            </Picker>
	        </List>
	    );
	}
}

Cascade.propTypes = IDataEntry;

export default Cascade