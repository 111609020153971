import React, {Component} from 'react';
import '../../../res/style/myEnroll.less';
import { withRouter } from 'react-router-dom'
import { ActionSheet, Modal, Toast } from 'antd-mobile';
import {inject, observer} from "mobx-react/index";
import Config from '../../../config';
import WxApi from '../../../common/wxapi';

import icon_arrow from "../../../res/img/ic_arrow_up.png";
import up_arrow from "../../../res/img/ic_arrow_down.png";
import arrow_right from "../../../res/img/right_arrow.png";
const defaultBanner = 'https://cdn-xcxcustom.weiyoubot.cn/20210705/b6049009536ef7dfd84b72c38281b7ae.png'
const defaultImg =  'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230613/7052e007942a1c1f16bc9fde16351652.png'
@inject("myEnrollStore", "enrollStore")
@observer

class EnrollBox extends Component{
    state = {
         enrollStats:['未开始','进行中','已截止'],
         isShow:false
    };
    constructor(props) {
	    super(props);
	};
    componentDidMount(){
        setTimeout(()=>{
            this.setState({
                isShow:true
            })
        },2000)
    };
    render(){
        return (
            <div>
                 {this.props.currentType < 3 ? this.myEnrollList() : this.myWathingList()}
            </div>
        )
    };
    // 我关注的
    myWathingList(){
        return (
            <div className='watch-container'>
                <div className={this.props.enrollList.length % 2 == 0?'watch-box':'watch-box father-box'}>
                {
                    this.props.enrollList.map((item)=>{
                            return (
                                <div className="wath-list" key={item.unionid} onClick={()=>{
                                    React.$wx.jumpNewPath.call(this,'/h/'+item.unionid)
                                }}>
                                    <div className="img-box" style={{backgroundImage:`url(${item.banner||defaultBanner})`}}> </div>
                                    <div className="watch-msg">
                                        {
                                            item.pic?<img src={item.pic} className='avatar-img'></img>:
                                            <div className='avatar-img'></div>
                                        }
                                        <div className="home-name">
                                            <div  className="enroll-num">{item.name}</div>
                                            <span>活动：{item.count}</span>
                                        </div>
                                        <img src={arrow_right} className="arrow-right"/>
                                    </div>
                                </div>
                            )
                    })
                }
                </div> 
                {
                    this.props.enrollList.length && this.state.isShow ?
                        <img className='recommend-img' onClick={()=>{//个人主页推荐页面
                            React.$wx.jumpNewPath.call(this,'/recommend')
                        }} src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20220121/61999a8131ffa9d71bcb76d0371ccf06.png" />
                        :
                        <div className='empty-box'>
                            <img className='empty-img' src='https://cdn-xcxcustom.weiyoubot.cn/20210728/72e3304756062c4b36c36b27b29b039c.png' />
                            <span>暂未关注任何发起人</span>
                            <p className='btn-home' onClick={()=>{                               
                                React.$wx.jumpNewPath.call(this,'/recommend')
                            }}>去逛逛精选主页></p>
                        </div>
                }
            </div>
        )
    };

    // 最近浏览&我管理的&我参与的 
    myEnrollList(){
      return (<div className='enroll-panel'>
                {
                    this.props.enrollList.map((item,index)=>{
                        let banner = item.pic_url?item.pic_url:item.banner
                        const bagStyle = {
                            background:"url("+(banner)+") no-repeat center center",
                            width:'100%',
                            height:'0px',
                            paddingBottom:'100%',
                            WebkitBackgroundSize:'cover',
                            MozBackgroundSize:'cover',
                            backgroundSize:'cover',
                            backgroundColor: '#eee',
                        }
                        return (
                            <div className='enroll-item' key={index} onClick={()=>{this.jumpToDetail(item)}}>
                                {
                                    item.await_del == 1 && this.props.userInfo.auth_status != 2 ? <span className="await-del-text">即将清理</span> : ''
                                }
                                <div className="main-box">
                                    <div className="enroll-left-panel">
                                        <div className='enroll-img'><div style={bagStyle}></div></div>
                                        <div className='enroll-body'>
                                            <div className='enroll-title'>{item.title}</div>
                                            <div className='enroll-count'>
                                                <div className="enroll-person-box count-item">
                                                    <img className='public-icon' src='https://cdn-xcxcustom.weiyoubot.cn/20210429/ab033c9aea518545541cea2b6b33734d.png' />
                                                    <label>{item.user_visible==0?'**':item.count}{item.limit?'/'+(item.user_visible?item.limit:'**'):""} </label>
                                                </div>
                
                                                {
                                                    item.fee && item.charge==0?
                                                    (<div className="enroll-price count-item">
                                                        <label>￥{item.fee/100}</label>
                                                    </div>):''
                                                }
                                            </div>
                                        
                                        </div>
                                    </div>
                                
                                    <div className="enroll-right-panel">
                                        {
                                            this.renderStatusButton(item)
                                        }                                       
                                    </div>
                                </div>
                                {/* 核销 */}
                                { this.props.currentType == 2?this.writeOffBox(item,index) :''}
                                        
                        </div>
                        )
                    })
                }
            </div>)
    };
    renderStatusButton(item){
        if(this.props.currentType ==2){ //我参与的
            return (<div className={item.status != 1?'box-red box-btn':'box-blur box-btn'}>
                    { this.state.enrollStats[item.status] }   
                </div>)
        }else{
            if(item.status == 1){
                return <div className="blur">进行中</div>
            }else if(item.status == 2){
                return <div className="red">已截止</div>
            }else{
                return <div className="gray">未开始</div>
            }
        }       
    }
    // 底部核销
    writeOffBox(item,index){
        return (
            <div className="enroll-bottom-box">
                <div className="write-box">                                      
                    {
                        this.renderButton(item,index)
                    }                                                    
                    <span className="blur message-btn cursor" onClick={(e)=>{
                        e.stopPropagation()
                        Config.EID = item.eid
                        this.props.history.push('/message')
                    }}>写留言</span>
                </div>

                {/*待核销列表 */}
                { item.isOpen && item.cert_length>1?
                item.cert_list.map((data,i)=>{
                    return  (<div className="write-item" key={i} onClick={(e)=>{
                            e.stopPropagation()
                            this.getCertImage(data)
                        }}>
                        <span>{data.name}</span>
                        <span className={data.verify_cancel?'':'red'}>{data.verify_cancel?'已核销':'未核销'} > </span>
                    </div>)
                }):''
                }
        </div>
        )
    }

    renderButton(item,index){
        if(!item.cert_length){
            return (
                <span className="red" >暂无凭证</span>
            )
        }else if(item.cert_length == 1 && item.cert_list[0].verify_cancel){
            return (
                <span className="blur cursor" onClick={(e)=>{
                    e.stopPropagation()
                    this.getCertImage(item.cert_list[0])
                }}>已核销 > </span>
            )
        }else if(item.cert_length == 1 && !item.cert_list[0].verify_cancel){
            return (
                <span className="red cursor" onClick={(e)=>{
                    e.stopPropagation()
                    this.getCertImage(item.cert_list[0])
                }}>未核销 > </span>
            )
        }else if(item.isOpen){
            return (
                <div className="flex-start-box blur cursor" onClick={(e)=>{
                    e.stopPropagation()
                    this.props.changeData(false,index)
                }}>收起 <img src={icon_arrow} className="icon-img"></img></div>
            )
        }else if(!item.isOpen){
            return (
                <div className="flex-start-box blur cursor" onClick={(e)=>{
                    e.stopPropagation()
                    this.props.changeData(true,index)
                }}>展开 <img src={up_arrow} className="icon-img"></img></div>
            )
        }   
    }

    showActionSheet(data){
        const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
        let wrapProps;
        if (isIPhone) {
            wrapProps = {
                onTouchStart: e => e.preventDefault(),
            };
        }
        const BUTTONS = ['公开到个人主页', '复制创建','取消'];
        ActionSheet.showActionSheetWithOptions({
            options: BUTTONS,
            cancelButtonIndex: BUTTONS.length - 1,
            destructiveButtonIndex: BUTTONS.length - 2,
            maskClosable: true,
            'data-seed': 'logId',
            wrapProps,
        },
        (buttonIndex) => {
            if(buttonIndex == 0){
                if(data.is_owner == 1){
                    Modal.alert('设置公开活动','设置公开后，所选活动将会显示在个人主页上面，方面大家通知查看',[
                        { text:'取消'},
                        { text: '确定', onPress: () => this.openActive(data) }
                    ])
                }else{
                    Toast.fail('非发起者无权进行此操作',2)  
                }
            }else if(buttonIndex == 1){

            }
        });
    };

    // 获取核销码
    getCertImage(data){
        Toast.loading('加载中')
        this.props.myEnrollStore.getEnrollCert(data.info_id).then(()=>{
            Config.EID = data.eid;              
            React.$wx.jumpNewPath.call(this,'/voucher')	
        })
    }

    openActive(data){
        console.log(this.props.myEnrollStore)
        const params = {
            eid:data.eid,
            type:1,
            send_msg:0
        }
        this.props.myEnrollStore.toPublicEnroll(params).then(res=>{
            if(res.sta == 0){
                Toast.success('设置成功')
            }else{
                Toast.fail(res.msg,2)  
            }
        })
    }

    jumpToDetail(e){
        Config.EID = e.eid
        sessionStorage.setItem('eId',Config.EID)

        const unionid = sessionStorage.getItem('unionid')
        Config.referer = unionid || ''
        sessionStorage.setItem('referer',Config.referer)
        
        Config.pathName = '/myEnroll'

        let jumpUrl = ''
        if(e.temp == 6){ //团购
            window._hmt.push(['_trackPageview',  '/group']);
            jumpUrl = '/group?eid='+ Config.EID+'&referer='+Config.referer
        }else{ //普通活动
            window._hmt.push(['_trackPageview', '/detail']);
            jumpUrl = '/detail?eid='+ Config.EID+'&referer='+Config.referer
        }
        React.$wx.jumpNewPath.call(this,jumpUrl)
    };
}

export default withRouter(EnrollBox);