import React, {Component} from 'react';
import Label from "./label";
import {List, ImagePicker} from 'antd-mobile';
import IDataEntry from "./IDataEntry";


class ImageSelect extends Component {
    state = {
        value: this.props.field_value?[...this.props.field_value] : []
    };

    constructor(props) {
        super(props);

    }

    getValue(d) {
        let result = [];

        if (d && d.length && d.length > 0) {
            d.map((item) => {
                result.push({
                    url: item,
                    id: item,
                })
            })
        }

        return result;

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value =='undefined'?'':nextProps.field_value});
        }

    }

    //点击图片触发的回调
    onImageClick(index, file) {
//      console.log(file);
        React.$wx.previewImage({
            current: file[index].url, // 当前显示图片的http链接
            urls: this.state.value // 需要预览的图片http链接列表
        });
    }

    //自定义选择图片的方法
    onAddImageClick(e) {
        e.preventDefault();
        let _this = this;
        React.$wx.uploadImage(this.props.img_type).then(d=>{
            if(d.sta == 0){
                if (!_this.state.value) {
                    _this.state.value = [];
                }
                _this.state.value.push(d.data.url);
                _this.setState({value: _this.state.value});
                _this.props.onFieldChange(_this.state.value, _this.props.field_key);
            }                   
        })
    }

    //选择失败
    onFail(msg) {

    }

    onChange(file, operationType, index) {
        // console.log(file, operationType, index);
        //删除图片
        if (operationType === "remove") {
            // e.preventDefault();

            this.state.value.splice(index, 1);
            this.setState({value: this.state.value});
            this.props.onFieldChange(this.state.value, this.props.field_key);

        }
    }


    render() {
        return (
            <List renderHeader={() => <Label {...this.props} />}>
                <ImagePicker
                    className={this.props.disabled ? "img-no-clear" : null}
                    files={this.getValue(this.state.value)}
                    onAddImageClick={this.onAddImageClick.bind(this)}
                    onImageClick={this.onImageClick.bind(this)}
                    onChange={this.onChange.bind(this)}
                    onFail={this.onFail.bind(this)}
                    selectable={this.state.value.length < 1 && !this.props.disabled}
                    multiple={false}
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                />
            </List>
        );
    }
}

ImageSelect.propTypes = IDataEntry;

export default ImageSelect