import React, {Component} from 'react';
import Label from "./label";
import {List,Drawer,SearchBar,Icon} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
class Select extends Component {
    state = {
        value:this.props.field_value,
        visible:false,
        searchVal:'',
        currOptions:[],
        oldOptions:[],
        currIndex:-1,
    };

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        let result = []
        this.is_new = this.props.new_options && this.props.new_options.length>0 ? 1 : 0
        if(this.is_new){
            result = this.props.new_options 
        }else{
            result = this.props.options.map(d=>{
                return { value: d,key: d}
            })
        }
        this.setState({currOptions:result,oldOptions:result})
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            let index = this.state.currOptions.findIndex(item=>{
                if(!this.is_new || nextProps.new_field_value === undefined){
                    return item.value == nextProps.field_value
                }else if(this.is_new){                                       
                    return item.key == nextProps.new_field_value
                }
            })

            let value = nextProps.field_value
            if(nextProps.new_field_value && index>-1){
                value = this.state.currOptions[index].value
            }
           
            this.setState({value,currIndex:index});
        }
    }

    render() {
        return (
            <div>
                <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                    {
                        !this.props.disabled ? <span className={`clear-select-data`} onClick={()=>{
                            this.props.onFieldChange('', this.props.field_key,'','');
                            this.setState({value:''})
                        }}>清空选项</span> : null
                    }                                   
                    <List.Item arrow="horizontal" onClick={()=>{     
                        if(this.props.disabled)return                 
                        let index = this.state.oldOptions.findIndex(item=>this.state.value == item.value)
                        this.setState({
                            visible:true,
                            currIndex:index,
                            searchVal:'',
                            currOptions:this.state.oldOptions
                        })
                    }}>{this.state.value !== '' ?this.state.value:'请选择'}</List.Item>
                </List>
                {
                    this.state.visible ? <Drawer className="my-drawer-select" open={this.state.visible} position="bottom"
                        style={{minHeight: document.documentElement.clientHeight }} sidebar={this.initDialog()}
                        onOpenChange={()=>{ this.setState({visible:false})}}>
                    </Drawer>:''
                }
            </div>
            
        );
    }

    initDialog(){
        return (
            <div className="main-select">
                <div className='flex-between-box'>
                    <button className='cancel-btn' onClick={()=>{this.setState({visible:false})}}>取消</button>
                    <button className='sure-btn' onClick={()=>{ this.handleSubmit() }}>确定</button>
                </div>
                <SearchBar className='cursor' value={this.state.searchVal} placeholder="输入关键词搜索" 
                    onChange={(e)=>{ this.setState({searchVal:e}) }} 
                    onClear={()=>{this.onCancel()}}
                    onCancel={()=>{this.onCancel()}}
                    onSubmit={()=>{this.onSearch()}}/>
                <div className="select-list">
                    <List>
                        {
                            this.state.currOptions.map((i, index) => {
                                return (
                                <List.Item className='cursor' key={index} onClick={(e)=>{
                                    e.stopPropagation();
                                    this.setState({currIndex:index})                                   
                                }}>
                                    <span className='select-text'>{i.value}</span>
                                    { this.state.currIndex == index ? <Icon type="check" className='check-icon' /> : '' }
                                </List.Item>);
                            })
                        }
                    </List>
                </div>
            </div>
           
        );
    }

    onSearch(){
        let result = []
        if(this.state.searchVal){
            result = this.state.oldOptions.filter(item=>{
                return item.value.indexOf(this.state.searchVal) !=-1
            })
        }else{
            result = this.state.oldOptions
        }
       
        this.setState({currOptions:result,currIndex:-1})
    }

    onCancel(){
        this.setState({
            currOptions:this.state.oldOptions,
            searchVal:'',
            currIndex:-1
        })
    }

    handleSubmit(){
        if(this.state.currIndex == -1){
            this.setState({visible:false})
            return
        }
    
        let currSelect = this.state.currOptions[this.state.currIndex]
        this.props.onFieldChange(currSelect.value, this.props.field_key,currSelect.key);
        this.setState({value:currSelect.value,visible:false});
    }
}

Select.propTypes = IDataEntry;

export default Select