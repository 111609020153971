import React, {useState } from 'react';
import { Icon,DatePicker, Toast} from 'antd-mobile';
import './index.module.less'
import WxApi from '../../../common/wxapi'

let flag = true //判断是否为新开的日历页面
const calendar = (props)=>{   
    let timespan = []
    let currentTimeZone = 0 - new Date().getTimezoneOffset() / 60
    if( props.timespan && props.timespan.length>0){
        timespan = props.timespan.map(item=>{   
            return {
                remain:props.time_stat[item._id].remain,
                date:item.date
            }
        })     
    }   
    let startDate = 0,endDate=0,highlight_time=0;
    if(props.type == 'date'){//普通日历，限制用户可选的开始和结束时间
        if(props.startDate){
            startDate = new Date(new Date(new Date(props.startDate).getTime()).setHours(0,0,0,0)).getTime()
        }
        if(props.endDate){
            endDate = new Date(new Date(new Date(props.endDate).getTime()).setHours(23,59,59,999)).getTime()
        }       
        if(props.highlight_time){
            highlight_time = new Date(new Date(new Date(props.highlight_time).getTime()).setHours(0,0,0,0)).getTime() 
        }        
    }
 
    //每月多少天
    let MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const WEEK_NAMES = ['日', '一', '二', '三', '四', '五', '六'];
    const LINES = [1, 2, 3, 4, 5, 6];
    const [year, setLoinsYear] = useState(0);
    const [month, seLoinstMonth] = useState(new Date(props.date).getMonth());
    const [currentDate, setcurrentDate] = useState(new Date());
    const [pickerTime,selectDate] = useState(new Date());
    const [tag, setTag] = useState(false);
    const [hasLeft,setLeft] = useState(true);
  
    if(month != new Date(props.date).getMonth() && flag){ //如下，是为了解决比如用户选择的八月份，但是打开日历仍然显示六月份的问题   
        setcurrentDate(new Date(props.date)) 
        seLoinstMonth(new Date(props.date).getMonth())
    }
    //获取当前月份
    const getMonth = (date) => {
        return date.getMonth();
    }
    //获取当前年份
    const getFullYear = (date) => {          
        return date.getFullYear();
    }
    const getCurrentMonthDays = (month, year) => {
        let _year = year + currentDate.getFullYear();
        if(_year % 100 != 0 && _year % 4 == 0 || _year % 400 == 0){
            MONTH_DAYS = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        }
        return MONTH_DAYS[month]
    }
    //当前月第一天是周几
    const getDateByYearMonth = (year, month, day=1)=> {          
        var date = new Date()
        date.setFullYear(year)
        date.setMonth(month, day)          
        return date
    }
    const getWeeksByFirstDay = (year, month) => {                 
        var date = getDateByYearMonth(year, month)        
        return date.getDay()
    }
    const getDayText = (line, weekIndex, weekDay, monthDays)=>{              
        var number = line * 7 + weekIndex - weekDay + 1
        if(number>31)return
        if (number <= 0 || number > monthDays) {
            return <div className="day-c" key={weekIndex}>&nbsp;</div>
        }
        
        let today = new Date(new Date().toDateString()).getTime() / 1000   
        
        let date = WxApi.getFullDate(year + currentDate.getFullYear(),month,number)
        if(props.type == 'date'){//填写日期格式的日历  
            let isLimit = false
            if(!props.startDate && !props.endDate) {
                isLimit = true
            }else if(props.startDate && props.endDate){
                isLimit = date*1000 >= startDate && date*1000 <= endDate
            } if(props.startDate && !props.endDate){
                isLimit = date*1000 >= startDate
            }else if(!props.startDate && props.endDate){
                isLimit = date*1000 <= endDate 
            }                                        
            return (
                <div className="day-c" key={weekIndex} onClick={()=>{
                    if(isLimit){
                        props.submitDate(date)
                    }                    
                }}>
                    {
                        highlight_time == date*1000 ? <div className="day cursor">{number}</div> :
                        <div className={isLimit ? 'day normal-day cursor' : 'day is-disabled'}>{number}</div>
                    }                                    
                </div>
            )                  
           
        }else{//预约项目的日历
            const date1 = new Date(date*1000);
            const year1 = date1.getFullYear();
            const month1 = date1.getMonth();
            const day1 = date1.getDate();

            let curIndex = timespan.findIndex(item=>{
                const date2 = new Date(item.date*1000);  //因为时区不同，所以只比较年月日
                const year2 = date2.getFullYear();
                const month2 = date2.getMonth();
                const day2 = date2.getDate();
                return year1 === year2 && month1 === month2 && day1 === day2;
            })

            let hasRemain = timespan.some(item=>{ //当前日期下面的时间段是否有剩余数量
                const date2 = new Date(item.date*1000);
                const year2 = date2.getFullYear();
                const month2 = date2.getMonth();
                const day2 = date2.getDate();
                const datesAreEqual = year1 === year2 && month1 === month2 && day1 === day2;
                return datesAreEqual && item.remain > 0;
            }) 
            let isShow = curIndex != -1 && date >= today //判断当前时间是否为日历上面的时间   
            let boxStyle = 'day-c'
            if(isShow && !hasRemain){ //已售罄               
                boxStyle = 'day-c day-gray'                         
            }else if(isShow){
                boxStyle = 'day-c day-focus'
            } 
            return (
                <div className={boxStyle} key={weekIndex} onClick={()=>{                  
                    if(isShow){    
                        if(props.time_limit && date >= props.time_limit){
                            Toast.info(`发起者设置为，仅能预约${props.day_limit}天内的日期`)
                        }else if(!hasRemain){
                            Toast.info('该时间段已满，不可预约')
                        }else{
                            flag = true
                            props.submitDate(timespan[curIndex].date)
                            
                        }                     
                    } 
                }}>
                    <div className={ isShow ? 'day cursor':'day is-disabled' }>{number}</div>
                    {
                        isShow && props.type == 'reverse' ? <div className="desc cursor">{hasRemain > 0 ? '可预约':'已满'}</div>:<div className="desc"> </div>
                    }            
                </div>
            )                  
        }      
    }
    const setCurrentYearMonth = (date) => {
        var month = getMonth(date)
        var year = getFullYear(date)
        setLoinsYear(year)
        seLoinstMonth(month)
        setTag(false)  
    }
 
    const monthChange = (monthChanged) => {
        let monthAfter = month + monthChanged //切换后的月份
        let yearthAfter = year + currentDate.getFullYear()//切换后的年份
        let currentMonth = new Date().getMonth()//当前月份
        let currenyYear = new Date().getFullYear() //当前年份
        if(props.type == 'reverse'){//仅预约日期限制箭头
            if (tag || (monthAfter < currentMonth && currenyYear>=yearthAfter)) {
                setLeft(false)
                return;
            } else {
                setTag(true)
                setLeft(true)
            }
        }
       
        flag=false
        var date = getDateByYearMonth(year, monthAfter)     
        setCurrentYearMonth(date)       

        //获取下一月份的第一天,传给父组件         
        if(monthAfter == 12){ //解决跨年后，可预约日期未展示问题
            yearthAfter += 1;         
            monthAfter = 0;
        }   
        let firstTime = WxApi.getFullDate(yearthAfter,monthAfter)  
        props.changeMonth(firstTime)
        let m = formatNumber(monthAfter)
        selectDate(new Date(yearthAfter+'/'+m+'/1 00:00:00') )            
    }

    // 切换日历时间
    const changeDate = (value)=>{
        let monthAfter = new Date(value).getMonth()
        let yearthAfter = new Date(value).getFullYear()
        setcurrentDate(new Date(value)) 
        seLoinstMonth(monthAfter)  
        let firstTime = WxApi.getFullDate(yearthAfter,monthAfter) 
        props.changeMonth(firstTime)
        let m = formatNumber(monthAfter)     
        selectDate(new Date(yearthAfter+'/'+m+'/1 00:00:00') )       
        flag=false
    }
    
    //  月份+1才是当前真正月份
    const formatNumber = (num)=> {
        return num + 1
    }
 
    let weekDay = getWeeksByFirstDay(year + currentDate.getFullYear(), month);
    return (
        <div className='loins-calendar'>
            <span className='bg-num'>{formatNumber(month)} </span>
            <div className='loins-calendar-tabbar'>
                <div className="icon-img" onClick={()=>{monthChange(-1)}} >
                    <Icon type="left" size="small" color={hasLeft?'#666':'#ccc'} />
                </div>                
                <div className="loins-calendar-title cursor">                   
                    <DatePicker mode="month" value={pickerTime} onChange={(e)=>{changeDate(e)}}>
                        <div>
                            <span>{year + currentDate.getFullYear()} 年 {formatNumber(month)}月</span>
                            <i className='iconfont icon-xiajiantou'></i>
                        </div>
                    </DatePicker>
                </div>
                <div className="icon-img" onClick={()=>{monthChange(1)}}>
                    <Icon type="right" size="small" color="#666" />
                </div>                
            </div>
            <div className='calendar-body'>
                {//周
                    WEEK_NAMES.map((week, key) => {
                        return <div className="title-c" key={key}>{week}</div>
                    })
                }                
                {//天
                    LINES.map((l, key) => {
                        return <div key={key} className="day-content">
                            {
                                WEEK_NAMES.map((week, index) => {
                                    return getDayText(key, index, weekDay, getCurrentMonthDays(month, year))
                                })
                            }
                        </div>
                    })
                }
            </div>
        </div>
    )
    
}

export default calendar