import React, {Component} from 'react';
import Label from "./label";
import {List, Radio,InputItem,Icon,Drawer,SearchBar} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import {inject, observer} from "mobx-react/index";

const RadioItem = Radio.RadioItem;

@inject("myEnrollStore")
@observer
class RadioInput extends Component {
	
	state = {
	    value: this.props.field_value,
		otherValue:this.props.field_value,
	    radioOption:[],
		isStart:true,
		radioIndex: -1,
		tmpArr:[],
		selectList:[],
		searchVal:'',
		currOptions:[],
		visible: false,		 
		currIndex: 0,
		radioItem: {},
	};
	
	async componentDidMount() {
		let that = this;
		that.is_new = 0
		if(that.props.field_type == 4){
			that.is_new = that.props.new_options && that.props.new_options.length>0			
			let tmpArr = await this.getTmpData()
			let field = this.props.myEnrollStore.filedList;
			let keyList = []
			field.forEach(element=>{
				if(element.viewSet){
					element.options.forEach(a=>{
						keyList = keyList.concat(a.field_key_arr)
					})
				}
			})

			field.forEach(element=>{
				if(keyList.indexOf(element.field_key) == -1){
					element.isHide = false
				}else{
					element.isHide = true
				}
			})

			that.setState({
				tmpArr,
				selectList:field,
			})
		}
	}
	
    constructor(props) {
        super(props);
    }
	
    async componentWillReceiveProps(nextProps) {  
        if (nextProps.field_value != this.state.value) {
			this.setState({value: nextProps.field_value});
			return true
        }else{
			//判断是否选择的其他选项
			let _index = -1; 
			let that = this;
			let tmpArr = await this.getTmpData()			
			if(!tmpArr||tmpArr == 0)return
			tmpArr.map((item,index) =>{			
				if(this.props.new_field_value === undefined && this.props.field_value !== undefined){ //多加这一步，是为了处理小程序未同步这个新功能，用户去小程序报名，然后H5上面new_field_value为空
					if(item.value == that.props.field_value){
						_index = index
						return
					}
				}else if(this.is_new){
					 if(item.key == that.props.new_field_value){
						_index = index
						return
					}
				}else if(item.id || item.key){
					if(item.value == that.props.field_value){
						_index = index;
						return
					}
				}else{
					if(item == that.props.field_value){
						_index = index;
						return
					}
				}
			})

			if(_index > -1){
				if(_index == this.props.radio_custom_index && this.state.value != '其他'){
					this.setState({
						radioIndex: _index,
						otherValue:this.state.value
					});
				}else{
					this.setState({
						radioIndex: _index,
						otherValue:''
					});
				}
				
			}else if(this.props.field_value){
				this.setState({
					radioIndex: this.props.radio_custom_index,
					otherValue:this.props.field_value,
				});
			}

		}

    }

	// 设置单选数据,之所以要封装这个，是为了避免页面上有多个单选数据，切涉及到跳题，数据会串了
	getTmpData(){
		let that = this
		return new Promise((resolve)=>{
			let tmpArr = []
			let o = that.is_new ? that.props.new_options : that.props.options							
			if(o.length){
				for (var j = 0; j < o.length + 1; j++) {
					if(o[j]){
						let params = {}						
						if(o[j].id || o[j].key){ //跳题或显示设置的单选
							 params = {
								value: that.is_new ? o[j].value : o[j].text, 							
								checked: 0,
								field_key:o[j].field_key || that.props.options[j].field_key,//部分之前的new_options数据没传field_key
								myKey:that.props.field_key, 
								hasSet:that.props.hasSet,
								viewSet:that.props.viewSet,
								field_key_arr:o[j].field_key_arr || that.props.options[j].field_key_arr,//部分之前的new_options数据没传field_key_arr
								key: o[j].key,
								id:o[j].id,
							}						 	
						}else{
							params = { 
								value: that.is_new ? o[j].value : o[j], 
								checked: 0,
								myKey:that.props.field_key,
								key: o[j].key
							}							
						}
					
						tmpArr.push(params)
					}
				}
			}
			resolve(tmpArr)
		})		
	}

    onRadioChange(item,index) {	 		 
		let value = item.value;
		let endIndex = 0;
		let currIndex = 0;
		let setRadoiItem = [];
		if(this.props.disabled){
			return;
		}
		let field = this.props.myEnrollStore.filedList;
		
    	if(this.state.radioIndex == index){ //取消选中
			this.setState({value: '',radioIndex: -1});
			this.props.onFieldChange('', this.props.field_key,'');
			endIndex = 0;
			this.clearAllData() //数据重置
			return;
    	}else{
			
			this.setState({value: value,radioIndex: index});
			this.props.onFieldChange(value, this.props.field_key,item.key);
			
			if(index == this.props.radio_custom_index){
				if(this.state.localOtherValue){
					this.setState({otherValue: this.state.localOtherValue});
				}
			}else{
				if(this.state.otherValue){
					this.setState({localOtherValue:this.state.otherValue});
				}
			}
		
			if(item.hasSet){//跳题设置	 			
				field.map((fobj,eIndex) =>{					
					if(fobj.field_key == item.field_key){
						item.endIndex = eIndex;
					}
				})
				 
				field.map((sobj,mIndex) =>{
					if(sobj.field_key == item.myKey){
						currIndex = mIndex;
						sobj.currIndex = mIndex;
						if(item.field_key == -9999){
							sobj.endIndex = field.length
						}else{
							sobj.endIndex = item.endIndex;
						}
					}else{
						if(sobj.ignore == 1 && sobj.field_type == 4 && sobj.hasSet){
							sobj.endIndex = -1;				 
						}
					}
					sobj.ignore = 0;
				 })
				 
				 setRadoiItem = field.filter(filed => filed.field_type === 4 && filed.hasSet);

				if(item.field_key){
					 if(item.field_key == -9999){
						endIndex = field.length;
					}
				} 	
			}
    	}

		if(item.viewSet){ //显示设置
			let field_key_arr = item.field_key_arr
			let targetIndex = this.state.selectList.findIndex(data=>data.field_key == item.myKey);
			let data = this.state.selectList.map((element,eIndex)=>{
				if(field_key_arr && field_key_arr.length>0){ //field_key_arr有值

					let i = field_key_arr.indexOf(element.field_key)
					if(i == -1 && element.isHide){ //在被标记的数据中进行匹配，如果key相等则显示
						if(eIndex > targetIndex){
							element.ignore = 1
						}
					}else{
						element.ignore = 0
					}

				}else{ //field_key_arr有值 为null
					if(element.isHide){
						element.ignore = 1
					}else{
						element.ignore = 0
					}
					
				}
				return element
			})
			this.props.myEnrollStore.setFiledList(data)
		}else if(item.hasSet){ //跳题设置
			field.map((obj,index) =>{
				 setRadoiItem.map((radio) =>{
					 if(index > radio.currIndex && index < radio.endIndex){
						 obj.ignore = 1;
						 obj.field_value = '';
						 if(obj.field_type == 4 && obj.field_key == radio.field_key){
							 radio.endIndex = -1;
						 }
					 }
				 })
			})
			
			this.props.myEnrollStore.setFiledList(field);
		}
		// else if(this.props.filedLogic && this.props.filedLogic.length){ //跳转逻辑设置		 
		// 	let bind_key = [];			
		// 	let father_key = []; //已经选中的单选，下面选择项包含的字段，也要显示	 	
		// 	let {filedInfo} = this.props.myEnrollStore;		 
		// 	for(let a = 0; a < this.props.filedLogic.length; a++){
		// 		let data = this.props.filedLogic[a];
		// 		if(data.field_key == this.props.field_key){
		// 			let option_info = data.option_info;						
		// 			for(let b = 0; b < option_info.length; b++){
		// 				if(option_info[b].key == item.key){
		// 					bind_key = data.bind_info.map(arr=>arr.field_key);
		// 				}
		// 			}
		// 		}
				
		// 		for(let i = 0; i < filedInfo.length; i++){ //这块逻辑主要是获取之前已选择的单选，下面包含的字段的key,解决多个逻辑设置，下面的逻辑会覆盖上面的逻辑
		// 			if(filedInfo[i].field_key == data.field_key){					
		// 				let option_info = data.option_info;		
		// 				for(let b = 0; b < option_info.length; b++){							 
		// 					if(filedInfo[i].field_value && option_info[b].key == filedInfo[i].new_field_value){							 						 						 						 
		// 						for(let i = 0; i < data.bind_info.length; i++){//把所以选项标记下
		// 							father_key.push(data.bind_info[i].field_key);
		// 						}
		// 					}
		// 				}				 
		// 			}
		// 		}
				
		// 	}

		// 	let bind_all_key = [];
		// 	for(let a = 0; a < this.props.filedLogic.length; a++){ //获取所有选项的key
		// 		let data = this.props.filedLogic[a].bind_info;
		// 		for(let b = 0; b < data.length; b++){
		// 			bind_all_key.push(data[b].field_key);
		// 		}
		// 	}
		// 	let targetIndex = this.state.selectList.findIndex(data=>data.field_key == item.myKey); //当前单选所在索引

		// 	let ignoreList = []
		// 	for(let a = 0; a < this.state.selectList.length; a++){
		// 		let selectList = this.state.selectList[a];		 
		// 		if(bind_key.indexOf(selectList.field_key) != -1){
		// 			selectList.ignore = 0;					 
		// 		}else{
		// 			if(a > targetIndex && (bind_all_key.indexOf(selectList.field_key) != -1)){ //在其他字段里面设置了，那就隐藏						 
		// 				selectList.ignore = 1;
		// 				if(selectList.field_type == 4){
		// 					this.props.filedLogic.forEach(data=>{
		// 						if(data.field_key == selectList.field_key){
		// 							for(let c = 0; c < data.bind_info.length; c++){
		// 								ignoreList.push(data.bind_info[c].field_key);
		// 							}							 
		// 						}
		// 					})
		// 					this.props.onFieldChange('', selectList.field_key,'');		
		// 				}										 	 				
		// 			}	
		// 			if(father_key.indexOf(selectList.field_key) != -1){						 
		// 				selectList.ignore = 0;	
		// 			}	
		// 			if(ignoreList.indexOf(selectList.field_key) != -1){
		// 				selectList.ignore = 1;	
		// 			}
		// 		}		
		// 	}

		// 	this.props.myEnrollStore.setFiledList(this.state.selectList);
		// }
    }
	
	renderOther(){
		return(
			<div className="other-input">
				<input />
			</div>
		)
	}
    
	getFocus(value,index){
		if(this.state.radioIndex != index){
			this.setState({radioIndex: index});
			this.onRadioChange(value,index);
		}
	}
	
	onChangeOther(value,item){
		this.setState({value: value,otherValue:value});
		this.props.onFieldChange(value, this.props.field_key,item.key);
	}

	// 清空数据
	clearAllData(){
		let that = this;
		let options = that.is_new ? that.props.new_options : that.props.options
		let selectKey = []
		if(that.props.hasSet){ //跳题还原
			selectKey = options.map(item=>item.field_key)
			if(selectKey.length>0){
				this.state.selectList.forEach(item=>{
					if(selectKey.indexOf(item.field_key) !=-1 || !item.isHide){//不是跳题设置的选项且一开始就是显示的
						item.ignore = 0
					}else if(item.isHide){
						item.ignore = 1
					}
				})
			}
		}else if(that.props.viewSet){ //显示设置还原
			options.forEach(item=>{
				selectKey = selectKey.concat(item.field_key_arr)
			})
			let targetIndex = this.state.selectList.findIndex(data=>data.field_key == this.props.field_key);
			
			let new_arr = [];
			for(let a = 0; a < this.state.selectList.length; a++){ //获取显示设置题目下面绑定的题目
				if(this.state.selectList[a].viewSet){
					let data = this.state.selectList[a].new_options.map(item=>item.field_key_arr)
					new_arr = new_arr.concat(data);
				}
			}
			new_arr = [].concat(...new_arr)
	 
			if(selectKey.length>0){
				this.state.selectList.forEach((item,index)=>{				 
					if(selectKey.indexOf(item.field_key) !=-1){ //只要是显示设置的选项，都隐藏						 
						item.ignore = 1
					}else if(index > targetIndex && new_arr.indexOf(item.field_key) != -1){ //显示设置下面包含的题目，也要隐藏						 
						item.ignore = 1
					}else if(!item.isHide){ //非显示设置的，则根据初始状态显示
						item.ignore = 0
					}
				})
			}
		}
		// else if(this.props.filedLogic && this.props.filedLogic.length){ //逻辑设置
		// 	let bind_all_key = []; 
		// 	for(let a = 0; a < this.props.filedLogic.length; a++){ //获取所有选项的key
		// 		let data = this.props.filedLogic[a].bind_info;
		// 		for(let b = 0; b < data.length; b++){
		// 			bind_all_key.push(data[b].field_key);
		// 		}
		// 	}
		// 	let logicIndex = this.props.filedLogic.findIndex(item=>item.field_key == this.props.field_key)			 
		// 	let father_key = [];
		// 	let {filedInfo} = this.props.myEnrollStore;
		// 	for(let a = 0; a < this.props.filedLogic.length; a++){ //这块逻辑主要是获取其他选项下绑定的单选，避免点击清空时，把其他选项下面的字段也清空了
		// 		let data = this.props.filedLogic[a];
		// 		for(let i = 0; i < filedInfo.length; i++){
		// 			if(filedInfo[i].field_key == data.field_key){					
		// 				let option_info = data.option_info;		
		// 				for(let b = 0; b < option_info.length; b++){							 
		// 					if(filedInfo[i].field_value && option_info[b].key == filedInfo[i].new_field_value && this.props.field_key != filedInfo[i].field_key){							 						 						 						 
		// 						for(let c = 0; c < data.bind_info.length; c++){//把所以选项标记下
		// 							father_key.push(data.bind_info[c].field_key);
		// 						}
		// 					}
		// 				}				 
		// 			}
		// 		}
		// 	}
		 
		// 	if(bind_all_key.indexOf(this.props.field_key) != -1  || logicIndex != -1){
		// 		let targetIndex = this.state.selectList.findIndex(data=>data.field_key == this.props.field_key); //当前单选所在索引
		// 		for(let a = 0; a < this.state.selectList.length; a++){
		// 			let selectList = this.state.selectList[a];	
		// 			if(a > targetIndex && bind_all_key.indexOf(selectList.field_key) != -1){ //在其他字段里面设置了，那就隐藏				 
		// 				selectList.ignore = 1;
		// 			}				
		// 			if(father_key.indexOf(selectList.field_key) != -1){
		// 				selectList.ignore = 0;
		// 			}
		// 		}
		// 	}			
		// }

		 this.setState({value: '',radioIndex: -1});
		 this.props.onFieldChange('', this.props.field_key,'');
		 this.props.myEnrollStore.setFiledList(this.state.selectList);
	}

    render() {
		let that = this;
        return (
			<div>
				<List className={this.props.radio_style == 1 ? "enroll-label" : ''} renderHeader={() => <Label {...this.props} />}>
					{
						!this.props.disabled ? <span className={`clear-select-data`} onClick={()=>{
							that.clearAllData()
						}}>清空选项</span> : null
					}					
					{
						this.props.radio_style == 1 ?
						<List.Item arrow="horizontal" onClick={()=>{                        					 
							this.setState({
								visible:true,
								currIndex: this.state.radioIndex,
								searchVal:'',
								currOptions:this.state.tmpArr
							})
						}}>{this.state.value !== '' ? this.state.value : '请选择'}&nbsp;</List.Item>
						:
						that.state.tmpArr.map(function(item, index){
							return(
								<div key={index}>
									<div className="radio-choose-item" onClick={() => { that.onRadioChange.bind(that)(item,index)}}>
										{that.state.radioIndex == index ? <i className="select" /> : <i className="un-select" />}
										<span>{item.value}</span>
									</div>
									{
										that.props.radio_custom_index == index ? <InputItem className="other-input"
										value={that.state.otherValue}
										type={"text"}
										onChange={(value,index) => that.onChangeOther(value,item)}
										onFocus={() => that.getFocus(item,index)}
										/> : ''
									}
									
								</div>)
						})
					} 					
				</List>
				{
                    this.state.visible ? <Drawer className="my-drawer-select" open={this.state.visible} position="bottom"
                        style={{minHeight: document.documentElement.clientHeight }} sidebar={this.initDialog()}
                        onOpenChange={()=>{ this.setState({visible: false})}}>
                    </Drawer>:''
                }
			</div>
           
        );
    };
	initDialog(){
        return (
            <div className="main-select">
                <div className='flex-between-box'>
                    <button className='cancel-btn' onClick={()=>{this.setState({visible: false})}}>取消</button>
                    <button className='sure-btn' onClick={()=>{						 
						let index = this.state.tmpArr.findIndex(item=>item.key == this.state.radioItem.key);						
						if(index > -1 && this.state.currIndex > -1){							 
							this.onRadioChange.bind(this)(this.state.radioItem,index);
							this.setState({visible: false, radioIndex: index});
						}else{
							this.setState({visible: false});
						}					 						
					}}>确定</button>
                </div>
                <SearchBar className='cursor' value={this.state.searchVal} placeholder="输入关键词搜索" 
                    onChange={(e)=>{this.setState({searchVal: e})}} 
                    onClear={()=>{this.onCancel()}}
                    onCancel={()=>{this.onCancel()}}
                    onSubmit={()=>{this.onSearch()}}/>
                <div className="select-list">
                    <List>
                        {
                            this.state.currOptions.map((item, index) => {
                                return (
                                <List.Item className='cursor' key={index} onClick={(e)=>{
                                    e.stopPropagation();								 
                                    this.setState({currIndex: index, radioItem: item})                                   
                                }}>
                                    <span className='select-text'>{item.value}</span>
                                    { this.state.currIndex == index ? <Icon type="check" className='check-icon' /> : '' }
                                </List.Item>);
                            })
                        }
                    </List>
                </div>
            </div>           
        )
    };
	onCancel(){
		this.setState({
            currOptions:this.state.tmpArr,
            searchVal:'',
            currIndex:-1
        })
	};
	onSearch(){
        let result = [];
        if(this.state.searchVal){
            result = this.state.tmpArr.filter(item=>{
                return item.value.indexOf(this.state.searchVal) != -1;
            })
        }else{
            result = this.state.tmpArr;
        } 
       
        this.setState({currOptions:result,currIndex:-1});
    }
}

RadioInput.propTypes = IDataEntry;

export default RadioInput