import React, {Component} from 'react';
import Label from "./label";
import {List, Checkbox,InputItem} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import WxApi from '../../common/wxapi';

const CheckboxItem = Checkbox.CheckboxItem;

class CheckInput extends Component {
    state = {
        value: this.props.field_value,
        otherValue:this.props.field_value,
        checkList:[],
        currOptions:[],
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount(){   
        this.is_new = this.props.new_options && this.props.new_options.length>0
        let checkList = await this.getTmpData()      
        this.setState({
            currOptions:WxApi.clone(checkList),
            checkList:WxApi.clone(checkList),  
            otherValue:this.props.other_value     
        })          
    }

    async componentWillReceiveProps(nextProps) {  
        if (nextProps.field_value != this.state.value) {            
            this.setState({value: nextProps.field_value});
            return true
        }else{                                                 
            let checkList = await this.getTmpData()         
            this.setState({ 
                checkList:WxApi.clone(checkList),
                otherValue:nextProps.other_value,
                currOptions:WxApi.clone(checkList),
            })
        }
    }

    // 设置多选数据
    getTmpData(){
        return new Promise((resolve)=>{
            let currOptions = this.is_new ? this.props.new_options : this.props.options  
            currOptions = currOptions.map(item=>{
                let obj = {}
                if(this.is_new){
                    obj = { value:item.value,key:item.key}
                }else{
                    obj = { value:item,key:item}
                }
                return obj
            })                                
            let checkList = currOptions.map((item)=>{              
                let obj = WxApi.clone(item)                      
                if(this.state.value){
                    if(this.is_new){
                        let currValue = []
                        if(this.props.new_field_value === undefined && this.state.value !== undefined || (this.props.field_value && !this.props.new_field_value)){ //多加这一步，是为了处理小程序未同步这个新功能，用户去小程序报名，然后H5上面new_field_value为空
                            currValue = this.state.value
                            obj.isChecked = this.state.value.indexOf(item.value) !=-1

                        }else{
                            currValue = this.props.new_field_value
                            obj.isChecked = currValue.indexOf(item.key)!=-1
                        }                    
                    }else{
                        obj.isChecked = this.state.value.indexOf(item.value) !=-1
                    }            
                }else{
                    obj.isChecked = false
                }
                return obj
            })

            if(this.state.value){
                let data = checkList.filter(item=>item.isChecked)
                if(data.length<this.state.value.length && this.props.radio_custom_index){ //选中了其他选项，且内容是自定义的
                    checkList[this.props.radio_custom_index].isChecked = true
                }
            }           
            resolve(checkList)
        })
    }

    //label
    onChange(value,index,isChecked){
        let result = this.state.value || [];
        if(result){
            if(result.indexOf(value) == -1 && !isChecked){
                result.push(value)              
                this.state.checkList[index].isChecked = true
            }else{
                result.splice(value, 1);
                this.state.checkList[index].isChecked = false
            }
            let data = this.state.checkList.filter(item=>item.isChecked)
         
            let keyList = data.map(item=>item.key)
            let valueList = data.map(item=>item.value)
            this.props.onFieldChange(valueList, this.props.field_key,keyList,this.state.otherValue);
            this.setState({
                checkList:this.state.checkList
            })
        }   
    }

    // 输入的值
	onChangeOther(value,index){
        if(value){//有值则取值
            this.state.checkList[index].value = value
        }else{//没值则取其他
            this.state.checkList[index].value = this.state.currOptions[index].value
        }
        
        this.state.checkList[index].isChecked = true
        let data = this.state.checkList.filter(item=>item.isChecked)
        let valueList = data.map(item=>item.value)
        let keyList = data.map(item=>item.key)
        this.props.onFieldChange(valueList, this.props.field_key,keyList,value);
		this.setState({
            otherValue:value,
            checkList:this.state.checkList
        });
	}

    render() {

        return (
		    
            <List renderHeader={() => <Label {...this.props}/> }>
                {
                    !this.props.disabled ? <span className={`clear-select-data`} onClick={()=>{                       
                        this.props.onFieldChange([], this.props.field_key,[]);
                        this.state.checkList = this.state.checkList.map(item=>{
                            item.isChecked = false;
                            return item;
                        });
                        this.setState({value:'', checkList: this.state.checkList});
                    }}>清空选项</span> : null
                }				
                {
					this.state.currOptions && this.state.currOptions.length > 0 ? this.state.currOptions.map((item, index) => {
                    let isChecked = false;
                    if(Array.isArray(this.state.value) && this.state.value.length && (this.state.value.findIndex((i) => i === item.value) != -1)){
                        isChecked = true
                    }else if(this.state.checkList[index] && this.state.checkList[index].isChecked){  //其他选项有值
                        isChecked = true
                    }
             
                    return(
                        <div className="select-checkbox-box">
                            <CheckboxItem key={index}
                                checked={!!isChecked}
                                disabled={this.props.disabled}
                                onChange={() => this.onChange.bind(this)(item,index,isChecked)}>
                                <span>{item.value}</span>
                            </CheckboxItem>
                            {
                                this.props.radio_custom_index == index ? <InputItem className="other-input"
                                value={this.state.otherValue}
                                type={"text"}
                                onChange={(value) => this.onChangeOther(value,index)}
                                /> : ''
							}
                        </div>
                    )
                }) : null
					
				}
            </List>
        );
    }
}

CheckInput.propTypes = IDataEntry;

export default CheckInput