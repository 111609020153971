import React, {Component} from 'react';
import Label from "./label";
import {List, TextareaItem} from 'antd-mobile';
import IDataEntry from "./IDataEntry";


class Textarea extends Component {
    state = {
        value: this.props.field_value
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }

    }

    onChange(value) {
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({value: value});
    }


    render() {
        return (
            <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                <TextareaItem
                    placeholder="点此输入"
                    onChange={(value) => this.onChange(value)}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    rows={5}
                    count={1000}
                />
            </List>
        );
    }
}

Textarea.propTypes = IDataEntry;

export default Textarea