import React, {Component} from 'react';
import Label from "./label";
import {List, DatePicker} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import BaseServer from "../../services/BaseServers";

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);


class TimeSelect extends Component {

    state = {
        value: this.props.field_value
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }

    }

    onChange(value) {
        let v = BaseServer.formatDate(value, "HH:mm")
        this.props.onFieldChange(v, this.props.field_key);
        this.setState({value: v});
    }

    getTime(time) {
        if (time && time.indexOf(":") > 0) {
            let timeArr = time.split(":");
            if (timeArr.length >= 2) {
                now.setHours(timeArr[0]);
                now.setMinutes(timeArr[1]);
                return now;
            }
        }
        return null;
    }

    render() {


        return (
            <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                <DatePicker
                    mode="time"
                    title={this.props.field_name}
                    format={"HH:mm"}
                    disabled={this.props.disabled}
                    value={this.getTime(this.state.value)}
                    onChange={this.onChange.bind(this)}
                >
                    <List.Item arrow="horizontal">{this.props.field_name}</List.Item>
                </DatePicker>
            </List>
        );
    }
}

TimeSelect.propTypes = IDataEntry;

export default TimeSelect