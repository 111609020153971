export default class BaseServers {
    static checkServerData(data, defaultData = {}) {
        if (data && data.status === 200 && data.data && data.data.sta === 0 && data.data.data) {
            return data.data.data;
        }
        else {

            return defaultData;

        }
    }

    static checkSuccess(data) {
        return data.data || {};
    }

    static formatDate(source, format, type) {
        if (!source) {
            return "";
        }
        source = isNaN(source) ? source : new Date(source);
        let o = {
            'M+': source.getMonth() + 1, // 月份
            'd+': source.getDate(), // 日
            'H+': source.getHours(), // 小时
            'm+': source.getMinutes(), // 分
            's+': source.getSeconds(), // 秒
            'q+': Math.floor((source.getMonth() + 3) / 3), // 季度
            'f+': source.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (source.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
		if(type == 'detail'){
			var year = new Date().getFullYear();
			var sourceYear = source.getFullYear();
			if(year == sourceYear){
				format = format.substring(5,format.length)
			}
		}
		
		
        return format || "";
    }
    //获取当前日期n天后的日期
    static dayAdd(num, currDay){
        let curr = null;
        if (currDay) {
            curr = new Date(currDay);
        } else {
            curr = new Date();
        }
        
        return new Date(curr.valueOf() + 24 * 60 * 60 * 1000 * num);
    }
    static jsonSort(arr,key){
        for(var j = 0; j < arr.length -1; j++){
            for(var i = 0; i < arr.length - 1 - j; i++){
                if(arr[i][key] > arr[i+1][key]){
                    var tmp;
                    tmp = arr[i];
                    arr[i] = arr[i+1];
                    arr[i+1] = tmp;
                }
            }
         }
        return arr
    }
}