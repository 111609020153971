import React, {Component} from 'react';
import WxApi from '../../common/wxapi';
class WriterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: ('ontouchstart' in window) ? ['touchstart', 'touchmove', 'touchend'] : ['mousedown', 'mousemove', 'mouseup'],
            cxt: null
        }
    };
    componentDidMount() {
        this.cxt = this.canvas.getContext("2d");
        if(WxApi.isMobile()){
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight * 0.6;
        }else{ //电脑浏览器的画布尺寸固定
            this.canvas.width = 450;
            this.canvas.height = 300;
        }       
        this.cxt.strokeStyle = "#000";
        if(this.props.sign_bg_config && this.props.sign_bg_config.url){ //有背景图,则画布设置为透明
            this.cxt.fillStyle = 'rgba(255, 255, 255, 0)'; 
        }else{
            this.cxt.fillStyle = '#fff';
        }        
        this.cxt.fillRect(0, 0, 99999, 99999);
        this.cxt.lineWidth = 1;
        this.cxt.lineCap = 'round'; // 线条末端添加圆形线帽，减少线条的生硬感
        this.cxt.lineJoin = 'round'; // 线条交汇时为原型边角
        // 利用阴影，消除锯齿
        this.cxt.shadowBlur = 1;
        this.cxt.shadowColor = '#000';
        this.is_drawed = false;
        if(WxApi.isMobile()){ //手机上面绘制签名            
            this.canvas.addEventListener("touchstart", function (e) { //开始绘制
                e.preventDefault();
                this.cxt.beginPath();
                const evt = e.touches[0];
                const coverPos = this.canvas.getBoundingClientRect();
                this.cxt.moveTo(evt.clientX - coverPos.left, evt.clientY - coverPos.top);
            }.bind(this), false);
           
            this.canvas.addEventListener("touchmove", function (e) { //绘制中
                e.preventDefault();
                const evt = e.touches[0];
                const coverPos = this.canvas.getBoundingClientRect();
                this.is_drawed = true
                this.cxt.lineTo(evt.clientX - coverPos.left, evt.clientY - coverPos.top);
                this.cxt.stroke();
            }.bind(this), false);
            
            this.canvas.addEventListener("touchend", function (e) { //结束绘制
                e.preventDefault();
                this.cxt.closePath();
            }.bind(this), false);

        }else{ //电脑浏览器上绘制签名
           
            let begainPaint = false           
            this.canvas.addEventListener("mousedown", function (e) { //开始绘制
                begainPaint = true          
                e.preventDefault();
                this.cxt.beginPath();
                const evt = e;           
                const coverPos = this.canvas.getBoundingClientRect();                             
                this.cxt.moveTo(evt.clientX - coverPos.left, evt.clientY - coverPos.top)
            }.bind(this), false);
           
            this.canvas.addEventListener("mousemove", function (e) { //绘制中          
                e.preventDefault();
                if(begainPaint){
                    const evt = e;               
                    const coverPos = this.canvas.getBoundingClientRect();                                                
                    this.cxt.lineTo(evt.clientX - coverPos.left, evt.clientY - coverPos.top)
                    this.cxt.stroke();
                    this.is_drawed = true;
                }           
            }.bind(this), false);
            
            this.canvas.addEventListener("mouseup", function (e) { //结束绘制       
                e.preventDefault();
                this.cxt.closePath();
                begainPaint = false;
            }.bind(this), false);
        }  
    };
    getImg() {      
        return this.canvas.toDataURL("image/png");
    };
    clearImg() {
        this.is_drawed = false;
        this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
    };
    render() {
        return (
            <div className="page">
                <canvas ref={canvas => (this.canvas = canvas)} style={{backgroundColor: "#ffffff",cursor:"pointer"}}/>              
            </div>
        );
    }
}

export default WriterPage;


