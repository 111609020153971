// 提交前阅读和提交前口令组件
import React, {Component} from 'react';
import { Flex,Button,Toast,InputItem} from 'antd-mobile';

import {inject, observer} from "mobx-react/index";
@inject("myEnrollStore", "enrollStore")
@observer
class SubmitNotice extends Component {
    state = {
        tokenPanel:'none',
        showReadDialog:false,//提交前阅读弹窗
        token:'',
        countDown:5,
    };
    componentDidMount(){
        this.props.onRef(this);
    };
    render(){
        let {detail} = this.props;        
        return (
            <div>
                {this.renderTokenPanel(detail)}
                {this.renderSubmitRead(detail)}
            </div>
        )
    };
    //口令输入框
	renderTokenPanel(detail){
		return(
			<Flex className="Detail-join-token" style={{display:this.state.tokenPanel}}>
				<div className="share-choice-mask" onClick={() => {this.setTokenType('none') }}></div>
					<div className="minProgram-panel">
					<div className="read-main">
						<div className="panel-title">输入口令</div>
						{detail.has_token == 1&&detail.token_desc ? <div className="cen-token" style={{whiteSpace:'pre-wrap'}}>口令获取方法：{detail.token_desc}</div> : null}
						<div className="token-input-panel">
							<InputItem className="token-input" value={this.state.token} onChange={(value) => this.onChange(value)} placeholder='请输入口令' />
						</div>
						<div className="sure-btn-panel flex-start-box">
							<Button className="btn-cancel" onClick={() => { this.setTokenType('none') }} >取消</Button>
							<Button className="btn-get-token" type="primary" onClick={() => { this.submitToken(1,detail) }} >确定</Button>
						</div>
						{/* <div className="token-desc">活动发起人要求必须输入正确口令才能报名,如有问题,请与TA联系。 </div> */}
					</div>
				</div>
			</Flex>
		)
	}
	
	//提交前阅读框
	renderSubmitRead(detail){
		return(
			<div>
				{this.state.showReadDialog?( 
				<Flex className="Detail-join-token">
				<div className="share-choice-mask" onClick={()=>{this.setReadDialog(false)}}></div>
				<div className={`minProgram-panel read-box` }>
					<div className="read-main">
						<div className="panel-title">{detail.token_desc.title}</div>
						<div className='read-content'>
							<p className="read-text" style={{whiteSpace:'pre-wrap'}}>{detail.token_desc.content}</p>							
							{
								detail.token_desc &&  detail.token_desc.pics && detail.token_desc.pics.length ? 
								detail.token_desc.pics.map(item=>{
									return <img className='read-img' src={item.url} onClick={()=>{
										let pics = detail.token_desc.pics.map(item=>item.url);
										React.$wx.previewImage({
											current: item.url, // 当前显示图片的http链接
											urls: pics // 需要预览的图片http链接列表
										})
									}}></img>
								}) : ''
							}							
						</div>
						{ detail.token_desc.feedback == 2 ?
							<div>
								<div className="token-input-panel">
								<InputItem className="token-input" value={this.state.token} placeholder={`请手动输入“${detail.token_desc.reaction}”`} onChange={(value) => this.onChange(value)}/>
								</div>
								<div className="sure-btn-panel">
								<Button className="btn-get-token" type="primary" onClick={() => { this.submitToken(2,detail) }} >确定</Button>
								</div>
							</div>
							:
							<div className="sure-btn-panel">
								{detail.token_desc.feedback == 0?
								<Button className="btn-get-token" type="primary" onClick={() => { this.handleSubmit(detail) }} >我已知晓</Button>
								:
								<Button className="btn-get-token" type="primary" disabled={this.state.countDown>0} onClick={() => { this.handleSubmit(detail) }} >我已知晓{this.state.countDown ==0?'':`(${this.state.countDown})`}</Button>
								}
							</div>
						}
					</div>
				</div>
			</Flex>)
			:null}
		</div>
		)
	};
    // 检测是否有提交前阅读或者口令    
    checkHasToken(){
        return new Promise((resolve)=>{
            let {detail} = this.props;    
            if(detail.has_token == 1){
                this.setTokenType('block');
                detail.token_desc = detail.token_desc.replace(/\n/g, '\n');
            } else if(detail.has_token == 2){
                detail.token_desc.content = detail.token_desc.content.replace(/\n/g, '\n');
                this.setReadDialog(true);
                this.timer = setInterval(()=>{
                    if(this.state.countDown<=0){
                        this.state.countDown = 0
                        clearInterval(this.timer);
                    }else{
                        this.state.countDown--;
                    }
                    this.setState({ countDown:this.state.countDown});
                },1000)
            }else{
                resolve();
            }
        })        
    };
	setTokenType(type){
		this.setState({
	        tokenPanel: type,
	    })
	};
    setReadDialog(type){
		clearInterval(this.timer);
		this.setState({
			showReadDialog:type,
		})
	};
    onChange(value){
		this.setState({
	        token: value,
	    })
	};
    submitToken(type){
		this.props.myEnrollStore.subToken(this.state.token).then(() => {
			let {tokenPass} = this.props.myEnrollStore;
			if(tokenPass){
				this.setTokenType('none');
				this.setState({
					showReadDialog:false,
					token:''
				})
                this.props.onSuccess();
			}else{
				if(type == 1){
					Toast.fail('口令错误', 2);
				}else{
					Toast.fail('输入内容错误', 2);
				}
				
			}
		});
	};	
	handleSubmit(){
		this.setState({
			showReadDialog:false
		})
        this.props.onSuccess();
	}
}

export default SubmitNotice;