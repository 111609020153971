import React from 'react';
import ReactDOM from 'react-dom';
import '../src/res/style/index.less';
import '../src/res/style/page.less';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd-mobile/dist/antd-mobile.css';
import Config from "./config";
import WxApi from "./common/wxapi";
import wx from './common/wxPackage'
React.$wx = wx
  

//Config.EID = WxApi.getQueryString("eid");
let eId = WxApi.getQueryString("eid");
let referer = WxApi.getQueryString("referer");

//订阅通知
let action = WxApi.getQueryString("action");
let reserved = WxApi.getQueryString("reserved");
let openid = WxApi.getQueryString("openid");
let scene = WxApi.getQueryString("scene");
let source = WxApi.getQueryString("source");
if(action =='confirm' && reserved){
	sessionStorage.setItem("notice",'notice');
	if(source == "group"){
		window.location.href = Config.url_notice + "?&eid="+reserved+"&openid="+openid+"&scene="+scene + "/group";
	}else{
		window.location.href = Config.url_notice + "?&eid="+reserved+"&openid="+openid+"&scene="+scene + "/detail";
	}
	
}

/*let min_appid = WxApi.getQueryString("appid");
let min_tmpid = WxApi.getQueryString("tmp_id");
if(min_appid){
	Config.min_appid = min_appid;
}
if(min_tmpid){
	Config.min_tmpid = min_tmpid;
}*/


if(eId && eId.length>24){
	Config.EID = eId.substr(0,24);
}else{
	Config.EID = eId;
}
if(Config.EID){
	sessionStorage.setItem('eId',Config.EID);
}
if(!Config.EID){
	Config.EID = sessionStorage.getItem('eId');
}

if(referer){
	Config.referer = referer;
	sessionStorage.setItem('eId',Config.EID);
}else{
	Config.referer = sessionStorage.getItem('referer') || '';
}


const bm = 'baominggongju.com';
let url = WxApi.urlencode(window.location.href);
if (url.indexOf(bm) > -1) {
    Config.bm_domain = true;  // 域名是baominggongju.com时，修改此变量值
}

Config.TOKEN = WxApi.getToken();
Config.MCHID = WxApi.getMchId();
//WxApi.configApi();
//WxApi.configApiBusiness();


if (Config.TOKEN) {

    start();
}
else {
	start();
	
//  let hasCode = WxApi.jumpWxAuth();
    
//	let hasCode = WxApi.jumpBusinessAuth();

    /*if (hasCode) {
        hasCode.then(() => {
            start();
        });
    }*/
}

//控制台监听告警
process.env.NODE_ENV === "production" && Sentry.init({
	dsn: "https://2f19539612094662891c836d34c91432@sentry.weiyoubot.cn/6",
	integrations: [new Integrations.BrowserTracing()],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});
 
 
function start() {
	// WxApi.configApi();
    ReactDOM.render(<App/>, document.getElementById('root'));
    serviceWorker.unregister();
}
