import PropTypes from 'prop-types';

const IDataEntry = {
    field_key: PropTypes.any.isRequired,
    field_type: PropTypes.any.isRequired,
    field_name: PropTypes.string.isRequired,
    require: PropTypes.number.isRequired,
    field_desc: PropTypes.string,
    onFieldChange: PropTypes.func.isRequired
};

export default IDataEntry;