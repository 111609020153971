import React, {Component} from 'react';
import Label from "./label";
import {List, ImagePicker, Modal,Button,Toast} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import WritePage from "./wirter";
import {withRouter} from 'react-router-dom';
import html2canvas from 'html2canvas';

function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

class Writeable extends Component {
    state = {
        value: this.props.field_value,
        sign_text:'',
        isLoading:false,
        dragBoxHeight:200
    };
    constructor(props) {
        super(props);       
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }
    };
    getValue(d) {
        let result = [];
        if (d) {
            result.push({url: d,id: d})
        }
        return result;
    };
    setValue(value) {
        this.setState({ value: value});
        this.props.onFieldChange(value, this.props.field_key);
    };
    //点击图片触发的回调
    onImageClick(index, file) {
        React.$wx.previewImage({
            current: file[index].url, // 当前显示图片的http链接
            urls: [file[index].url] // 需要预览的图片http链接列表
        });
    };
    //自定义选择图片的方法
    onAddImageClick(e) {
        e.preventDefault();
        this.showModal("modal")(e);
    };
    showModal = key => (e) => {
        e.preventDefault();
        this.setState({[key]: true});
    };
    onClose = key => (e) => {
        this.setState({[key]: false,});
    };
    onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    };
    onChange(file, operationType, index) {
        if (operationType === "remove") {
            this.setState({value: ""});
            this.props.onFieldChange("", this.props.field_key);
        }
    };
    render() {
        return (
            <List renderHeader={() => <Label {...this.props} />}>
                <div style={{position: 'relative'}}>
                    <ImagePicker
                        files={this.getValue(this.state.value)}
                        className={this.props.disabled ? "img-no-clear" : null}
                        onAddImageClick={this.onAddImageClick.bind(this)}
                        onImageClick={this.onImageClick.bind(this)}
                        selectable={this.getValue(this.state.value) == 0 && !this.props.disabled}
                        onChange={this.onChange.bind(this)}
                        multiple={false}
                        // length={1}                        
                    />
                    {
                        this.props.sign_bg_config && this.props.sign_bg_config.url && !this.state.value ?
                        <div className='sign-example-image' onClick={()=>{
                            React.$wx.previewImage({
                                 current: this.props.sign_bg_config.url,
                                 urls: [this.props.sign_bg_config.url]
                            }) 
                         }}>
                             <img className='sign-img' src={this.props.sign_bg_config.url}></img>
                             <span className='sign-text'>签名材料</span>
                         </div>  : ''
                    }                                                         
                </div>               
                <Modal
                    visible={this.state.modal}
                    transparent
                    maskClosable={false}
                    closable={true}
                    onClose={this.onClose('modal')}
                    className={"writer-page"}
                    title={"请在下方空白处手写"}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}>
                    <div>
                        {
                            !this.state.sign_text ? <WritePage sign_bg_config={this.props.sign_bg_config} ref={(ref) =>this.writer = ref}/> : ''
                        }                           
                        {
                            this.props.sign_bg_config && this.props.sign_bg_config.url && this.state.sign_text ? 
                            <div className="sign-box" id="signDom" style={{height:this.state.dragBoxHeight+'px'}}>
                                <img className="sign-image" src={this.props.sign_bg_config.url} />  
                                {
                                    this.renderText()//签名文本
                                }
                            </div> :''
                        } 
                        {
                            !this.state.sign_text ?
                            <div className="footer-panel">
                                <Button type="primary" className="operate-btn" onClick={()=>{
                                    if(!this.writer.is_drawed){
                                        Toast.info('请在空白处手写');
                                        return;
                                    }                                  
                                    const img = this.writer.getImg.bind(this.writer)();
                                    if(this.props.sign_bg_config && this.props.sign_bg_config.url){ //有背景图
                                        this.getBoxHeight();
                                        this.setState({
                                            sign_text: img
                                        })
                                    }else{ //无背景直接上传
                                        this.setState({isLoading:true});
                                        Toast.loading();
                                        this.props.onUploadBase(img).then(((d) => {
                                            this.setValue(d.data.url);
                                            Toast.hide();
                                            this.setState({sign_text:'',isLoading:false});
                                            this.onClose('modal')();
                                        }));
                                    }                                        
                                }}>确定</Button>
                                <Button className="operate-btn" onClick={()=>{
                                    this.writer.clearImg.bind(this.writer)()                                  
                                }}>清除</Button>
                            </div>  
                            : 
                            <Button type="primary" className="save-btn" disabled={this.state.isLoading} onClick={async ()=>{
                                this.setState({isLoading:true});
                                Toast.loading();
                                let img = await this.canvasToImage();                                   
                                this.props.onUploadBase(img).then(((d) => {
                                    this.setValue(d.data.url);
                                    this.setState({sign_text:'',isLoading:false});
                                    Toast.hide();
                                    this.onClose('modal')();
                                }));
                            }}>保存</Button> 
                        }                                              
                    </div>                   
                </Modal>
            </List>
        )
    };
    renderText(){
        let config = this.props.sign_bg_config;
        const signStyle = {
            width:`${76*config.scale}px`,
            height:`${110*config.scale}px`,
            transform:`rotate(${config.rotate}deg)`,
            position:'absolute',
            left:`${config.x}px`,
            top:`${config.y}px`,
            objectFit:'contain',
        }
       return <img style={signStyle} src={this.state.sign_text} />
    };
    // 容器的高度 = （ 345px屏幕下图片的高度 + 600）/2
    getBoxHeight(){
        let that = this;
        let img = new Image();
        img.src = this.props.sign_bg_config.url;              
        img.onload = function(){                                                          
            let imgH = (345 * img.height)/img.width; 
            let dragBoxHeight = (imgH + 600)/2;
            that.setState({dragBoxHeight});                                                                                                       
        }       
    };
    // cnavas转为图片
    canvasToImage(){
        return new Promise((resolve)=>{
            const vocheRBox = document.getElementById('signDom');                                               
            html2canvas(vocheRBox,{
                backgroundColor: "#ffffff",
                logging: false,
                allowTaint: true,  //开启跨域
                useCORS: true,
                scale:2,
                width:vocheRBox.offsetWidth,
                height:vocheRBox.offsetHeight
            }).then(canvas=>{
                let context = canvas.getContext('2d');
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;
                context.imageSmoothingEnabled = false;
                let img_png_src = canvas.toDataURL("image/png"); //将canvas保存为图片              
                resolve(img_png_src);                                                       
            })
        })
    }
}

Writeable.propTypes = IDataEntry;
export default withRouter(Writeable);