import React, {Component} from 'react';

export default class Label extends Component {
    render() {
        return (

            <div>
                <span className="gray label-width">{this.props.field_name || "未知字段名"}</span>
                {
                    this.props.require ? <span className="Data-entry-require">*</span> : null
                }
                {
                    this.props.field_type != 18 && this.props.field_type != 0 ? this.renderNumber() : null //最大值，最小值
                }
                {
                    this.props.field_desc ? <label className="field-desc label-width">{this.props.field_desc}</label> : ''                    
                }               
                {   this.props.demo_img && (this.props.field_type == 14 || this.props.field_type == 2) ? 
                    <span className="demo-img field-desc" onClick={()=>{
                        React.$wx.previewImage({current:this.props.demo_img,urls:[this.props.demo_img]})
                    }}>【查看示例图】
                    </span> : '' }
            </div>			
        )
    }

    renderNumber(){
        let has_max_value = !(!this.props.max_value && this.props.max_value !==0)
        let has_min_value = !(!this.props.min_value && this.props.min_value!==0) 
        let textStyle = {
            fontSize:"10px",
            color:'#888',
            marginLeft:'5px'
        }
        if(has_min_value && has_max_value){
            return <span style={textStyle}>(请输入{this.props.min_value}~{this.props.max_value}之间的数字)</span>
        }else if(has_min_value){
            return <span style={textStyle}>(请输入大于{this.props.min_value}的数字)</span>
        }else if(has_max_value){
            return <span style={textStyle}>(请输入小于{this.props.max_value}的数字)</span>
        }
    }
}