import React, {Component} from 'react';
import Label from "./label";
import {List, Tag} from 'antd-mobile';
import IDataEntry from "./IDataEntry";


class TagSelect extends Component {
    state = {
        value: this.props.field_value
    };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }

    }

    onChange(value, selected) {
        value = selected ? value : "";
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({value: value});
    }

    getData() {
        let result = [];
        this.props.options.map((d) => {
            result.push({
                label: d,
                value: d
            })
        })
        return result;
    }

    render() {
        return (
            <List className="tag-container" renderHeader={() => <Label {...this.props} />}>

                <List.Item>
                    {this.props.options.map((item, index) => (
                        <Tag key={index}
                             selected={this.state.value == item}
                             disabled={this.props.disabled}
                             onChange={(selected) => {
                                 this.onChange(item, selected)
                             }}>
                            {item}
                        </Tag>
                    ))}
                </List.Item>

            </List>
        );
    }
}

TagSelect.propTypes = IDataEntry;

export default TagSelect