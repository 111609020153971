import React, {Component} from 'react';
import Factoy from "../../components/DataEntry/factory";
import {inject, observer} from "mobx-react/index";
import {WhiteSpace, Button, Toast, InputItem,Flex} from 'antd-mobile';
import Config from "../../config";
import WxApi from "../../common/wxapi";

//图片引入
import img_title_left from "../../res/img/ic_title_left.png";
import img_title_right from "../../res/img/ic_title_right.png";
import img_item_left from "../../res/img/ic_benifit.png";

@inject("myEnrollStore", "enrollStore")
@observer
class App extends Component {
	
	state = {
		authStatus:null,
		userInfo:{},
		isWeixin:true,
		showTips: 'none',
	}
	
	componentDidMount() {
		let _this = this;
		if(!Config.TOKEN){
			let isWeixin = WxApi.isWeixn()
			this.setState({isWeixin:isWeixin})
			const code = WxApi.getQueryString("code");
			const isOldUser = localStorage.getItem('isOldUser'); //微信曾经授权过报名工具的用户
			if(isWeixin){
				if(code || isOldUser){
					WxApi.jumpWxAuth();
				}				
			}else if(WxApi.isMobile()){			
				const params = {
					path:'/subpackage/authentication/authentication',
					expire_time:WxApi.getDate(new Date(),2),
					scene_id:'desc=personal_banner_auth&from=personal&content='
				}
				this.props.myEnrollStore.getSchemeUrl(params).then(res=>{ //获取跳转微信的短链链接
					if(res.sta == 0){						
						this.setState({scheme_ticket:res.data})
					}
				})								
			}else{
				React.$wx.webLogin()
			}			
		}else{
			WxApi.configApi();
			this.props.enrollStore.getUserInfo().then(() => {
				let {userInfo} = _this.props.enrollStore;
				if(userInfo){
					WxApi.setShare('报名工具—发起人认证', '', 'https://p.baominggongju.com/auth', '')
					this.setState({
					    authStatus: userInfo.auth_status,
						userInfo:userInfo,
						code_status:userInfo.code_status,
						renew_code_status:userInfo.renew_code_status
					})
					sessionStorage.setItem('authType',userInfo.auth_type)
					sessionStorage.setItem('Renew',userInfo.renew_status)
					if(userInfo.auth_type==4 && userInfo.code_status==0 && userInfo.auth_status == 1){
						this.setState({
							viewCodeInput:''
						})
					}
					
					let token = WxApi.getQueryString("tk");
					let init_zhini = sessionStorage.getItem('init_zhini');
					if(token && init_zhini != 1){					 				 
						window._ZHINI({
							action:"init",  // 初始化开始指令；
							client_id:userInfo.openid   // 用户的唯一标识，由您自行生成，必填且不能为无效字符，否则不生效;
						});	
						sessionStorage.setItem('init_zhini', 1);
					}
				}
			});
			
		}

		//判断模板消息认证来源
		let href = window.location.href
		let routerArr = href.split('?')
		if(routerArr.length>1){
			if(routerArr[1].indexOf('from=tpl_msg_renew') != -1){
				sessionStorage.setItem('sourceType','tpl_msg_renew')

			}else if(routerArr[1].indexOf('from=tpl_msg_auth') != -1){
				sessionStorage.setItem('sourceType','tpl_msg_auth')

			}else{
				sessionStorage.setItem('sourceType','official_account_menu')
			}
		}
	}
	
	renderHeader(userInfo){
		let authType = ['个人','企业','组织','公众号']
		
		if(this.state.authStatus == 4){
			return(
				<div className="auth-status-panel">
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<span className='user-name'>{userInfo.name}</span>
							<span className="status-name">VIP过期</span>
						</div>
					</Flex>
					<div className="auth-loser">请及时续费，保持认证权益的正常使用</div>
				</div>
			)
		}else if((userInfo.auth_type==4 && this.state.code_status==0 && this.state.authStatus == 1) || (userInfo.renew_type==4 && this.state.renew_code_status==0 && userInfo.renew_status == 2)){
			return(
				<div className="auth-status-panel">
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<div className='user-name'>{userInfo.name}</div>
							<span className="status-name them-color">输入审核验证码</span>
						</div>						
					</Flex>
					<div className="auth-loser">审核人员会稍后发送数字验证码，请在电脑端登录你的公众号后台-左侧-私信-搜索“验证码”获取</div>
				
					<div className="input-panel">
						<InputItem
							className="input-item"
							value={this.state.viewCodeInput}
							placeholder={"请填写验证码"}
							clear
							onChange={(v) => { 
								this.setState({
									viewCodeInput : v
							}) }}
						  ></InputItem>
						  <Button className="sure-btn" type="primary" onClick={() => {
						  	this.validateCode(userInfo)
						  }} >确定</Button>
					</div>
				</div>
			)
		}
		else if(this.state.authStatus == 1 || this.state.userInfo.renew_status == 2){
			return(
				<div className="auth-status-panel">
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<div className='user-name'>{userInfo.name}</div>
							<span className="status-name them-color">VIP审核中</span>
						</div>						
					</Flex>
					<div className="auth-loser">半个工作日内完成审核，还请耐心等待</div>
					<div className='warn-text' onClick={()=>{
						window.open('https://mp.weixin.qq.com/s/7xoT8N4jJuR36X3UoZEQVg')
					}}>点击关注<span className='them-color'>「知你报名消息」</span>公众号，及时接收审核结果通知</div>
				</div>
			)
			
		}else if(this.state.authStatus == 3 || this.state.userInfo.renew_status == 4){
			return(
				<div className="auth-status-panel">
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<div className='user-name'>{userInfo.name}</div>
							<span className="status-name">认证失败</span>
						</div>						
					</Flex>
					<div className="auth-loser" style={{'color': '#FF6C60'}}>失败原因：{this.state.authStatus==3 ? this.state.userInfo.reason : this.state.userInfo.renew_reason}</div>
				</div>
			)
		}
		else if(this.state.authStatus == 2){
			const miniBtnStyle = {
				width: '120px',
				height: '30px',
				position: 'absolute',
				top: '20px',
				left: '10px',
				zIndex: 2
			}
			return(
				<div className="auth-status-panel" style={{'textAlign': 'center'}}>
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<div className='user-name'>{userInfo.name}</div>
							<span className="status-name">已通过</span>
						</div>						
					</Flex>
					<div className="auth-loser">{authType[this.state.userInfo.auth_type -1]}VIP有效期到：<span className={userInfo.renew_status ==1 ?"active":null}>{this.state.userInfo.expire}</span></div>
					<div className="flex-center-box">
						<div style={{'position': 'relative'}}>
							<button className="view-auth-btn" onClick={()=>{
								this.props.history.push('/transfer')
								// if(!WxApi.isWeixn() || !WxApi.isMobile()){
								// 	this.props.history.push('/transfer')							 
								// }								
							}}>数据资产交接</button>
							{/* {
								WxApi.isWeixn() && WxApi.isMobile() ? 
								<wx-open-launch-weapp
									style={miniBtnStyle}
									id="launch-btn"
									username="gh_ae6449501a82"
									path={'/subpackage/viptransfer/viptransfer.html'}
								>
									<script type="text/wxtag-template">
										<div style={miniBtnStyle}></div>
									</script>
								</wx-open-launch-weapp> : ''
							} */}
						</div>
						<button className="view-auth-btn primary-btn" onClick={()=>{					
							this.props.history.push('/auth-detail?uid='+userInfo.unionid)
						}}>查看VIP信息</button>
					</div>					
				</div>
			)
		}
		else if(userInfo && userInfo.unionid){
			return(
				<div className="auth-status-panel">
					<Flex className="auth-header" justify={"center"}>
						<img className="status-icon" src={userInfo.logo || Config.defaultAvatar} />
						<div>
							<span className='user-name'>{userInfo.name}</span>
							<span className="status-name">未升级VIP</span>
						</div>						
					</Flex>
					<div className="auth-tips">【报名工具】是一款好用、强大的在线活动发起软件。如果你是活动发起方,可以通过认证获得额外功能和权益。</div>
				</div>
			)
		}
	}
	
	validateCode(userInfo){
		if(!this.state.viewCodeInput){
			Toast.fail("请输入验证码", 2);
			return
		}
		
		if(this.state.viewCodeInput.length != 4){
			Toast.fail("请输入正确的验证码", 2);
			return
		}
		sessionStorage.setItem('Renew',userInfo.renew_status)
		let code = this.state.viewCodeInput
		this.props.myEnrollStore.validateCode(code).then((d) => {
			if(d.sta == 0){
				Toast.success('验证成功', 1);
				this.setState({
					code_status:1,
					renew_code_status:1
				})
			}else{
				Toast.fail(d.msg, 2);
			}
		})
	}
	/**
	 * status 0未认证 1审核中  2已认证  3认证失败  4 过期
	 *  renew_status 1未续费，2续费进行中，3已续费成功, 4续费失败
	 * */
	renderButton(userInfo){
		sessionStorage.setItem('Auth_status',userInfo.auth_status)
		if(this.state.authStatus < 1 && userInfo && userInfo.unionid){
			return(
				<div className="foot-panel">
					<Button className="foot-btn" type="primary" onClick={() => {
	                    this.props.history.push('/personal');
	                }} >立即提交</Button>
				</div>
			)
		}
		else if(this.state.authStatus == 1 || userInfo.renew_status == 2){
			return(
				<div className="foot-panel">
					<Button className="foot-btn" type="primary"  disabled >审核中...</Button>
				</div>
			)
		}else if(userInfo.renew_status == 1 || userInfo.renew_status == 4 || (this.state.authStatus == 2 && userInfo.renew_status != 2)){ //未续费 or 续费失败 or 已认证但是未续费
			return(
				<div className="foot-panel">
					<Button className="foot-btn" type="primary" onClick={() => {
						let Renew = userInfo.renew_status;
						if (this.state.authStatus == 2) { //已认证的
							Renew = 4
							sessionStorage.setItem('Auth_status',2)
                        }else if(userInfo.renew_status == 4 || this.state.authStatus == 3){ //续费失败或者认证失败 ，为重新提交，则也不显示续费
							sessionStorage.setItem('Auth_status',userInfo.renew_status)
						}
						sessionStorage.setItem('Renew',Renew)
				        this.props.history.push('/personal');
				    }} >{userInfo.renew_status == 4 || this.state.authStatus == 3 ? '重新提交' : '续费'}</Button>
				</div>
			)
		}
		else if(this.state.authStatus == 4 || this.state.authStatus == 3 || userInfo.renew_status == 4){//认证过期 or 认证失败 or 续费失败
			return(
			<div className="foot-panel">
				<Button className="foot-btn" type="primary" onClick={() => {
						sessionStorage.setItem('Renew',userInfo.renew_status)
	                    this.props.history.push('/personal');
	                }}>{this.state.authStatus == 4 ? '续费':'重新提交'}</Button>
			</div>
			)
		}else{
			return(
				<div className="foot-panel">
					<Button className="foot-btn" type="primary" onClick={() => {
						if(!this.state.isWeixin){
							window.location.href = this.state.scheme_ticket;
						}else{
							React.$wx.webLogin()
						}			            
			        }} >立即提交</Button>
				</div>
			)
		}
	}
	
	 render() {
		 let {userInfo} = this.props.enrollStore;
		 let auth_list = [
			{title: '人数无上限', desc: '单个活动默认上限1000人次，升级VIP后无限制人次参与。'},
			{title: '更高的收款额度', desc: '未升级VIP单笔收费限额100元，总额3000元；升级VIP后单笔限额10万，总额1000万。'},
			{title: '自定义头像和名称', desc: '升级VIP后可自定义展示的头像、名称、简介和背景图，更有机会被收录进入精选主页',id:3 },
			{title: '个性化小程序码和二维码', desc: '小程序码和二维码中间logo可替换为自定义头像。' },
			{title: '去除推广展示',desc:'升级VIP后创建的活动中除开屏外不会展示任何推广内容，页面干净清爽。'},
			{title: 'H5网页端报名链接',desc:'专属H5网页报名链接，可直接点击链接填写信息、支付并保存，数据与小程序端保持同步。'},
			{title: '邀请统计数据',desc:'可查看用户分享活动的明细数据，包括每个人通过分享邀请到了具体哪些用户。'},
			{title: '支持插入短视频和高清图', desc: '活动封面和活动说明中支持上传15秒短视频和高清图，展示效果更丰富。' },
			{title: '满额后排队', desc: '可开启排队功能，当名额达到上限后，参与者可以提交信息排队。' },
			{title: '自定义提交后推广位', desc: '可将微信号、公众号、微信群等任意图文信息添加在用户提交后的页面展示，增加推广曝光。' },
			// {title: '设置黑名单',desc:'可设置无限黑名单，限制黑名单用户再次参与你发起的任何活动。'},
			{title: '数据长期保存', desc: '无需担心数据丢失或无法访问，历史数据会长期保存在云端供您随时免费下载。' },
			{title: '信息格式和重复校验', desc: '手机号、身份证号等字段支持自动去重和格式校验，防止重复多次填写，提升收集准确率。' },
			{title: '支持插入文件', desc: '活动描述中可直接插入Word，PPT，Excel，PDF格式文件，方便点击查看和下载。' },
			{title: '多管理员协同', desc: '升级VIP后管理员可设置最多50人（未升级VIP上限3人）' },
			{title: '更多群发通知次数',desc:'未升级VIP时单个活动仅能发送一次微信服务通知；升级VIP后，单个活动可发送多达五次微信服务通知。'},
			{title: '会员管理',desc:'支持会员和会员卡管理，收会员费，设置仅会员参与的活动，单个项目设置普通价和会员价。'}
		];
		 
		 return(
			<div className="auth-app-panel">
				{
					this.renderHeader(userInfo)
				}
                {
                    this.renderWxCode()
                }
				<div className="auth-use-info">
					<div className="heaer-info"><img src={img_title_left} /><h1>大家都在用</h1><img src={img_title_right} /></div>
					<div className="auth-use-body">
						<div className="use-item">
							<div className="item-name">企业</div>
							<div className="item-num">12700+</div>
						</div>
						<div className="use-item">
							<div className="item-name">组织</div>
							<div className="item-num">4900+</div>
						</div>
						<div className="use-item">
							<div className="item-name">公众号</div>
							<div className="item-num">3200+</div>
						</div>
						<div className="use-item">
							<div className="item-name">个人</div>
							<div className="item-num">8600+</div>
						</div>
					</div>
				</div>
				
				<div className="auth-box">
					<div className="heaer-info"><img src={img_title_left} /><h1>VIP有效期内享有以下权益</h1><img src={img_title_right} /></div>
					{
						auth_list.map((item, index) => {
							return(
								<div className="auth-item">
									<img className="status-icon" src={img_item_left} />
									<div className="item-body">
										<h2 className="item-title">{item.title}{item.id == 3 ? <a className='example-text' href="https://mp.weixin.qq.com/s/lqtkb9V_YXOI3bA_3swqEQ" target="_blank">查看示例> </a> :""}</h2>
										<p className="item-desc">{item.desc}</p>
									</div>
								</div>
							)
							
						})
					}
				</div>
				{
					this.renderButton(userInfo)
				}
				<div className='customer-box'>
					<div className='img-panel' onClick={()=>{
						window._ZHINI({action: 'showPanel'})
					}}>
						<img className='customer-img' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230804/6da2c3821e1f674a92fe8acd0ef8a1ec.png"></img>
						<span>客服</span>
					</div>					
				</div>
			</div>
		 )
		 
	 };
     renderWxCode(){
     	 if(!WxApi.isMobile()){
     	 	return (
				  <div className="advert-box-right">
					  <div className="wx-code-box">
						<div className="wx-qrcode">
							<img src="https://cdn-xcxcustom.weiyoubot.cn/20200609/3c5cda78ba2db0e514c2ce7e625865db.png"></img>
						</div>
						<span>微信扫一扫</span>
						<span className="wx-watch">完成认证</span>
     	 			</div>
				  </div>
     	 	)
     	 }
     }
}
export default App;