/*
* 0 文本框
* 1 数字
* 2 图片
* 3 日期
* 4 单选
* 5 复选
* 6 时间
* 7 多行文本
* 8 地图位置
* 9 手写
* 10 下拉框
* 11 标签
* 12 省市区
* 15 级联
* */


// {
//     "status" : true,
//     "field_type" : 4,
//     "require" : 1,
//     "field_len" : 50,
//     "field_name" : "性别",
//     "options" : [
//     "保密",
//     "男",
//     "女"
// ]
// }
// const mock = [
//     {
//         "field_key": 1,
//         "status": true,
//         "field_type": 0,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "文本框1-必填",
//         "field_value": "文本框值"
//     },
//     {
//         "field_key": 2,
//         "status": true,
//         "field_type": 0,
//         "require": 0,
//         "field_len": 50,
//         "field_name": "文本框2-非必填",
//         "field_value": "文本框非必填"
//     },
//     {
//         "field_key": 3,
//         "status": true,
//         "field_type": 1,
//         "require": 0,
//         "field_len": 50,
//         "field_name": "数字输入-非必填",
//         "field_value": "100"
//     },
//     {
//         "field_key": 4,
//         "status": true,
//         "field_type": 2,
//         "require": 1,
//
//         "field_name": "图片上传"
//     },
//     {
//         "field_key": 5,
//         "status": true,
//         "field_type": 3,
//         "require": 1,
//         "field_value": new Date(),
//         "field_name": "日期选择器"
//     },
//     {
//         "field_key": 6,
//         "status": true,
//         "field_type": 4,
//         "require": 1,
//         "field_name": "单项选择",
//         "options": [
//             "保密",
//             "男",
//             "女"
//         ]
//     },
//     {
//         "field_key": 7,
//         "status": true,
//         "field_type": 5,
//         "require": 1,
//         "field_name": "复选框",
//         "options": [
//             "复选1",
//             "复选2",
//             "复选3"
//         ]
//     },
//     {
//         "field_key": 8,
//         "status": true,
//         "field_type": 6,
//         "require": 1,
//         "field_value": new Date(),
//         "field_name": "时间选择器"
//     },
//     {
//         "field_key": 9,
//         "status": true,
//         "field_type": 7,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "多行文本"
//     },
//     {
//         "field_key": 10,
//         "status": true,
//         "field_type": 8,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "家庭住址"
//     },
//     {
//         "field_key": 11,
//         "status": true,
//         "field_type": 9,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "手写输入"
//     },
//     {
//         "field_key": 12,
//         "status": true,
//         "field_type": 10,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "下拉框",
//         "options": [
//             "下拉框1",
//             "下拉框2",
//             "下拉框3"
//         ]
//     },
//     {
//         "field_key": 13,
//         "status": true,
//         "field_type": 11,
//         "require": 1,
//         "field_len": 50,
//         "field_name": "标签",
//         "options": [
//             "标签1",
//             "标签2",
//             "标签3"
//         ]
//     },
// ];

import TextInput from "./text";
import Number from "./number";
import ImagePicker from "./image";
import ImageSingle from "./imageSingle";
import DataPicker from "./date";
import Radio from "./radio";
import Checkbox from "./checkbox";
import Time from "./time";
import Textarea from "./textarea";
import Write from "./write";
import Select from "./select";
import Province from "./province";
import Cascade from "./cascade";
import Tag from "./tag";
import Describe from './description';
import SelectCalendar from "./selectCalendar";
import Team from './team/team';
import File from './file';
import Score from './score';
import React from 'react';
import {inject, observer} from "mobx-react/index";
import {toJS} from "mobx";

@inject("myEnrollStore")
@observer
class Factory extends React.Component {

    constructor(props) {
        super(props)
    }


    onUpload(media_id) {
        return this.props.myEnrollStore.uploadImage(media_id);
    }

    onUploadBase(base64) {
        return this.props.myEnrollStore.uploadBase64(base64);
    }

    onFieldChange(value, field_key,new_field_value,other_value) { 
        let otherValue = other_value
        if(otherValue){ //如果有自定义值，但是没选中其他，则清除自定义值
            if(value.indexOf(otherValue) == -1){
                otherValue = ''
            }
        }
        this.props.myEnrollStore.setInfo(field_key, value,new_field_value,otherValue);
        setTimeout(()=>{
            this.changeLogicData(field_key, new_field_value)
        }, 500)
    }
    changeLogicData(current_field_key,current_field_value){
        let { filedInfo, filedLogic , filedList } = this.props.myEnrollStore;      
        if(filedLogic && filedLogic.length){
            let bind_all_key = [];         
			for(let a = 0; a < filedLogic.length; a++){ //获取所有选项的key，这些被绑定的默认都不展示
				let data = filedLogic[a].bind_info;
				for(let b = 0; b < data.length; b++){               
					bind_all_key.push(data[b].field_key);
				}
                filedLogic[a].select_key = [];
			}

       
            if(!current_field_value){ //清空数据
                let targetIndex = filedList.findIndex(item=>item.field_key == current_field_key);
                let logicIndex = filedLogic.findIndex(item=>item.field_key == current_field_key)	
                if(bind_all_key.indexOf(current_field_key) != -1 || logicIndex != -1){                   
                    for(let a = 0; a < filedInfo.length; a++){                       
                        if(a > targetIndex && bind_all_key.indexOf(filedInfo[a].field_key) != -1){ //在其他字段里面设置了，那就隐藏				                             
                            filedInfo[a].field_value = '';
                            filedInfo[a].new_field_value = '';                         
                        }				                       
                    }                 
                }
            }
            
            for(let i = 0; i < filedInfo.length; i++){               
                let new_field_value = filedInfo[i].new_field_value;                
                let field_key = filedInfo[i].field_key;                
                for(let a = 0; a < filedLogic.length; a ++){
                    let data = filedLogic[a];
                                      
                    if(field_key == data.field_key){ //当前字段的在逻辑设置中，则看选项是否对得上
                        let option_info = data.option_info;                          
                        for(let b = 0; b < option_info.length; b++){                               
                            if(option_info[b].key == new_field_value || ((Array.isArray(new_field_value) && new_field_value.indexOf(option_info[b].key) != -1))){
                                let bindData = data.option_info.map(arr=>arr.key);                                
                                filedLogic[a].select_key = filedLogic[a].select_key.concat(bindData);
                            }
                        }
                    }

                    if(field_key == data.other_field_key){
                        let option_info = data.other_logic_info.option_info;
                        for(let b = 0; b < option_info.length; b++){                                              
                            if(option_info[b].key == new_field_value || (Array.isArray(new_field_value) && new_field_value.indexOf(option_info[b].key) != -1)){                                                    
                                let bindData = data.other_logic_info.option_info.map(arr=>arr.key);                                                          
                                filedLogic[a].select_key = filedLogic[a].select_key.concat(bindData);
                            }
                        }
                    }            
                }
            }

            let show_enroll_key = [], hide_enroll_key = [];
            for(let a = 0; a < filedLogic.length; a++){
                let option_info_key = filedLogic[a].option_info.map(item=>item.key);
                let isFirstContained = option_info_key.every(item =>filedLogic[a].select_key.includes(item));
                filedLogic[a].show_enroll = false;

                if(filedLogic[a].bind_logic_type && filedLogic[a].other_logic_info){ //额外的关联逻辑，或&且
                    let other_field_key = filedLogic[a].other_logic_info.option_info.map(item=>item.key);
                    let isSecondContained = other_field_key.every(item=>filedLogic[a].select_key.includes(item));

                    if(filedLogic[a].bind_logic_type == 1 && (isFirstContained || isSecondContained)){ //或
                        filedLogic[a].show_enroll = true;                        
                    }else if(filedLogic[a].bind_logic_type == 2 && isFirstContained && isSecondContained){ //且
                        filedLogic[a].show_enroll = true;                       
                    }else{
                        filedLogic[a].show_enroll = false;                        
                    }                       
                }else if(isFirstContained){ //普通逻辑设置
                    filedLogic[a].show_enroll = true;                   
                }

                let data = filedLogic[a].bind_info.map(item=>item.field_key);
                if(filedLogic[a].show_enroll){                   
                    show_enroll_key = show_enroll_key.concat(data);
                }else{
                    hide_enroll_key = hide_enroll_key.concat(data);
                }             
            }
           
            for(let a = 0; a < filedList.length; a++){
                if(hide_enroll_key.indexOf(filedList[a].field_key) !== -1){
                    filedList[a].ignore = 1;                
                }else if(show_enroll_key.indexOf(filedList[a].field_key) != -1){
                    filedList[a].ignore = 0;                         
                }
            }
            
            this.props.myEnrollStore.setFiledList(this.props.myEnrollStore.filedList);                
        }  
    
    }
    render() {
        if (!this.props.myEnrollStore.filedList) {
            return null;
        }
        let {currentInfoId} = this.props.myEnrollStore; 
        return (
            <div className="factory-box">
                {
                	
                    this.props.myEnrollStore.filedList.map((DataEntry,index) => {
                    	if(DataEntry.hidden == 1){
                    		return;
                    	}
						if(DataEntry.ignore == 1){
							return;
						}
                        let result = null;
                        let info = this.props.myEnrollStore.filedInfo;
                        let filedLogic = this.props.myEnrollStore.filedLogic;                  
                        let field_value = "",new_field_value="";
                        if (info && info.length > 0) {
                            let item = info.find((i) => DataEntry.field_key === i.field_key);
                            field_value = item && item.field_value;
                            new_field_value = item && item.new_field_value
							if(DataEntry.field_type == 16){
								field_value = DataEntry.description;
							}                            
                        }
 
                        switch (DataEntry.field_type) {
                            case 0:
                                result = <TextInput key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                    disabled={this.props.disabled} scan={DataEntry.scan}
                                                    onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 1:
                                result = <Number key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                 disabled={this.props.disabled}
                                                 onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 2:
                                field_value = field_value?field_value:''
                                result = <ImagePicker key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                      disabled={this.props.disabled}
                                                      onUpload={this.onUpload.bind(this)}
                                                      onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 3:
                                if(DataEntry.calendar_style == 1){//日历
                                    result = <SelectCalendar key={DataEntry.field_key} {...DataEntry}
                                                    disabled={this.props.disabled}
                                                    field_value={field_value}
                                                    onFieldChange={this.onFieldChange.bind(this)}></SelectCalendar>
                                }else{
                                    result = <DataPicker key={DataEntry.field_key} {...DataEntry}
                                                     disabled={this.props.disabled}
                                                     field_value={field_value}
                                                     onFieldChange={this.onFieldChange.bind(this)}/>;
                                }
                               
                                break;
                            case 4:
                                result = <Radio key={DataEntry.field_key} filedLogic={filedLogic} {...DataEntry} field_value={field_value} new_field_value={new_field_value}
                                                disabled={this.props.disabled}
                                                onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 5:
                                result = <Checkbox key={DataEntry.field_key} {...DataEntry} field_value={field_value} new_field_value={new_field_value}
                                                   disabled={this.props.disabled}
                                                   onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 6:
                                result = <Time key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                               disabled={this.props.disabled}
                                               onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 7:
                                result = <Textarea key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                   disabled={this.props.disabled}
                                                   onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 8:
                                // result = <Geolacation key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                //                       onFieldChange={this.onFieldChange.bind(this)}/>;
                                //微信不能支持选择地址 改成文本框输入
                                result = <Textarea key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                   disabled={this.props.disabled}
                                                   onFieldChange={this.onFieldChange.bind(this)}/>;

                                break;
                            case 9:
                                result = <Write key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                disabled={this.props.disabled}
                                                onUploadBase={this.onUploadBase.bind(this)}
                                                onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 10:
                                result = <Select key={DataEntry.field_key} {...DataEntry} field_value={field_value} new_field_value={new_field_value}
                                                 disabled={this.props.disabled}
                                                 onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;						
                            case 11:
                                result = <Tag key={DataEntry.field_key} {...DataEntry} field_value={field_value} new_field_value={new_field_value}
                                              disabled={this.props.disabled}
                                              onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
							case 12:
							    result = <Province key={DataEntry.field_key} {...DataEntry} field_value={field_value}
							                     disabled={this.props.disabled}
							                     onFieldChange={this.onFieldChange.bind(this)}/>;
							    break;		
                            case 14:
                                field_value = field_value?field_value:''
                                result = <ImageSingle key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                      disabled={this.props.disabled}
                                                      onUpload={this.onUpload.bind(this)}
                                                      onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
							case 15:
							    result = <Cascade key={DataEntry.field_key} {...DataEntry} field_value={field_value}
							                     disabled={this.props.disabled}
							                     onFieldChange={this.onFieldChange.bind(this)}/>;
							    break;
							case 16:
							    result = <Describe key={DataEntry.field_key} {...DataEntry} field_value={field_value}
							                     disabled={this.props.disabled}
							                     onFieldChange={this.onFieldChange.bind(this)}/>;
							    break;
                            case 17:
                                field_value = field_value?field_value:''
                                result = <ImageSingle key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                                      disabled={this.props.disabled}
                                                      onUpload={this.onUpload.bind(this)}
                                                      onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                            case 19: //多人团体                           
                                result = <Team key={DataEntry.field_key} currentInfoId={currentInfoId} {...DataEntry} field_value={field_value} team_req={DataEntry.team_req}
                                               disabled={this.props.disabled} router={this.props.history}
                                               onFieldChange={this.onFieldChange.bind(this)} />;
                                break;
                            case 20:
                                result = <File  key={DataEntry.field_key} {...DataEntry} field_value={field_value} currIndex={index} disabled={this.props.disabled}
                                onFieldChange={this.onFieldChange.bind(this)}
                                />
                                break;
                            case 21:
                                result = <Score key={DataEntry.field_key} {...DataEntry} field_value={field_value}
                                disabled={this.props.disabled} onFieldChange={this.onFieldChange.bind(this)}/>
                                break;
                            default:
                                result = <TextInput key={DataEntry.field_key} {...DataEntry} field_value={field_value} field_type={DataEntry.field_type}
                                                    disabled={this.props.disabled} scan={DataEntry.scan} wxPhone={DataEntry.wxPhone}
                                                    onFieldChange={this.onFieldChange.bind(this)}/>;
                                break;
                        }
                        return <div id={'content'+index}>{result}</div>;
                    })
                }
            </div>
        )
    }
}

export default Factory;