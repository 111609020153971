import React, {Component} from 'react';
import Label from "./label";
import {List, DatePicker} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import BaseServer from "../../services/BaseServers";


const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);


class DateSelect extends Component {
    state = {
        value: this.props.field_value,
    };
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }
    }

    onChange(value) {
        let v = BaseServer.formatDate(value, "yyyy-MM-dd")
        this.props.onFieldChange(v, this.props.field_key);
        this.setState({value: v});
    }

    getTime(time) {
        if (time && time.indexOf("-") > 0) {
            let timeArr = time.split("-");
            if (timeArr.length >= 3) {
                now.setFullYear(timeArr[0]);
                now.setMonth(timeArr[1] - 1);
                now.setDate(timeArr[2]);
                return now;
            }
        }
        return null;
    }

    render() {
		let _info = this.props;
		let startDate = new Date(1900, 1, 1, 0, 0, 0);
		let endDate = new Date(2100, 1, 1, 0, 0, 0);
		if(_info && _info.startDate){
			startDate = new Date(_info.startDate)
		}
		
		if(_info &&  _info.endDate){
			endDate = new Date(_info.endDate)
		}
		
        return (
            <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                <DatePicker
                    disabled={this.props.disabled}
                    mode="date"
                    title={this.props.field_name}
                    minDate={startDate}
                    maxDate={endDate}
                    value={this.getTime(this.state.value)}
                    onChange={this.onChange.bind(this)}
                >
                    <List.Item arrow="horizontal">{ this.props.field_name}</List.Item>
                </DatePicker>
            </List>
        );
    }
}

DateSelect.propTypes = IDataEntry;

export default DateSelect