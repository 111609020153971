// 日历组件
import React, {Component } from 'react';
import Calendar from './calendar/index';
import {inject, observer} from "mobx-react/index";
import {List, InputItem,Modal,Icon} from 'antd-mobile';
import Label from "./label";
import BaseServer from "../../services/BaseServers";

@inject("myEnrollStore", "enrollStore")
@observer
class SelectCalendar extends Component {
    state = {
        timespan:[],
        day_limit:0,
        time_limit:0,
        dateTimes:new Date().getTime(),
        value: this.props.field_value,
        showCalendar:false
    };    	
    constructor(props) {
        super(props);
    };
    componentWillReceiveProps(nextProps){
        if (nextProps.field_value != this.state.value) {
			this.setState({value: nextProps.field_value});			
        }        
    };
    render(){
        return (
            <div>
                <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>                   
                    <div className='enroll-calendar-box' onClick={()=>{
                         this.setState({showCalendar:true})
                    }}>
                        {
                            this.state.value ? <span>{ this.state.value }</span> : <span className='placeholder-text'>{"请选择" + this.props.field_name}</span>
                        }                 
                        <Icon type="right" className='right-icon' />
                    </div>                   
                </List>
                {
                    this.renderCalendar()
                }
                
            </div>
        )
    };
    renderCalendar(){        
        let date = new Date()
        if(this.state.value){
            date = this.state.value
        } else if(this.props.startDate){
            date = this.props.startDate
        } else if(this.props.endDate){
            date = this.props.endDate
        }               
        return <Modal maskClosable={true} popup={true} 
                visible={this.state.showCalendar} 
                animationType="slide-up" 
                className='m-calendar-modal'                
                onClose = {()=>{
                    this.setState({showCalendar:false})
                }}
                >           
                <Calendar type="date" date={date} startDate={this.props.startDate} highlight_time={this.state.value}
                endDate={this.props.endDate} changeMonth={()=>{}} submitDate={(e)=>{this.submitDate(e)}}></Calendar>
        </Modal>             
    }
    // 选择预约时间
    submitDate(reserve_time){
        let value = BaseServer.formatDate(reserve_time*1000, "yyyy-MM-dd")
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({
            value,
            showCalendar:false,
        })
    }
}

export default SelectCalendar