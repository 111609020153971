import React, {Component} from 'react';
import {Flex, Tabs, Badge, ListView, WingBlank,Button, Toast} from 'antd-mobile';
import ReactDOM from 'react-dom';
import {withRouter} from "react-router-dom";


import {inject, observer} from 'mobx-react';
import Config from "../../config";
import WxApi from '../../common/wxapi';


@inject("enrollStore", "loadingStore","myEnrollStore")
@observer
class UserList extends Component {
	state = {
		team:0,//模板号，1已开团  2单独购买
	}
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        let {getTeamList} = this.props.enrollStore;
        getTeamList.bind(this.props.enrollStore)(this.props.eid);
    }
    
    render() {
        let {userDataSource, getTeamList, userListLoading, userListNumCount,singleListNumCount, isEnd, currentTab} = this.props.enrollStore;
        const row = (rowData, sectionID, rowID) => {
            let {detail} = this.props.enrollStore;
            let getStatue = () => {
				
				if(rowData.fail){
					return (
					    <div className={"Detail-item-des1 "}>
					        拼团失败(已退款)
					    </div>
					)
				}else{
				
                if (rowData.team_num == rowData.joins) {
                	if(currentTab ==1){
                		return (
	                        <div className={"Detail-item-des1 "}>
	                        	 购买成功
	                        </div>
	                    )
                	}else{
                		return (
	                        <div className={"Detail-item-des1 "}>
	                        	 拼团成功
	                        </div>
	                    )
                	}
                    
                }
                else if (detail.status == 2 || isEnd) {
                    return (
                        <div className={"Detail-item-des1 "}>
                            拼团失败
                        </div>
                    )
                }
                else if (rowData.team_num > rowData.joins ) {
                	if(rowData.has_join){
                		 return (
	                        <div className={"group-info"} >
	                            <span>还差  <span className={"people-num"}>{rowData.team_num - rowData.joins}</span> 人成团</span>
                                {
                                    <Button inline className='team-btn join-btn' onClick={(e)=>{
                                        e.stopPropagation();
                                        const parm = {                                            
                                            tid: rowData.tid,
                                            type: 5,
                                        }                                        
                                        this.props.myEnrollStore.toShareGroup(parm).then(res=>{
                                            if(res.sta == 0){
                                                React.$wx.previewImage({
                                                    current: res.data.url, // 当前显示图片的http链接
                                                    urls: [res.data.url] // 需要预览的图片http链接列表
                                                })                                                
                                            }else{
                                                Toast.fail(res.msg);
                                            }
                                        })
                                    }}>邀请团</Button>                                    
                                }	                           
	                        </div>
	                    )
                	}else{
                		 return (
	                        <div className={"group-info"}>
	                            <span>还差 <span className={"people-num"}>{rowData.team_num - rowData.joins}</span> 人成团</span>
	                            <Button inline  className={"team-btn join-btn"} onClick={()=>{
                                    if(Config.TOKEN){
                                        this.props.enrollStore.getItemData().then(()=>{
                                            this.props.enrollStore.setTeamId(rowData.tid)
                                            this.props.history.push('/enrollGroupForm')
                                        })                                       
                                    }else{
                                        React.$wx.webLogin()
                                    }
	                            	
	                            }}>去参团</Button>
	                        </div>
	                    )
                	}
                }
                else if (currentTab == 2) {
                    return (
                        <div className={"Detail-item-des1 gray"}>
                            右箭头
                        </div>
                    )
                }
				
				}
				
            };
            let getName = function () {
                if(detail.user_visible == 1  ){
                	if(currentTab ==1){
                		 return (<div className="Detail-item-name">
                                { WxApi.getDefaultName(rowData.names[0]) }
                            </div>)
                	}
                   
                }
                else{
                    return (<div className="Detail-item-name">**</div>)
                }
            };
             
            if(rowData.team_num == rowData.joins && rowData.heads && rowData.heads.length != rowData.team_num){
                for(let a = 0;a<rowData.team_num;a++){
                    if(!rowData.heads[a]){
                        rowData.heads.push(Config.defaultAvatar)
                    }
                }
            }
            return (
                <Flex key={rowID} className="Detail-list-item" onClick={()=>{
                    if(rowData.has_join){
                        this.props.enrollStore.setTeamId(rowData.tid)
                        this.props.myEnrollStore.setCurrentEnroll({info_id: rowData.info_id})                                 
                        this.props.history.push('/groupCert')
                    }                    
                }}>
                    {
                    	rowData.heads && rowData.heads.length ? rowData.heads.map(function(item,index){
                    		if(index <5){
                    			return (<img className="header-img" src={item}/>)
                    		}
                    		
                    	}):<img className="header-img" src={Config.defaultAvatar}/>
                    }
                    <div className="Detail-list-flexpanel">
                    	{
                    		getName()
                    	}
                    </div>
                       
                        <Flex.Item className="Detail-item-des"  >
                            {
                                getStatue()
                            }
                        </Flex.Item>
                </Flex>
            );
        };
        
        const tabs = [
            {title: <Badge>已开团({userListNumCount})</Badge>},
            {title: <Badge>单独购买({singleListNumCount})</Badge>},
        ];
        
        return (

            <Flex className='group-user-list'>
                <Tabs tabs={tabs} tabBarActiveTextColor={this.props.themColor} tabBarUnderlineStyle={{backgroundColor: this.props.themColor, height: '2px', 'border': 0}}
                      swipeable={false}
                      initialPage={0}                      
                      onTabClick={(tab, index) => {
                            var team = index ? 2 : 1;
                            var enrollStore = this.props.enrollStore;
                            enrollStore.setCurrentTab(index)
                            enrollStore.setTeam(team);
                            enrollStore.getTeamList();                                                    
                      }}
                >
                <div>
                    <div className='msg-notification' onClick={()=>{
                        this.props.openMsg()                    
                    }}>
                        <i className='notify-img iconfont icon-svg45-copy' style={{color: this.props.themColor}}></i>
                        <h2 className='notify-title'>订阅通知 <span className='notify-desc'>及时接收报名成功、取消等</span></h2>                   
                        <span className='notify-btn' style={{color: this.props.themColor}}>去订阅 ></span>
                    </div>
                    {
                        userListNumCount != 0 ? <ListView
                        	className="Detail-content-member"
                            ref={el => this.lv = el}
                            dataSource={userDataSource}
                            renderFooter={() => (<div style={{textAlign: 'center'}}>
                                {userListLoading ? '正在加载...' : ''}
                            </div>)}
                            renderRow={row}
                            useBodyScroll={true}
                            scrollRenderAheadDistance={100}
                            onEndReached={() => getTeamList.bind(this.props.enrollStore)(this.props.eid)}

                        /> : <div className={`placeholder`}>还没有人参与</div>
                    }
                </div>   
                <div>
                    <div className='msg-notification' onClick={()=>{
                        this.props.openMsg()                    
                    }}>
                       <i className='notify-img iconfont icon-svg45-copy' style={{color: this.props.themColor}}></i>
                        <h2 className='notify-title'>订阅通知 <span className='notify-desc'>及时接收报名成功、取消等</span></h2>                   
                        <span className='notify-btn' style={{color: this.props.themColor}}>去订阅 ></span>
                    </div>
                    {
                        singleListNumCount != 0 ? <ListView
                            className="Detail-content-member"
                            ref={el => this.lv = el}
                            dataSource={userDataSource}
                            renderFooter={() => (<div style={{textAlign: 'center'}}>
                                {userListLoading ? '正在加载...' : ''}
                            </div>)}
                            renderRow={row}
                            useBodyScroll={true}
                            scrollRenderAheadDistance={100}
                            onEndReached={() => getTeamList.bind(this.props.enrollStore)(this.props.eid)}

                        /> : <div className={`placeholder`}>还没有人参与</div>
                    } 
                </div>                                 
                </Tabs>
            </Flex>
        )
        
        
        
    }
}

export default withRouter(UserList);
