import React, {Component} from 'react';
import Label from "./label";
import {List, Picker} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import street from "../../common/provStreet.js"

class Select extends Component {
    state = {
        value: this.props.field_value,
        region: [
            {'value': '省', 'level': 1, 'level_value': 'province'}, 
            {'value': '省/市', 'level': 2, 'level_value': 'city'}, 
            {'value': '省/市/区', 'level': 3, 'level_value': 'region'}, 
            {'value': '省/市/区/街道', 'level': 4, 'level_value': 'sub-district'}
        ],
        proInfo: {'value': '省/市/区', 'level': 3, 'level_value': 'region'}
    };
    componentDidMount(){
        let index = this.state.region.findIndex(item=>item.level_value == this.props.level);
        if(index > -1){
            this.setState({
                proInfo: this.state.region[index]
            })            
        }       
    }
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});
        }
    }

    onChange(value) {
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({value: value});
    }
    getData() {
        return street;
    }
    render() {
        return (
            <List className='enroll-label' renderHeader={() => <Label {...this.props} />}>
                {
                    !this.props.disabled ? <span className={`clear-select-data`} onClick={()=>{
                        this.props.onFieldChange('', this.props.field_key,'','');
                        this.setState({value:''})
                    }}>清空选项</span> : null
                }
                <Picker data={this.getData()} value={this.state.value} extra={'请选择'+ this.state.proInfo.value} cols={this.state.proInfo.level} onChange={this.onChange.bind(this)}
                        disabled={this.props.disabled}
                >
                    <List.Item arrow="horizontal" className="prov-item-panel">{ this.state.proInfo.value }</List.Item>                
                </Picker>
            </List>
        );
    }
}

Select.propTypes = IDataEntry;

export default Select