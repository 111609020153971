import React, {Component} from 'react';
import Label from "./label";
import {List, InputItem, Toast} from 'antd-mobile';
import IDataEntry from "./IDataEntry";

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

class Number extends Component {
    state = {
        value: this.props.field_value
    };

    constructor(props) {
        super(props);
    }
    componentWillReceiveProps (nextProps) {
        if(nextProps.field_value!==this.state.value){
            this.setState({value: nextProps.field_value});
        }

    }
    onChange(value) {
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({value: value});
    }

    checkVal(value){      
        if(!value && value !==0)return; 
        if(!(!this.props.min_value && this.props.min_value!==0)  && value < this.props.min_value){
            Toast.fail('最小值不能小于'+this.props.min_value)
            this.props.onFieldChange(this.props.min_value, this.props.field_key);
            this.setState({value: this.props.min_value});  
        }else if(!(!this.props.max_value && this.props.max_value !==0) && value > this.props.max_value){
            Toast.fail('最大值不能大于'+this.props.max_value)
            this.props.onFieldChange(this.props.max_value, this.props.field_key);
            this.setState({value: this.props.max_value});  
        }
    }

    render() {
        return (
            <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                <InputItem
                    type={"money"}
                    placeholder="点此输入"
                    onChange={(value) => this.onChange(value, this.props.field_key)}
                    onBlur={(value) => this.checkVal(value, this.props.field_key)}
                    clear
                    disabled={this.props.disabled}
                    value={this.state.value}
                    moneyKeyboardAlign="left"
                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                />
            </List>
        );
    }
}

Number.propTypes = IDataEntry;

export default Number