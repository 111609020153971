import React, {Component} from 'react';
import Label from "./label";
import {List, InputItem, Toast, Modal, Button, Icon} from 'antd-mobile';
import IDataEntry from "./IDataEntry";
import WxApi from "../../common/wxapi";
import ic_identification_no from "../../res/img/ic_identification_no.png";
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}
@inject("enrollStore","loadingStore", "myEnrollStore")
@observer
class Text extends Component {
    state = {
        value: this.props.field_value,
        sorollTop: 0,
        visibleDialog: false,
        unique_phone: '',     
    };

    constructor(props) {
        super(props);
    }
    componentDidMount(){
        if(this.props.field_type == 13 ){
            let {userInfo} = this.props.enrollStore;   
            if(userInfo && userInfo.unique_phone){
                this.setState({unique_phone: userInfo.unique_phone});
            }else{
                this.props.enrollStore.getUserInfo().then(()=>{                         
                    this.setState({unique_phone: this.props.enrollStore.userInfo.unique_phone});
                })
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value});        
        }
    }

    onChange(value) {
        this.props.onFieldChange(value, this.props.field_key);
        this.setState({value: value});
    }
 
	getScrollTop(){
		let sorollTop = document.body.scrollTop;
		this.setState({
	        sorollTop: sorollTop,
	    })
	}
    // 扫一扫
    toScan(){
        window.wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: (res)=>{
                let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                let value = '';
                if(result){
                    result = result.split(',');                   
                    if(result.length > 1){
                        value = result[1];
                    }else{
                        value = result[0];
                    }
                }
                this.props.onFieldChange(value, this.props.field_key);
                this.setState({value});                           
            },
            fail:(res)=>{
                Toast.fail('暂时只支持二维码、条形码');
            }
        })
    }

    render() {
        return (
            <div>
                <List className="enroll-label" renderHeader={() => <Label {...this.props} />}>
                    <div className="scan-box flex-between-box">
                        <InputItem
                            type={"text"}
                            placeholder="点此输入"
                            value={this.state.value}
                            onChange={(value) => this.onChange(value)}
                            clear
                            disabled={this.props.disabled}
                            maxLength={this.props.max_length || 500}
                            moneyKeyboardAlign="left"                            
                            onFocus={() => this.getScrollTop()}
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        />
                        {
                            !this.state.value && this.props.scan == 1 ?
                            <img className="scan-img" onClick={()=>{this.toScan()}} src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230209/65f14aa8667010fd72b300ca1a0caa63.png"/> : ''
                        }   
                        {
                            this.props.field_type == 13 && this.props.wxPhone != 2 ? 
                            <span className="operate-phone-btn" onClick={()=>{                                
                                // window.wx.openAddress({
                                //     success: (res)=>{
                                //         alert(JSON.stringify(res))
                                //     },
                                //     error: (e)=>{
                                //         alert('err:'+JSON.stringify(e))
                                //     }
                                // })
                                this.setState({ visibleDialog: true });                                                                                                       
                            }}>一键填写</span> : ''
                        }                
                        {
                            this.props.field_type == 18 && this.props.disabled && WxApi.getIdCardInfo(this.state.value) ?
                            <span className="idcard-type">{WxApi.getIdCardInfo(this.state.value).card_type}</span> : ''
                        }                  
                        {
                            this.props.field_type == 22 ? <div className='student-identify' onClick={()=>{
                                Modal.alert('温馨提示', '目前仅报名工具小程序支持「学生验证」，如需使用，请去报名工具小程序吧！', [{ text: '我知道了' }]);                                
                            }}>
                                {
                                    this.state.value == -1 ? <span className='fail-box'><img className='icon-fail' src={ic_identification_no} />验证学生身份失败</span> : <span>请点击验证学生身份</span>
                                }                                
                                <span className='operate-btn'>去验证 ></span>
                            </div> : null
                        }               
                    </div>                
                </List>
                { this.renderPhoneList() }            
            </div>           
        );
    };
    /* 引导绑定手机号 */
    renderPhoneList(){
        return (
            <Modal visible={this.state.visibleDialog} animationType="slide-up" popup className='phone-guide-modal' onClose={()=>{
                    this.setState({visibleDialog: false});
                }}>
                {
                    this.state.unique_phone ?
                    <div className='phone-items' onClick={()=>{
                        this.setState({value: this.state.unique_phone,visibleDialog: false});
                        this.props.onFieldChange(this.state.unique_phone, this.props.field_key);
                    }}>
                        <span>{this.state.unique_phone}</span>
                        <span className='warn-text'>当前已绑定账号</span>
                    </div> 
                    :
                    <div className='phone-items' onClick={()=>{                         
                        this.props.history.push('/personalInfo');
                    }}>
                        <span>未绑定手机账号</span>
                        <span className='warn-text'>前往个人中心，<span className='them-color'>去绑定 ></span></span>
                    </div> 
                }                                
                <div className='other-phone-guide cancel-btn' onClick={()=>{
                    this.setState({visibleDialog: false})
                }}>取消</div>
            </Modal>
        )
    };
}

Text.propTypes = IDataEntry;

export default withRouter(Text);