import axios from 'axios'
import config from "../config"
import {loadingStore} from "../store/loading";
import wxapi from "../common/wxapi";
import {Toast} from "antd-mobile/lib/index";


let instance = axios.create({
    baseURL: config.api_business,
    timeout: config.timeout,
});
// 请求拦截器
instance.interceptors.request.use((config) => {
    loadingStore.pushStack();
    return config
}, (error) => {
    loadingStore.pushStack();
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use((response) => {
    loadingStore.popStack();
    // //判断Token是否过期
    if (response.status == 200 && response.data && response.data.sta == "-500") {
        wxapi.removeToken();
    }
    return response;
}, (error) => {
    config.on_error_interface("数据读取出错");
    loadingStore.popStack();
    return Promise.reject(error)
})


export default instance


