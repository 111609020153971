import React, {Component} from "react";
import Label from "./label";
import {List} from 'antd-mobile';

class Score extends Component {
    state = {
        list: [
            {'value': '评星', 'url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/5232f7d193d7fcdfa6ed279548079b5e.png', 'select_url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/c074c560b4e878156fb286e2c979ba02.png', 'id': 1},
            {'value': '点赞', 'url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/60a3147edcbe57a749633b3c206589d5.png', 'select_url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/afe14e011b32aae408d259880c2f5626.png', 'id': 2}, 
            {'value': '爱心', 'url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/260cde0a3ce68781bff36a9cf7b996f7.png', 'select_url': 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20231122/b90e075131d57b2c36b95f97070e3787.png', 'id': 3}, 
            {'value': '数值', 'id': 4}
        ],
        value: this.props.field_value || 0,
        score_list: [],
    };
    componentDidMount(){
        this.getScoreList();
    };
    componentWillReceiveProps(nextProps){
        if (nextProps.field_value != this.state.value) {
            this.setState({value: nextProps.field_value},()=>{
                this.getScoreList();
            });            
        }
    };
    render(){
        return (
            <List renderHeader={() => <Label {...this.props} />}>  
                {
                    !this.props.disabled ? <span className={`clear-select-data`} onClick={()=>{
                        this.getScoreList();
                        this.props.onFieldChange('', this.props.field_key);
                        this.setState({value:''});                 
                    }}>清空</span> : null
                }                        
                {              
                    this.renderScore()        
                }                        
            </List>
        )
    };
    renderScore(){    
        return <div className="score-panel">
            {
                this.state.score_list.map((item, index)=>{                    
                    return this.props.rate_type == 4 ? 
                    <span className={item.is_selected ? 'score-num num-focus' : 'score-num'} onClick={()=>{ this.changeScore(index) }}>{index+1}</span>
                    : 
                    <img className="score-img" key={index} src={item.is_selected ? item.select_url : item.url} onClick={()=>{
                       this.changeScore(index);
                    }}></img>
                })        
            }
        </div>
    };
    changeScore(index){
        if(this.props.disabled) return;
        let selected = !this.state.score_list[index].is_selected;
        let value = 0;
        this.state.score_list = this.state.score_list.map((item, i)=>{                
            if(index == i){
                item.is_selected = selected;
            }else if(i < index && selected){                       
                item.is_selected = selected;
            }else if(i > index && !selected){
                item.is_selected = selected;
            }
            if(item.is_selected){ value++ };
            return item;
        })                
        this.setState({ score_list: this.state.score_list });
        this.props.onFieldChange(value, this.props.field_key);
    };
    getScoreList(){
        let score_list = [];
        for(let a = 0; a < this.props.rate_num; a++){
            let obj = {
                url : this.state.list[this.props.rate_type - 1].url,
                select_url: this.state.list[this.props.rate_type - 1].select_url,
                is_selected: false,
            }
            if(a < this.state.value){
                obj.is_selected = true;
            }
            score_list.push(obj);
        }       
        this.setState({score_list});
    };
}

export default Score;