import axios from 'axios'
import config from "../config"
import {loadingStore} from "../store/loading";
import wxapi from "../common/wxapi";
import {Toast} from "antd-mobile/lib/index";


let instance = axios.create({
    baseURL: config.api,
    timeout: config.timeout,
});
// 请求拦截器
instance.interceptors.request.use((config) => {
    loadingStore.pushStack();
    return config
}, (error) => {
    loadingStore.pushStack();
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use((response) => {
    loadingStore.popStack();
    // //判断Token是否过期
    if (response.status == 200 && response.data && response.data.sta == "-500") {
        let detail_url = '/xcx/enroll/v3/detail'
        let responseURL = response.request.responseURL
        if(responseURL.indexOf(detail_url) !=-1){ //活动详情页接口   
            localStorage.setItem('isOldUser',1)//如果有过token,但是token已过期，也代表是老用户授权过微信    
            config.TOKEN = ''
            localStorage.removeItem(config.wx_token_key);
            window.location.reload()
        }else{
            wxapi.removeToken();
        }
    }
    return response;
}, (error) => {
    config.on_error_interface("数据读取出错");
    loadingStore.popStack();
    return Promise.reject(error)
})


export default instance

