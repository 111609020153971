import React, {Component} from 'react';

export default class Describe extends Component {
	state = {
	    value: this.props.field_value,
	    sorollTop:0
	};
	
	constructor(props) {
	    super(props);
	}
	
	componentWillReceiveProps(nextProps) {
		
	    if (nextProps.field_value != this.state.value) {
	        this.setState({value: nextProps.field_value});
	    }
	
	}
	
	
    render() {
        return (
            <div className="enroll-desc">
                <div className="label-header">{this.props.field_name || "未知字段名"}</div>
				{
					this.props.field_value ? <div className="field-desc label-content gray">{this.props.field_value}</div> : ''
				}                
				{
					this.props.description_img ? <img className="description-img" src={this.props.description_img} onClick={()=>{
						React.$wx.previewImage({current:this.props.description_img,urls:[this.props.description_img]})
					}}/> : ''
				}
            </div>

        )
    }
}