//外部组件引入
import React, {
	Component
} from 'react';
import { Flex, Button, Modal, Result, List, Badge ,Toast,InputItem,ActionSheet} from 'antd-mobile';
import { inject, observer } from 'mobx-react';
//自定义内容引入
import UserList from "./userList";
import Config from "../../config";
import WxApi from "../../common/wxapi";
import NewMsgDialog from '../../components/NewMsgDialog';

//图片引入
import img_identification_p from "../../res/img/ic_identification_person_small.png";//个人认证
import img_identification_c from "../../res/img/ic_identification_company_small.png";//企业认证
import img_identification_o from "../../res/img/ic_identification_organization_small.png";//组织认证
import img_identification_g from "../../res/img/ic_identification_gzh_small.png";//公众号认证
import wxapi from "../../common/wxapi";

import img_qun from "../../res/img/ic_detail_group_photo.png"
import img_subscription from "../../res/img/ic_detail_notice_guide.png"
import img_mask_arrow from "../../res/img/ic_mask_arrow.png";
import right_arrow from "../../res/img/right_arrow.png";
import left_arrow from "../../res/img/icon_left_arrow.png";
import close_btn from "../../res/img/ic_popup_close.png";
import ic_person_identity from "../../res/img/ic_person_identity_V.png";
import is_follow from "../../res/img/ic_detail_follow_done.png";
import no_follow from "../../res/img/ic_detail_follow.png";
import ic_top_row from "../../res/img/ic_detail_top_arrow.png"
import ic_top_notice from "../../res/img/ic_detail_top_notice.png"
import ic_conct_btn from "../../res/img/ic_conct_btn.png"

import icon_right_img from '../../res/img/right_arrow_blue.png'
import icon_create_enroll from '../../res/img/icon_create_enroll.png'
import icon_adress from '../../res/img/icon_adress.png'
import icon_video_img from '../../res/img/big-num-video.png'
const Item = List.Item;
const Brief = Item.Brief;

@inject("enrollStore", "loadingStore", "myEnrollStore")
@observer
class App extends Component {
	state = {
		height: 0,
		sharePanel:'none',//分享选择界面
		showDetailQrCode:'none',
		showHomePageQrCode:'none',
		shareFriend:'none',
		roleMinProgram:'none',//认证小程序二维码
		tokenPanel:'none',//口令输入框
		heightAuto:false,//图片是否根据高度自适应
		rendBanner:true,
		token:'',
		sub_user: 0,
		btnTypeText:["立即报名", "点击填写", "立即预约", "了解更多", "下一步","立即购买",'立即申请','立即报名'],
		isWeixin:true,//判断是否在微信环境
		isMobile:true,//判断是否是移动环境
		showSheet:'0',
		showSeat:false,
		showReverse:false,
		showReadDialog:false,//提交前阅读弹窗
		countDown:5,
		hasActive:true,//这个活动是否存在
		currIndex:0,
		reverseList:[],
		tagList:[],
		showLink:false,//短链
		showWxlink:false,
		currAudioIndex:-1,	 
		shareList:[], 
		censorModal: false,
		enroll_token: '',
		showEnrollToken: false,
		groupList: [],
		show_notice: false,
		vipList: [],
	};

	getDetail() {
		if(Config.EID) {		 
			let _this = this;			 
			this.props.enrollStore.getEnrollDetailByEid().then(async(res) => {
				Toast.loading('正在加载数据');
				let {detail} = _this.props.enrollStore;
				let isAwait = sessionStorage.getItem('isAwait');      				 
				if(res.data.data && res.data.data.is_await == 1 && isAwait !=1){
					sessionStorage.setItem('isAwait',1);
					setTimeout(()=>{                     
						_this.getDetail();
					},3000)
					return;
				}   
				Toast.hide();
				detail.owner_pic = await wxapi.checkImage(detail.owner_pic);
				let themColor = '#00a8f3';
				if(detail && detail.btn_config && detail.btn_config.color){
					themColor = detail.btn_config.color;
				}
				let vipList = [];
				if(detail.user_owner_vip_all && detail.user_owner_vip_all.length){
					vipList = detail.user_owner_vip_all.filter(vip => vip.expire > new Date().getTime() / 1000).map(vip => vip.card_id); //获取当前用户有哪些没过期的会员卡	 
				}				
				_this.setState({
					sub_user: detail.sub_user,
					detail: detail,
					hasActive:res.data.sta == -1?false:true,  //-500token失效 -1活动不存在
					themColor,
					vipList: vipList,
				})
				if(res.data.data && res.data.data.is_await != 1){//// 超时情况延迟请求接口
					this.getAdultCode() //右侧广告码
				}; 
				
				//订阅消息回调
				let sceneId = WxApi.getQueryString("scene");
				let openid = WxApi.getQueryString("openid");
				let notice = sessionStorage.getItem("notice");
				if(sceneId && openid && notice =='notice'){
					_this.receiveNotice(sceneId,openid)
				}
		 
				if(detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1){ //指定群聊可参与				 		
					if(detail.is_owner || detail.is_admin ){
						this.initShareGroup(detail);
					}else if(!detail.info_id){
						this.props.history.push('/error/5')
					}	 					
				}

				if(detail.title){
					document.title = detail.title
					sessionStorage.setItem("authorizer_appid",detail.authorizer_appid);
					if(detail && detail.temp == 6){
						_this.props.history.push('/group?eid='+ Config.EID+'&referer='+Config.referer)
						return;
					}
					
					if(!Config.TOKEN){
						let isWeixin = WxApi.isWeixn();
						let isMobile = WxApi.isMobile();
						let isUnionPay = wxapi.isUnionPay();
						const code = WxApi.getQueryString("code");
						const isOldUser = localStorage.getItem('isOldUser'); //微信曾经授权过报名工具的用户
						if(!isWeixin){
							_this.getsShareImg(1);
							_this.setState({
								isWeixin:isWeixin,
								isMobile:isMobile,
								isUnionPay,
							})
						}else if(code || isOldUser){//微信收授权后，则重新登录
							WxApi.jumpWxAuth();
						}
					}else if(res.data.data && res.data.data.is_await != 1){
						_this.getNoticeInfo(detail);  //顶部展示通知
					}
					sessionStorage.setItem("isWixin",this.state.isWeixin);
				}
				
				if(detail.unionid){
					sessionStorage.setItem('unionid',detail.unionid);
					sessionStorage.setItem('owner_unionid',detail.owner_unionid);
				}
				
				let _url = wxapi.replaceParamVal('referer',Config.referer);
				let shareText = "";
				if(detail.auth > 1){
					shareText = detail.sign_name;
				}else if(detail.auth == 1){
					shareText = "<点击查看内容>"
				}else{
					shareText = "来自报名工具小程序"
				}
				if(detail.content && Array.isArray(detail.content)){
					let index = detail.content.findIndex(item=>item.type === 'text'&&item.value)
					if(index>-1){
						shareText = detail.content[index].value
					}
				}
				
				if(res.data.data && res.data.data.is_await != 1){// 拥堵情况延迟请求接口
					if(detail && (detail.count <= Number(detail.user_list_limit) || detail.is_admin || detail.is_owner)){//根据后台接口的阈值来限制加载
						this.props.enrollStore.restUserListPage();
					}else{
						this.props.enrollStore.initUserList() //初始化已提交列表
					}
				};

				if(detail.temp == 10 || detail.temp == 8){ //选座&预约
					this.wxJumpPath = `/pages/detail/reserve.html?eid=${detail.eid}&referer=${Config.referer}`;
				}else{
					this.wxJumpPath = `/pages/detail/detail.html?eid=${detail.eid}&referer=${Config.referer}`;				 
				}

				if(detail.temp == 5 && !detail.info_id){
					let no_quoto = false;
					this.props.enrollStore.getItemData().then(res=>{
						if(res.data.sta == 0){
							let enrollData = res.data.data.items;
							no_quoto = enrollData.every(item=>item.remain == 0);							 
						}
						detail.no_quoto = no_quoto;					 				 
					})
				}
				 
				await this.toEditorMsg()// 如果是发起人编辑参与者报名信息，则直接跳转
				this.toMoveEnroll()//如果是名额转让，则直接跳转报名页面
				wxapi.setShare(detail.title, shareText, _url, detail.banner || Config.LOGO);
				if(detail.is_move)this.getShare();				
				if(detail.auth == 0){					
					if(wxapi.isWeixn()){ //这个是为了避免在ios中注册微信api与初始进入页面不一致，导致微信api不生效
						const checkWxApi = () => {
							return new Promise((resolve, reject) => {
							  let interval = setInterval(() => {
								if (typeof window.wx !== 'undefined' && window.wx && window.wx.openBusinessView) {
									clearInterval(interval);
									resolve();
								}
							  }, 200);
							});
						  }						  
						checkWxApi().then(() => {
							this.props.history.push('/share?eid='+detail.eid+'&referer='+Config.referer);
						});
					}else{
						this.props.history.push('/share?eid='+detail.eid+'&referer='+Config.referer);	
					}													
				}else if(detail.censor_status == 2) { //内容违规
					this.props.history.push('/error/2')
				}else if(detail.censor_status == 3){ //活动有争议
					this.setState({ censorModal: true })					 
				}
			}, () => {
				this.props.history.push('/page-404') //未知的组织报名
			});
		} else {
			this.props.history.push('/page-404')
		}
	}
	// 如果是发起人编辑参与者报名信息，则直接跳转
	toEditorMsg(){
		return new Promise((resolve, reject)=>{
			sessionStorage.removeItem('fromPC')		
			let info_id = WxApi.getQueryString('info_id')
			let verified = WxApi.getQueryString('verified')
			let token = WxApi.getQueryString("tk")
			if(info_id && verified){		
				if(token && !WxApi.isWeixn()){
					localStorage.setItem(Config.wx_token_key,token)			
				}	
				sessionStorage.setItem('fromPC',1)//从pc过来编辑报名信息
				this.props.myEnrollStore.setCurrentEnroll({info_id,verified})
				this.props.enrollStore.getItemData().then(()=>{
					this.props.history.push('/enrollForm')
					reject()
				})			
			}else{
				resolve()
			}		
		})		
	}
	//如果是名额转让，则直接跳转报名页面
	toMoveEnroll(){
		sessionStorage.removeItem('fromMove')		
		let move_id = WxApi.getQueryString('move_id')					
		if(move_id){
			if(!Config.TOKEN){
				React.$wx.webLogin()
				return
			}
			sessionStorage.setItem('fromMove',1)//从转让名额过来编辑报名信息
			this.props.myEnrollStore.setCurrentEnroll({info_id:move_id})
			this.props.enrollStore.getItemData().then(()=>{
				this.props.history.push('/enrollForm')
			})	
		}
	}	
	getShare(){
		const params = {
			count: 6,
			page: 1,
		}
		this.props.myEnrollStore.getShareList(params).then(res=>{
			if(res.sta == 0){
				this.setState({
					shareList: res.data.move_list,
				})
			}
		})
	}
	setReverseTag(detail){
		if(detail.temp == 8){
			let tagList = ['全部'],items = detail.items||[]
			for(let a = 0;a < items.length;a++){
				if(items[a].tag && tagList.indexOf(items[a].tag) == -1){
					tagList.push(items[a].tag)
				}
			}
			this.setState({
				reverseList:detail.items,
				tagList,
				tagList:tagList.length>1?tagList:[]
			})
		}
	}
	initShareGroup(detail){
		this.props.myEnrollStore.getShareGroup(detail.eid).then(res=>{
			if(res.sta == 0){				 
				this.setState({groupList: res.data.open_group});
			}
		})		
	}
	// 从普通浏览器跳转到微信小程序
	getTicket(detail){
		this.props.myEnrollStore.getShortTicket({eid:detail.eid}).then(res=>{
			if(res.sta == 0){
				window.location.href = res.data.scheme_ticket;					
			}
		})
	}

	//订阅通知
	receiveNotice(sceneId,openid){
		this.props.myEnrollStore.subNotice(sceneId,openid).then(() => {
			let {orderStatus} = this.props.myEnrollStore;
			if(orderStatus){
				sessionStorage.removeItem("notice");
				Toast.success('订阅成功!', 1);
			}
		});
	}
	
	//获取微信系统通知信息
	getNoticeInfo(detail){
		let _this = this;
		if(Config.TOKEN){
			this.props.myEnrollStore.getNoticeAll(detail.eid).then((res) => {
				_this.renderNotice(detail)
				let showScene = (detail.follow.scene == 0 && detail.info_id) || (detail.follow.scene == 1 && detail.verify == 1 && detail.verified == 1) || (detail.follow.scene == 2 && detail.info_id || (detail.verify ==1 && detail.verified == -1));
				const {wxNotice} = this.props.myEnrollStore;
				let noticeArr = wxNotice.msg_list;
				let sms_list = wxNotice.sms_list;
				if(noticeArr && noticeArr.length > 0){
					noticeArr = noticeArr.filter(item => !item.time_out || item.time_out == 0);
				}
				let show_notice = false;
				if(noticeArr && noticeArr.length > 0 &&  noticeArr[0].content && (detail.info_id || (detail.period && detail.period_info))){
					show_notice = true;
				}else if(sms_list && sms_list.length > 0){
					show_notice = true;
				}else if(detail && detail.follow && detail.follow.type > 0 && (detail.follow.desc||detail.follow.qrcode||detail.follow.link_url) && showScene){
					show_notice = true;
				}
				this.setState({show_notice});
			});
		}
	}

	getAdultCode(){ //右侧广告码		 
		this.props.enrollStore.getShare(1).then((d) => {
			if(d && d.url) {
				this.setState({
					shareImgUrl: d.url,
				})
				this.props.myEnrollStore.setShareImg(d.url);
			}
		})		 
	}
	
	componentDidMount() {
		sessionStorage.removeItem('isAwait');		
		sessionStorage.removeItem('teamReqList');
		sessionStorage.removeItem('teamPersonalMsg');		
		this.getDetail();
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.url !== nextProps.match.url) {
			this.getDetail();
		}
	}

	renderHeader(detail) {
		const miniBtnStyle = {
			width: 'calc(100vw - 65px)',
		    height:'40px',
		    background:'#f7f7f7',
			color: '#666666',
			fontSize:12,
			textAlign:'center',
		}

		let signName = WxApi.getDefaultName(detail.sign_name);

		if(detail.role && detail.auth == 1 && !detail.cert_show){
			signName = WxApi.getDefaultName(detail.sign_name).substr(0,1) + "**";
		}

		return(<div className="d-header">
			<div className="d-header-my" onClick={()=>{
				this.props.enrollStore.getCurrMenu(2)
				window._hmt.push(['_trackPageview',  '/manage/myEnroll'])
				this.props.history.push('/manage/myEnroll') 
			}}>
				<img className="left-arrow" src={left_arrow} />
				<span>我的</span>
			</div>
			<div className="d-header-left cursor" onClick={()=>{
				window._hmt.push(['_trackEvent', 'button_to_wx','', '活动详情顶部点击打开小程序按钮', '']); //百度统计				 
				if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器							 	
					this.showDetailQrCode('block')
				}else if(WxApi.isWeixn() && !Config.TOKEN){					 
					React.$wx.webLogin();
				}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器							 
					this.getTicket(detail)
				}else if(WxApi.isWeixn() && !WxApi.isMobile()){					 
					this.props.history.push('/h/'+ detail.owner_unionid)
				}				
			}}>
				<img src={detail.owner_pic || Config.defaultAvatar} />
				<h4 className="user-name">{signName}</h4>
				<button className="btn-open-mini" style={{backgroundColor: this.state.themColor}}>去看看</button>
			</div>			
			{
				WxApi.isWeixn() && WxApi.isMobile() ? <div className="btn-open-mini-opacity">
					<wx-open-launch-weapp
							style={miniBtnStyle}
							id="launch-btn"
							username="gh_ae6449501a82"
							path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
						>
						<script type="text/wxtag-template">
								<div style={miniBtnStyle}>
								<div>去看看</div>
								</div>
						</script>
					</wx-open-launch-weapp>
				</div> : ''
			}			
		</div>);
	}
	
	// 右侧广告位
	renderWxCode(){
		if(this.state.shareImgUrl){
			return (
				<div className="advert-box-right">
					<div className="wx-code-box">
						<div className="wx-qrcode">
							<img src={this.state.shareImgUrl}></img>
						</div>
						<span>微信扫一扫</span>
						<span className="wx-watch">打开小程序</span>
					</div>
					<a className="create-enroll-box" href="https://www.baominggongju.com/?hmsr=h5">
						<img src={icon_create_enroll} className="icon-create"/>
						<span className="enroll-text">我也要创建活动</span>	
						<img src={icon_right_img} className="icon-right-blue"/>
					</a>
				</div>
			)
		}
	}
	// 名额转让
	renderMove(){
		return (
			<div className='move-container' onClick={()=>{
				this.props.history.push('/transfor')
			}}>
				<div className='move-left'>
					{
						this.state.shareList.map((item, index) =>{
							return <img className='user-img' key={index} src={item.upic}></img>
						})
					}
				</div>
				<div className='move-text'>有转让名额&nbsp;&nbsp;<span className='them-color'>去看看 ></span></div>
			</div>
		)
	}
	// 指定群参加
	renderWxGroup(detail){
		const miniBtnStyle = {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '40px',
		}
		return (
			<div class='fixed-group-team' style={{bottom: this.state.show_notice ? '90px' : '50px'}}>
				<div className='group-main' onClick={()=>{
					window._hmt.push(['_trackEvent', 'button_to_wx','', '活动详情顶部点击打开小程序按钮', '']); //百度统计				 
					if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器							 	
						this.showDetailQrCode('block')
					}else if(WxApi.isWeixn() && !Config.TOKEN){					 
						React.$wx.webLogin();
					}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器							 
						this.getTicket(detail)
					}else if(WxApi.isWeixn() && !WxApi.isMobile()){					 
						this.props.history.push('/h/'+ detail.owner_unionid)
					}		
				}}>
					<div className='left-box'>
						<img className='msg-img' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20240221/d542a1fdb85c9cdbe73ec49383ae7924.png"></img>
						<span>{this.state.groupList && this.state.groupList.length > 0 ? this.state.groupList.length : 0}个指定群参加</span>
					</div>
					<span>设置 ></span>
					{
						WxApi.isMobile() && WxApi.isWeixn() ?
						<wx-open-launch-weapp
							id="launch-btn" style={miniBtnStyle}
							username="gh_ae6449501a82"
							path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								<div style={miniBtnStyle}>
									
								</div>
							</script>
						</wx-open-launch-weapp> : null
					}
				</div>
			</div>
		)
	}
	//自定义提交通知
	renderNotice(detail){
		let showScene = (detail.follow.scene == 0 && detail.info_id) || (detail.follow.scene == 1 && detail.verify == 1 && detail.verified == 1) || (detail.follow.scene == 2 && detail.info_id || (detail.verify ==1 && detail.verified == -1));
		const {wxNotice} = this.props.myEnrollStore;
		let noticeArr = wxNotice.msg_list;
		let sms_list = wxNotice.sms_list;
		if(noticeArr && noticeArr.length > 0){
			noticeArr = noticeArr.filter(item => !item.time_out || item.time_out == 0);
		}
		const miniBtnStyle = {
			width: '414px',
		    height:'40px',
		    background:'#f7f7f7',
		}
		if(noticeArr && noticeArr.length > 0 &&  noticeArr[0].content && (detail.info_id || (detail.period && detail.period_info))){
			return(
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
							sessionStorage.setItem("noticeTab",0);
							this.props.history.push('/notice');
						}}>
						<img className='notice-icon' src={ic_top_notice} />
						<p className='notice-content'>{noticeArr[0].content}</p>
						<img className='icon-row' src={ic_top_row} />
					</div>
					{this.state.isWeixin ? <div className="notice-box-opacity">
					<wx-open-launch-weapp
						  id="launch-btn"
						  username="gh_ae6449501a82"
						  path={detail.eid ? this.wxJumpPath + '&open_type=notice' : '/pages/default/default.html'}
						>
						<script type="text/wxtag-template">
							 <div style={miniBtnStyle}>
								
							 </div>
						</script>
					</wx-open-launch-weapp>
				</div> : null}
				</div>
			)
		}else if(sms_list && sms_list.length > 0){
			return (
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
								sessionStorage.setItem("noticeTab",0);
								this.props.history.push('/notice');
							}}>
							<img className='notice-icon' src={ic_top_notice} />
							<p className='notice-content'>{sms_list[0].content}</p>
							<img className='icon-row' src={ic_top_row} />
						</div>
						{this.state.isWeixin ? <div className="notice-box-opacity">
						<wx-open-launch-weapp
							id="launch-btn"
							username="gh_ae6449501a82"
							path={detail.eid ? this.wxJumpPath + '&open_type=notice' : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								<div style={miniBtnStyle}>
									
								</div>
							</script>
						</wx-open-launch-weapp>
					</div> : null}
				</div>
			)
		}else if(detail && detail.follow && detail.follow.type > 0 && (detail.follow.desc||detail.follow.qrcode||detail.follow.link_url) && showScene){
			return(
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
							sessionStorage.setItem("noticeTab",1);
							this.props.history.push('/notice');
						}}>
						<img className='notice-icon' src={ic_top_notice} />
						<p className='notice-content'>{ detail.follow.desc || '已参加活动的小伙伴，请点击查看'}</p>
						<img className='icon-row' src={ic_top_row} />
					</div>
					{ this.state.isWeixin ? <div className="notice-box-opacity">
						<wx-open-launch-weapp
							  id="launch-btn"
							  username="gh_ae6449501a82"
							  path={detail.eid ? this.wxJumpPath + '&open_type=notice&notice_type=1' : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								 <div style={miniBtnStyle}>
									
								 </div>
							</script>
						</wx-open-launch-weapp>
					</div> : null }
				</div>
			)
		}
	}

	//banner图片
	renderBanner(detail) {
		let _this = this;
		let imgHeight = document.body.offsetWidth * 0.6;
		
		if(detail.banner) {
			//根据短边来裁剪banner图片
			let _img  = new Image();
			_img.src = detail.banner;
			_img.onload = function(detail){
				if(_img.width*0.6 > _img.height){
					_this.setImgHeight(true);
				}else{
					_this.setImgHeight(false);
				}
			}
			_img.onerror = function(err){
				detail.banner = null;
			}

			return(<div className="Detail-header-image">
                <img className='enroll-detail-img' onClick={() => {
                    React.$wx.previewImage({
                        current: detail.banner, // 当前显示图片的http链接
                        urls: [detail.banner] // 需要预览的图片http链接列表
                    });
                }} src={WxApi.getAutoImage(detail.banner, 750, 'single')} />
            </div>);
		}else if(detail.video && detail.video.url){
			return(<div className="Detail-header-image" style={{height: imgHeight}}>
			<video className="video-banner" src={detail.video.url} controls="controls" poster={detail.video.pic_url}></video>
			</div>);
			
		}else if(detail.banner === null){
			return (<div className="Detail-header-image"><div className='enroll-detail-img'></div></div>)
		}
		else {
			return(<div className="no-banner"></div>);
		}
	}
	//设置图片自适应
	setImgHeight(type){
		if(this.state.rendBanner){
			this.setState({
		        heightAuto: type,
		        rendBanner:false
		    })
		}
	}
	
	//活动内容
	renderContent1(detail) {
		let enrollStore = this.props.enrollStore;
		let adressChannel = ['腾讯会议', '企业微信会议','钉钉会议','其他'];
		let adressCount = detail.address;
		if(detail.address_type && detail.address_type > 0){
			if(detail.address_channel == 3){
				adressCount = '线上活动' +' >'
			}else{
				adressCount = '线上活动' + ' • ' + adressChannel[detail.address_channel] + ' >';
			}
		}

		const listItem = [{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/ccdf4a2dcf25fb1516eb860f259ff0d6.png',
				isShow: true,
				title: "报名：",
				content: detail.startTime + " - " + detail.endTime,
			},
			{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/6cd639fd139836f17cf134f6e290ec10.png',
				isShow: detail.items && detail.items.length === 0 && (detail.user_visible || detail.is_owner)&& detail.temp != 8 && detail.temp != 5 && detail.temp != 10,
				title: "提交：",
				content: (detail.user_visible ? detail.count : '**') + "/" + (detail.limit ? detail.limit : "无限制") + " " + ((detail.count === detail.limit) && (detail.limit !== 0) ? "(名额已满)" : ""),
			},
			{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/354236005ef43fa149a8a80d09942f7b.png',
				isShow: !!detail.act_start,
				title: "活动：",
				content: detail.actStartTime + " - " + detail.actEndTime
			},
			{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/5d5a06f07bf908516ba4af0f3c939764.png',
				isShow: !!detail.address,
				title: "地址：",
				content: adressCount, //TODO 这里需要添加定位,
				action: () => {
					if(!detail.address_type || detail.address_type ==0){
						if(WxApi.isMobile() && WxApi.isWeixn()){
							if(!Config.TOKEN){
								React.$wx.webLogin();
								return;
							}
							window.wx.openLocation({
								latitude: detail.latitude, // 纬度，浮点数，范围为90 ~ -90
								longitude: detail.longitude, // 经度，浮点数，范围为180 ~ -180。
								address: detail.address, // 地址详情说明
								scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
							});
						}else{
							const linkElement = document.createElement('a');
							linkElement.target="_blank";
							linkElement.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${detail.latitude},${detail.longitude};title:活动地址;addr:${detail.address}&referer=myapp`
							// linkElement.href = `https://api.map.baidu.com/marker?location=${detail.latitude},${detail.longitude}&title=活动地址&content=${detail.address}&output=html&src=地图&ak=G1RjyBHcccDk6pb865C4FrPXjcpB2eKM`						
							const clickEvent = new MouseEvent('click', {
								view: window,
								bubbles: true,
								cancelable: true
							});							
							linkElement.dispatchEvent(clickEvent);
						}						
					}else{
						this.setState({showLink:true});
					}
					
				}
			},
			{
				img:'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/24b2000097834bc5092203da00c8e00e.png',
				isShow:detail.period,
				title:'周期：',
				content:`${detail.period == 1 ? "按天报名" : "按周报名"} ${detail.daily_time ? "(每天" + detail.daily_start + "~" + detail.daily_end + "可报名)" : ""}`
			},

		];
		let  has_price = false
		if(detail.fee > 0 || detail.items_fee && detail.items_fee.fee > 0 || (detail.items_fee.vip_card_id && detail.items_fee.vip_fee > 0)){
			has_price = true
		}	 
		return(
			<div className="Detail-content1">
                <h1 className="Detail-title">
                    {detail.title}
                </h1>
				{
					detail.sub_mchid_type == 'offline' && !has_price ? <div className='offline-amount'>线下收款</div> : null
				}
                <Flex className="Detail-subtitle" align={"around"}>
					<div>						
							{
								this.renderEnrollFee(detail)
							}					 										 			
						<div style={{display:'flex'}}>
							{
									
								enrollStore.statusText.map((item, index) => {
									return(
										<Flex.Item key={index} className={"SubTitleTag " + enrollStore.tagClassName}>
											{item}
										</Flex.Item>
									)
									
								})
							}	
						</div>
					</div>
					<button className="detail-notice-btn" style={{color:this.state.themColor}} onClick={() => {
							if(detail.is_fans){					
								this.child.setDialog('open')
							}else{
								window.location.href = 'https://mp.weixin.qq.com/s/7xoT8N4jJuR36X3UoZEQVg'
							}
						}}>						
						<span>订阅&nbsp;</span>
						<i className="notice-icon iconfont icon-svg45-copy" style={{color:this.state.themColor}}></i>
					</button>                   
                </Flex>
				
                <Flex className="Detail-content" direction={"column"} align={"start"} justify={"center"}>
                    {
                        listItem.map((item, index) => {
                            if (item.isShow) {
                                return (
                                    <Flex.Item key={index} className="Detail-content-text">
                                        <img src={item.img}/>
                                        <div className="Detail-content-right">
	                                        <span>{item.title}</span>
	                                        <span className={item.action ? "action" : ""} onClick={() => {
	                                            if (item.action) {
	                                                item.action();
	                                            }
	                                        }}>{item.content}</span>
                                        </div>
                                    </Flex.Item>
                                )
                            }
                        })
                    }

                </Flex>
            </div>
		)
	}
	
	renderEnrollFee(detail){
		if(detail.fee){
			return (
				<div className="enroll-price-panel">
					<div className="price-panel">
						<div className="new-price">￥<span className="price-num">{detail.fee / 100}</span></div>
					</div>
				</div>				
			)
		}else if(detail.items_fee && (detail.items_fee.fee > 0 || (detail.items_fee.vip > 0 && detail.items_fee.vip_card_id))){			
			return (
				<div className="enroll-price-panel">
					<div className="price-panel cursor">
						{
							detail.items_fee.fee? 
							<div className="new-price">￥<span className="price-num">{detail.items_fee.fee / 100}</span></div> : ''
						}
						
						{ 	detail.items_fee.vip_card_id ? <div className="vip-price" style={{marginLeft:detail.items_fee.fee ? '12px':'0'}} onClick={()=>{
							React.$wx.jumpNewPath.call(this,'/apply-vip?cid='+detail.items_fee.vip_card_id)
						}}>
								<span>￥{detail.items_fee.vip_fee / 100} </span>
								<span className='vip-tag'>会员价</span>
							</div> : null 
						} 
					</div>
				</div>				
			)
		}
		
	}

	//设置认证按钮是否显示
	 getBtn(detail) {
            if(detail.auth ==0 && detail.is_owner){
                return (<div className="role-btn" onClick={() => { this.showRoleMinProgram('block')}}>
		            	<div className="left-panel">
		            		<i></i>
		            		<span>升级VIP后，享有更多权益</span>
		            	</div>
		            	<div className="right-panel">
		            		<span>去认证 > </span>
		            	</div>
		            </div>)
            }
        }
	 
	getHeader(detail){
    	if(detail.auth ==0 || (!detail.role && detail.auth !=0)){
    		return(<img className='min-img-header' src={detail.owner_pic || Config.defaultAvatar}/>)
    	}else{
    		return(<img src={detail.owner_pic || Config.defaultAvatar} />)
    	}
    }
	
	getCreator(detail){
		if(detail.auth ==0 || (!detail.role && detail.auth !=0)){
			return(<span>发起人:</span>)
		}
	}

	//输出发起人相关信息
	renderOwner(detail) {
		let icon = null;
		//let text = detail.is_owner ? "待认证" : "";
		let text = "";
		let signName = detail.sign_name;
		
		if(detail.role){
			switch(detail.auth) {
				case 1:
					icon = img_identification_p;
					text = "个人";
					if(!detail.cert_show){
						let nameArr = detail.sign_name.toString();
						signName = nameArr[0] + '**';
					}
					break;
				case 2:
					icon = img_identification_c;
					text = "企业";
					break;
				case 3:
					icon = img_identification_o;
					text = "组织";
					break;
				case 4:
					icon = img_identification_g;
					text = "公众号";
					break;
				default:
					icon = null;
					break
			}
		}
		
		return(
			<div className="Detail-role-panle">
			<div className="Detail-originator">
				<div className="originator-info-panel">	
					<div className="originator-info" onClick={()=>{
								window._hmt.push(['_trackEvent', 'button_to_homepage','', '活动详情点击主页', '']); //百度统计
								React.$wx.jumpNewPath.call(this,'/h/'+detail.owner_unionid)
							}}>
						<div className='detail-originator-left'>
							{
								this.getHeader(detail)
							}
							<div className="originator-type">
								<h3 className="originator-top">
									{WxApi.getDefaultName(signName)}
								</h3>
								<div>
									{
										detail.role ? <div style={{backgroundColor: this.state.themColor}} className="auth-type-parent">
											<img className="auth-icon" src={ic_person_identity} />
											<label>{text}</label>
										</div> : null
									}
									<div className='send-message' onClick={(e)=>{
										e.stopPropagation()
										this.showActionSheet(1) 
									}}><i className="iconfont icon-xinxi"></i>&nbsp;留言</div>
								</div>
								
							</div>
						 </div>
						{
							 
							<button className="detail-originator-right">
								主页
							 	<img className="arrow-share" src={right_arrow} />
							 </button> 
						}
						
					</div>
				</div>								
				
				{ detail.summary ? <div className='owner-desc'>{detail.summary}</div>  : ''}							
            </div>
            {
            	this.getBtn(detail)
            }
            </div>
		)
	}

	// 消息订阅弹窗
	renderMsgDialog(detail){
		let signName = detail.sign_name;
		if(detail.auth == 1 && !detail.cert_show){
			let nameArr = detail.sign_name.toString();
			signName = nameArr[0] + '**';
		}
		return <NewMsgDialog ref="messageDom" signName={signName} owner_pic={detail.owner_pic || Config.defaultAvatar} sub_user={this.state.sub_user} 
		owner_unionid={detail.owner_unionid} onRef={(ref)=>{ this.child = ref}}/>
	}

	// 联合创作
	renderEnrollGroup(detail){
		let list = {					
			1:'共创',
			2:'嘉宾',
			3:'联名',
			4:'赞助',
			5:'联名'
		}
		return (
			<ul class="team-user-panel">
				<li className='team-list' onClick={()=>{
					React.$wx.jumpNewPath.call(this,'/h/'+detail.owner_unionid);
				}}>
					<div class="team-avatar">
						<img class="team-img" src={detail.owner_pic || Config.defaultAvatar} />
						<span class="team-tag">发起</span>	
						<img class="auth-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230705/872afef5e0be594e97fd9eaaf60f5fd1.png"></img> 
					</div>
					<span class="team-uname">{WxApi.getDefaultName(detail.sign_name)}</span>	
				</li>
				{
					detail.team_user.map((item,index)=>{
						return (
							index < 3 ? 
							<li class="team-list" key={index} onClick={()=>{
								React.$wx.jumpNewPath.call(this,'/h/'+item.unionid);
							}}>				
								<div class="team-avatar">
										<img class="team-img" src={item.upic || Config.defaultAvatar} />
										<span class="team-tag">{list[item.type]}</span>	
										{
											item.is_auth ? 
											<img class="auth-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230705/872afef5e0be594e97fd9eaaf60f5fd1.png"></img> : ''
										}										
								</div>				
								<span class="team-uname">{item.uname}</span>											 			 
							</li> : ''							
						)
					})
				}
			</ul>
		)
	}

	showActionSheet = (type) => {
		this.setState({
		    showSheet: type,
		})
	}
	
	renderSheet(detail){
		return(
			<div className={this.state.showSheet == 1 ? 'show-sheet-panel' : 'sheet-panel'} >
				<div className="mask" onClick={() => {
					this.showActionSheet('0')
				}}></div>
				<div className="sheet-box">	
					<div className='sheet-list'>
						<div className="contact-item" onClick={() => {							
							this.showActionSheet('none')
							if(!Config.TOKEN){
								React.$wx.webLogin()
							}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){
								this.getTicket(detail)
							}else{							
								this.props.history.push('/message')							
							}							 
						}}>
							<img className="icon-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230418/a5b8e63fc575c69742d46c36988d7c22.png" />
							<span>留言</span>
						</div>												 					
						{
							detail.phone && (detail.info_id || detail.is_owner || detail.is_admin) ? 
							<a className="contact-item" href={"tel:" + detail.phone} onClick={() => {
								this.showActionSheet('0') 
							}}>
								<img className="icon-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230418/9856a66de3a11ee08dc61dcccefd6748.png" />
								<span>打电话</span>
								{
									detail.is_owner ? <span className='gray-text'>提交人可见</span> : ''
								}
							</a> : null
						}
						{/* {
							detail.wx_no ? <div className='contact-item' onClick={()=>{
								this.showActionSheet('0')
								this.setState({showWxlink:true})	 
							}}>
								<img className="icon-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230418/41199ebc43c514d7551206ba6d910e67.png"></img>
								<span>加微信</span>
							</div> : ''
						}*/}
						{
							detail.contact_url ? <div className='contact-item' onClick={()=>{							 
								window.open(detail.contact_url);															  
							}}>
								<img className="icon-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230418/41199ebc43c514d7551206ba6d910e67.png"></img>
								<span>私聊</span>
							</div> : null
						}					
						<div className='contact-item' onClick={()=>{
							this.showActionSheet('none')
							if(!Config.TOKEN){
								React.$wx.webLogin()
							}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){
								this.getTicket(detail)
							}else{
								this.props.history.push(`/reward?eid=${detail.eid}&unionid=${detail.owner_unionid}`)
							}
						}}>
							<img className="icon-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230508/e53eb8c3ce294a353cb22ea10e2d3b2b.png"></img>
							<span>赞赏</span>
						</div>
					</div>				
					<div className="cancel-btn" onClick={() => {
						this.showActionSheet('0')
					}}>取消</div>
				</div>				
			</div>
		)
	}

	showRoleMinProgram(type){
		this.setState({
	        roleMinProgram: type,
	    })
		
	}
	
	//查看小程序二维码认证
	renderMinProgramRole(){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.roleMinProgram}}>
				<div className="share-choice-mask" onClick={() => { this.showRoleMinProgram('none')}}></div>
				<div className="minProgram-panel">
					<i className="close-btn" onClick={() => { this.showRoleMinProgram('none')}}></i>
					<div className="panel-title">请去小程序完成</div>
					<div className="QRCode-img">
						<img src={'https://cdn-xcxcustom.weiyoubot.cn/20190516/18325c92c33d3cea608109cade0fb00c.jpeg'} />
					</div>
					<div className="min-tips">长按识别二维码，立即体验</div>
				</div>
			</Flex>
		)
	}
	
	//获取描述内容
	getContent(detail){		
		if(detail.content && detail.content !="无"){
			return(<pre className="Detail-describe-img">{detail.content}</pre>)
		}else{
			return(<div className="nullData"></div>)
		}
	}

	//输出描述
	renderDescribe(detail) {
		let enrollStore = this.props.enrollStore;
		return(
			<Flex className="Detail-describe" direction={"column"}>
				{
					detail.content ? <Flex.Item className={"Detail-describe-content" }>
					{
							Array.isArray(detail.content) ? detail.content.map((item, index)=>{
								const style = {
								 color: item.color || '#333',
								 fontSize: item.font_size / 2 || 17 + 'px',
								 textAlign: item.text_align || 'left'
 
							 }
								if(item.type == "text" && item.value){
									 if(index == 0){
										let description = item.value.substring(0,100)
										document.getElementsByTagName('meta')[4].setAttribute("content",description)
									 }
									 
									 return(<pre className="Detail-describe-img" key={index} style={style}>{item.value}</pre>)
								 }else if(item.type == "img" || item.type == 'img_hd'){
									 return(<div className="Detail-describe-img detail-img" key={index}><img onClick={() => {
											  React.$wx.previewImage({
												  current: item.value, // 当前显示图片的http链接
												  urls: [item.value]// 需要预览的图片http链接列表
											  });
										  }} src={item.value} /></div>)
								 }
								 else if (item.type == "file"){
									let colorType = 'bg-default';
									let fileList = {
										'W':'bg-blue',
										'doc':'bg-blue',
										'X':'bg-green',
										'pdf':'bg-red',
										'P':'bg-orange'
									}
									if(fileList[item.file_type]){
										colorType = fileList[item.file_type];
									}									
									let file_index = item.name.lastIndexOf('.');				
									return(<div onClick={() => {
										if(item.download == 1){ //只可查看不可下载
											if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器					
												this.showDetailQrCode('block');
											}else if(WxApi.isWeixn() && !Config.token){
												React.$wx.webLogin();
											}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器				
												this.getTicket(detail);
											}										
										}else{
											window.location.href = item.url;
										}										
									}} className="rule-file-item">
										<div className={["rule-file-type "+colorType].join('')}>{item.file_type}</div>
										<div className="rule-file-name">{file_index > -1 ? item.name.substring(0,file_index) : item.name}</div>
										{
											item.download != 1 ? <img className='down-btn' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230426/0632028fda6d1c479fab874370e9fa80.png"></img> : ''
										}										
										{
											WxApi.isWeixn() && WxApi.isMobile() && item.download == 1 ?
											<wx-open-launch-weapp												
												id="jump-wx-btn"
												username="gh_ae6449501a82"
												path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
												>																						
												<script type="text/wxtag-template">
													<div style={{width:'100%',height:'60px'}}></div>
												</script>																				
											</wx-open-launch-weapp> : ''
										}											
									</div>)
								 }else if(item.type == 'url'){
									 return (
										 <div className="article-box" onClick={()=>{
											 window.location.href = item.link;
										 }}>
											 <img src={item.icon} className="article-img"></img>
											 <p className="article-title">{item.value}</p>
										 </div>
									 )
								 }else if(item.type == 'adress'){
									 return (
										<div className='adress-box' onClick={()=>{
											if(WxApi.isMobile() && WxApi.isWeixn()){
												if(!Config.TOKEN){
													React.$wx.webLogin();
													return;
												}
												window.wx.openLocation({
													latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
													longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
													address: item.value, // 地址详情说明
													scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
												});
											}else{
												const linkElement = document.createElement('a');
												linkElement.target="_blank";
												linkElement.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${item.latitude},${item.longitude};title:地址;addr:${item.value}&referer=myapp`
												// linkElement.href = `https://api.map.baidu.com/marker?location=${detail.latitude},${detail.longitude}&title=活动地址&content=${detail.address}&output=html&src=地图&ak=G1RjyBHcccDk6pb865C4FrPXjcpB2eKM`						
												const clickEvent = new MouseEvent('click', {
													view: window,
													bubbles: true,
													cancelable: true
												});							
												linkElement.dispatchEvent(clickEvent);
											}														 
										 }}>
											<img src={icon_adress} className="adress-img" />
											<span> {item.value}</span>
										 </div>
									 )
								 }else if(item.type == 'video'){
									 return (
										<video width="320" height="240" controls="controls" style={{display:'block',margin:'10px auto'}}>
											<source src={item.value} type="video/mp4" />
										</video>
									 )
								 }else if(item.type == 'copy'){
									 return (
										<div className="copy-box applet-box flex-between-box" onClick={()=>{
											WxApi.copeText(item.value)
											Toast.success('复制成功')							
										}}>											 
											<img className="applet-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230220/bbc74473a300035bee8247e1037e4adb.png" />
											<div className="applet-content">												
												<p className="copy-desc">{item.desc}</p>												
												<span>{item.value}</span>																							
											</div>
											<Button type="primary" className="open-btn">复制</Button>
										</div>
									 )
								 }else if(item.type == 'program'){
									const miniBtnStyle = {
										display:'inline-block',
										width:'70px',
										height:'28px',
										padding:'10px 0',																	
										position:'absolute',
										right: '10px',
										top:'7px',
										zIndex:"10"					
									}
									let defaultAppLog = "https://cdn-xcx-qunsou.weiyoubot.cn/material/20230220/4bb82882884598314f9ab20e78f55328.png";
									return (
										<div className="applet-box flex-between-box">  
											<img className="applet-img" src={item.logo||defaultAppLog} />                     											
											<div className="applet-content">
												<span>{item.value}</span>
												<p className="applet-desc">{item.desc}</p>
											</div>
											<Button type="primary" className="open-btn" onClick={()=>{
												if(item.mini_type > 0){
													window.open(item.path);
												}else if(!Config.TOKEN){
													React.$wx.webLogin();
												}											
											}}>打开</Button>
											{
												item.mini_type == 0 && Config.TOKEN ? 
												<wx-open-launch-weapp id="launch-btn" style={miniBtnStyle} username={item.originalId} path={item.path}>
													<script type="text/wxtag-template">														
														<span type="primary" style={miniBtnStyle}></span>												
													</script>
												</wx-open-launch-weapp> : ''
											}																				
										</div>
									)
								 }else if(item.type == 'channel'){
									return (
										<div className="channel-box">
											<img className="channel-img" src={icon_video_img}/>                      
											<span>{item.value}</span>
										</div>
									)
								 }else if(item.type == 'audio'){
									return (
										<div className="audio-box">
											<div className="audio-main" onClick={()=>{
												 if(this.state.currAudioIndex == index){
													this.audioBox.pause();
													this.state.currAudioIndex = -1;
												}else{
													if(!this.audioBox){
														this.audioBox = new Audio();
													}
													this.audioBox.src = item.path;
													this.audioBox.addEventListener('ended',()=>{ //语音播放完毕
														this.setState({ currAudioIndex: -1 });                
													});
													this.audioBox.play();													 
													this.setState({ currAudioIndex: index });
												} 
											}}>     
												{
													index == this.state.currAudioIndex ?
													<img className="audio-play-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230706/46e95d5bad6311ee3baa23f62f144377.gif"></img>
													:
													<img className="audio-play-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230706/2f2983dd7572c12401096261d73bea12.png"></img>
												}
												<span>{item.duration}″</span>
											</div>                        
										</div>  
									)
								}									
							}):this.getContent(detail)
					}
					   {
						   // <div className="show-more-btn">展开更多</div>
					   }
				 </Flex.Item>:null
				}
            </Flex>
		)
	}
	
	//口令输入框
	renderTokenPanel(detail){
		return(
			<Flex className="Detail-join-token" style={{display:this.state.tokenPanel}}>
				<div className="share-choice-mask" onClick={() => {this.setTokenType('none') }}></div>
					<div className="minProgram-panel">
					<div className="read-main">
						<div className="panel-title">输入口令</div>
						{detail.has_token == 1&&detail.token_desc ? <div className="cen-token" style={{whiteSpace:'pre-wrap'}}>口令获取方法：{detail.token_desc}</div> : null}
						<div className="token-input-panel">
							<InputItem className="token-input" value={this.state.token} onChange={(value) => this.onChange(value)} placeholder='请输入口令' />
						</div>
						<div className="sure-btn-panel flex-start-box">
							<Button className="btn-cancel" onClick={() => { this.setTokenType('none') }} >取消</Button>
							<Button className="btn-get-token" type="primary" onClick={() => { this.submitToken(1,detail) }} >确定</Button>
						</div>
						{/* <div className="token-desc">活动发起人要求必须输入正确口令才能报名,如有问题,请与TA联系。 </div> */}
					</div>
				</div>
			</Flex>
		)
	}

	//提交前阅读框
	renderSubmitRead(detail){
		return(
			<div>
				{this.state.showReadDialog?( 
				<Flex className="Detail-join-token">
				<div className="share-choice-mask" onClick={()=>{this.setReadDialog(false)}}></div>
				<div className={`minProgram-panel read-box` }>
					<div className="read-main">
						<div className="panel-title">{detail.token_desc.title}</div>
						<div className='read-content'>
							<p className="read-text" style={{whiteSpace:'pre-wrap'}}>{detail.token_desc.content}</p>							
							{
								detail.token_desc && detail.token_desc.pics && detail.token_desc.pics.length ? 
								detail.token_desc.pics.map(item=>{
									return <img className='read-img' src={item.url} onClick={()=>{
										let pics = detail.token_desc.pics.map(item=>item.url);
										React.$wx.previewImage({
											current: item.url, // 当前显示图片的http链接
											urls: pics // 需要预览的图片http链接列表
										})
									}}></img>
								}) : ''
							}							
						</div>				
						{ detail.token_desc.feedback == 2 ? 
							<div>
								<div className="token-input-panel">
								<InputItem className="token-input" value={this.state.token} placeholder={`请手动输入“${detail.token_desc.reaction}”`} onChange={(value) => this.onChange(value)}/>
								</div>
								<div className="sure-btn-panel">
								<Button className="btn-get-token" type="primary" onClick={() => { this.submitToken(2,detail) }} >确定</Button>
								</div>
							</div>
							:  
							<div className="sure-btn-panel">
								{detail.token_desc.feedback == 0?
								<Button className="btn-get-token" type="primary" onClick={() => { this.handleSubmit(detail) }} >我已知晓</Button>
								:
								<Button className="btn-get-token" type="primary" disabled={this.state.countDown>0} onClick={() => { this.handleSubmit(detail) }} >我已知晓{this.state.countDown ==0?'':`(${this.state.countDown})`}</Button>
								}
							</div>						 
						}
					</div>
					
				</div>
			</Flex>)
			:null}
		</div>
		)
	}
	
	submitToken(type,detail){
		this.props.myEnrollStore.subToken(this.state.token).then(() => {
			let {tokenPass} = this.props.myEnrollStore;
			if(tokenPass){
				this.setTokenType('none');
				this.setState({
					showReadDialog:false,
					token:''
				})
				if(detail.temp == 10){
					this.setState({
						showSeat: true,
					})
				}else if(detail.temp == 8){
					this.setState({
						showReverse: true,
					})
				}else{
					this.props.history.push('/enrollForm')
				}
			}else{
				if(type == 1){
					Toast.fail('口令错误', 2);
				}else{
					Toast.fail('输入内容错误', 2);
				}
				
			}
		});
	}
	
	handleSubmit(detail){
		this.setState({
			showReadDialog:false
		})
		if(detail.temp == 10){
			this.setState({
				showSeat: true,
			})
		}else if(detail.temp == 8){
			this.setState({
				showReverse: true,
			})
		}else{
			this.props.history.push('/enrollForm')
		}
	}

	onChange(value){
		this.setState({
	        token: value,
	    })
	}

	setValue(value){
		this.setState({
	        reaction: value,
	    })
	}

	setTokenType(type){
		this.setState({
	        tokenPanel: type,
	    })
	}

	setReadDialog(type){
		clearInterval(this.timer)
		this.setState({
			showReadDialog:type
		})
	}
	gotoEnroll(detail, index, item){		 	 
		if(item && item.key){ //如果有分类，则索引需要进行匹配
			let i = detail.items.findIndex(data=>data.key == item.key);
			if(i > -1){ index = i };
		}
		window.reserve_index = index;
		this.props.myEnrollStore.setItemIndex(index);
		this.props.myEnrollStore.setCurrentEnroll(0);
		if(detail.has_token == 1){
			this.setTokenType('block');
			detail.token_desc = detail.token_desc.replace(/\n/g, '\n')
		} else if(detail.has_token == 2){
			detail.token_desc.content = detail.token_desc.content.replace(/\n/g, '\n')
			this.setReadDialog(true)
			this.timer = setInterval(()=>{
				if(this.state.countDown<=0){
					this.state.countDown = 0
					clearInterval(this.timer)
				}else{
					this.state.countDown--
				}
				this.setState({
					countDown:this.state.countDown
				})
			},1000)
		}else{
			if(detail.temp == 10){
				this.setState({
					showSeat: true,
				})
			}else if(detail.temp == 8){
				this.setState({
					showReverse: true,
				})
			}else{
				this.props.history.push('/enrollForm')
			}
			
			
		}
	}

	//相关逻辑
	renderButton(detail) {
		let btnColor = {
			backgroundColor:"#00a8f3"
		}
		if(detail && detail.btn_config && detail.btn_config.color){
			btnColor.backgroundColor = detail.btn_config.color;
		}
		if(Config.TOKEN){
			let isEditor = detail.period_config && detail.period_config.day_type == 'all' && detail.period_config.count == 1; //如果是总共一次，则显示编辑按钮
			if(detail.status == 2 && !detail.info_id && detail.on_behalf == 0) {
				return(<Button type="primary" style={btnColor} onClick={()=>{
					this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
					this.props.history.push('/cert');
				}}>已截止</Button>)
			}else if(detail.status == 0) {
				return(<Button type="primary" style={btnColor} onClick={()=>{
					this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
					this.props.history.push('/cert');
				}}>未开始</Button>)
			}else if (detail.temp == 8 && !detail.info_id) {
				return (<Button type="primary" style={btnColor} className="btn-my-reserve" onClick={async () =>{
					if(detail.sub_user_setting && detail.sub_user_setting.length && detail.sub_user_setting.indexOf('all') == -1 && detail.sub_user_setting.indexOf('white') == -1){						 
						if((detail.sub_user_setting.indexOf('vip') != -1 && this.state.vipList.length > 0) || (detail.sub_user_setting.indexOf('fans') != -1 && detail.sub_user)){
							await this.getEnrollStatus(detail)
							this.props.enrollStore.getItemData().then(()=>{
								this.props.myEnrollStore.setMyEnrollList([])
								this.setReverseTag(detail) //预约项目
								this.setState({
									showReverse: true,
								})
							})
							return
						}else if(detail.sub_user_setting.indexOf('fans') != -1 && !detail.sub_user){
							Modal.alert('温馨提示','你还不是当前组织的粉丝，不能参加此活动，请先去关注活动组织者', [
								{ text: '取消', style: 'default'},
								{ text: '去关注', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/h/'+sessionStorage.getItem('owner_unionid'))
								} },
							])
							return
						}else if(detail.sub_user_setting.indexOf('vip') != -1 && !this.state.vipList.length){
							Modal.alert('温馨提示','你还不是当前组织的会员，不能参加此活动，请先去申请成为会员', [
								{ text: '取消', style: 'default'},
								{ text: '去申请', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/apply-vip?cid='+detail.items_fee.vip_card_id)
								} },
							])
							return
						}else if(detail.sub_user_setting.indexOf('end_all') != -1){
							Modal.alert('温馨提示','发起人设置了所有人不可提交，暂时无法提交', [
								{ text: '取消', style: 'default'},
								{ text: '去留言', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/message')
								} },
							])
							return
						}			 
					}
					await this.getEnrollStatus(detail)
					this.props.enrollStore.getItemData().then(()=>{
						this.props.myEnrollStore.setMyEnrollList([])
						this.setReverseTag(detail) //预约项目
						this.gotoEnroll(detail)						
					})									
				} }>立即预约</Button>)	
			}else if(detail.count >= detail.limit && !detail.info_id && detail.on_behalf==0 && detail.limit!=0 && detail.queue){
				return(<Button type="primary" style={btnColor} onClick={async () =>{
					await this.getEnrollStatus(detail)
					this.props.enrollStore.getItemData().then(()=>{
						this.gotoEnroll(detail)
					})
				} 
			}>排队候补</Button>)				
			}else if((detail.count >= detail.limit && !detail.info_id && detail.on_behalf == 0 && detail.limit != 0) || (detail.temp == 5 && !detail.info_id && detail.no_quoto)) {
				return(<Button type="primary" style={btnColor} onClick={()=>{
					this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
					this.props.history.push('/cert');
				}}>名额已满</Button>)
			}else if((detail.status == 1 && ((!detail.info_id && !detail.period) || (detail.period == 1 && !detail.period_info) ) && detail.on_behalf == 0) || (detail.on_behalf && (detail.pay_status == 1 || detail.pay_status == 2))) {
				return <Button type="primary" style={btnColor} onClick={async()=>{
					if(detail.sub_user_setting && detail.sub_user_setting.length && detail.sub_user_setting.indexOf('all') == -1 && detail.sub_user_setting.indexOf('white') == -1){						 
						if((detail.sub_user_setting.indexOf('vip') != -1 && this.state.vipList.length > 0) || (detail.sub_user_setting.indexOf('fans') != -1 && detail.sub_user)){
							await this.getEnrollStatus(detail)					
							this.props.enrollStore.getItemData().then(()=>{
								this.gotoEnroll(detail)
							})
							return
						}else if(detail.sub_user_setting.indexOf('fans') != -1 && !detail.sub_user){
							Modal.alert('温馨提示','你还不是当前组织的粉丝，不能参加此活动，请先去关注活动组织者', [
								{ text: '取消', style: 'default'},
								{ text: '去关注', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/h/'+sessionStorage.getItem('owner_unionid'))
								} },
							])
							return
						}else if(detail.sub_user_setting.indexOf('vip') != -1 && !this.state.vipList.length){
							Modal.alert('温馨提示','你还不是当前组织的会员，不能参加此活动，请先去申请成为会员', [
								{ text: '取消', style: 'default'},
								{ text: '去申请', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/apply-vip?cid='+detail.items_fee.vip_card_id)
								} },
							])
							return
						}else if(detail.sub_user_setting.indexOf('end_all') != -1){
							Modal.alert('温馨提示','发起人设置了所有人不可提交，暂时无法提交', [
								{ text: '取消', style: 'default'},
								{ text: '去留言', onPress: () => {	
									React.$wx.jumpNewPath.call(this,'/message')
								} },
							])
							return
						}						 
					}
					await this.getEnrollStatus(detail)					
					this.props.enrollStore.getItemData().then(()=>{
						this.gotoEnroll(detail)
					})							
				}}>{detail.btn_config && detail.btn_config.text ? detail.btn_config.text : this.state.btnTypeText[detail.btn_type]}</Button>
			} else if(detail.status == 2 && detail.info_id && detail.on_behalf == 0) {
				return(
					<Button type="primary" style={btnColor} onClick={() => {
						this.props.enrollStore.getItemData().then(()=>{
							this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
	                    	this.props.history.push('/enrollForm');
						})	                
	                }}>查看/取消</Button>)
			} else if(detail.status == 2 && (detail.info_id || detail.on_behalf)) {
				return(
					<Button type="primary" style={btnColor} onClick={()=>{
						this.props.enrollStore.getItemData().then(()=>{
							this.props.history.push('/enrollList')
						})						
					}}>查看/取消</Button>)
			} else if(detail.temp != 8 && detail.status == 1 && (detail.info_id || (detail.period == 1 && detail.period_info)) && (isEditor || (detail.on_behalf_limit == 0 && detail.period_config && !detail.period_config.count))) {
				return(
					<Button type="primary" style={btnColor} onClick={() => {
						this.props.enrollStore.getItemData().then(()=>{
							this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
	                    	this.props.history.push('/enrollForm');
						})	                    
	                }}>编辑/取消</Button>)
			}
			else if(detail.status == 1 && ((detail.info_id || (detail.period == 1 && detail.period_info)) || (detail.period_config &&  detail.period_config.count > 1) ||  (detail.period_config && !detail.period_config.count && detail.on_behalf_limit != 0))) {
				return(
					<Button type="primary" style={btnColor} onClick={() =>{						
						this.props.enrollStore.getItemData().then(()=>{
							this.props.history.push('/enrollList')
						})							
					} }>新增/取消</Button>)
			} 
		}else{
			return <Button type="primary" style={btnColor} onClick={() => {
				this.showMinProgram()
			}}>{detail.btn_config && detail.btn_config.text ? detail.btn_config.text : this.state.btnTypeText[detail.btn_type]}</Button>		
		}

		return null;
	}

	// 判断发起人是否设置了所有人不可提交
	getEnrollStatus(detail){
		return new Promise((resolve,reject)=>{
			if(detail.sub_user_type == 'end_all'){
				Modal.alert('温馨提示','发起人设置了所有人不可提交，暂时不可提交', [
					{ text: '取消', style: 'default'},
					{ text: '去留言', onPress: () => {	
						this.props.history.push('/message') 
					} },
				])
				reject()
			}
			resolve()
		})		
	}
	
	getsShareImg(type,detail) {
		this.props.enrollStore.getShare(type).then((d) => {
			if(d && d.url) {
				if(type == 1){
					this.setState({
					    shareImgUrl: d.url,
					})
					this.props.myEnrollStore.setShareImg(d.url)
				}
				if(detail){
					this.setState({sharePanel:'none'})
					React.$wx.previewImage({
						current: d.url, // 当前显示图片的http链接
						urls: [d.url] // 需要预览的图片http链接列表
					});
				}
			}
		});
	}

	shareQrcode(detail) {
		var url = detail.follow.qrcode;
		React.$wx.previewImage({
			current: url, // 当前显示图片的http链接
			urls: [url] // 需要预览的图片http链接列表
		});
	}

	showFollow() {
		var url = 'https://cdn-xcxcustom.weiyoubot.cn/20190321/0c898d0e90173f659ef8e135d2a8c4c1.jpeg';
		React.$wx.previewImage({
			current: url, // 当前显示图片的http链接
			urls: [url] // 需要预览的图片http链接列表
		});
	}

	qrCodeBox(detail) {
		var follow = detail.follow;
		if(follow.title && (follow.visi || (follow.visi == 0 && detail.info_id))) {
			return(
				<Flex className="qrcode-box" onClick={() => {
                        this.shareQrcode(detail)
                    }}>
                    <img src={detail.follow.header} className="qrcode-icon" />
                    <div className="qrcode-body">
                        <div className="qrcode-title">{follow.title}</div>
                        <div className="qrcode-desc">{follow.desc}</div>
                    </div>
                    <Button>{follow.type == 1 ? "添加" : (follow.type == 2 ? "关注" : "进群")}</Button>
                </Flex>
			)
		}
	}
 
	reserveBtn(detail, item, index) {
		if (item.pause) {
			return (<div className="btn-reserve btn-disabled">已暂停</div>)
		}
		else if (detail.status == 2) {
			return (<div className="btn-reserve btn-disabled">已结束</div>)
		}
		else if (detail.status == 0) {
			return (<div className="btn-reserve btn-disabled">未开始</div>)
		}else if(!item.timespan.length){
			return (<div className="btn-reserve">已满</div>)
		}
		else {
			return (<div className="btn-reserve">预约</div>)
		}
	}

	handleTransfor(detail){
		if(detail.is_owner){
			Modal.alert('温馨提示','不能转载自己的活动',[{ text: '我知道了' }]);
			return;
		}
		const params = {
			eid: detail.eid,
			type: 'sub'
		}
		this.props.myEnrollStore.reshippedEnroll(params).then(res=>{
			if(res.sta == 0){
				this.showSharePanel('none');
				Modal.alert('温馨提示', '转载成功，请到你的个人主页查看', [					
					{ text: '取消', onPress: () => console.log('ok')},
					{ text: '前往主页查看', onPress: () => {
						React.$wx.jumpNewPath.call(this,'/h/'+detail.unionid);
					}},
				]);			 				
			}else{
				Toast.fail(res.msg);
			}
		})
	}

	showSharePanel(type){
		this.setState({
	        sharePanel: type,
	    })
	}
	//分享选择面板
	renderSharePanel(is_show,detail) {
			return(
				<Flex className="Detail-share-choice-panel" style={{display:this.state.sharePanel}}>
	    			<div className="share-choice-mask" onClick={() => { this.showSharePanel('none')}}></div>
	    			<div className="share-choice-panel">
	    				<div className="share-btn-panel">
	    					<div className="share-icon" onClick={() => { this.showFriend('block')}}>
	    						<i></i>
	    						<div>分享给好友</div>
	    					</div>
	    					<div className="share-icon poster-img" onClick={() => {this.getsShareImg(0,detail)}}>
	    						<i></i>
	    						<div>海报分享图</div>
	    					</div>
							<div className="share-icon wx-img" onClick={() => { this.getsShareImg(1,detail)}}>
	    						<i></i>
	    						<div>小程序码</div>
	    					</div>
	    					<div className="share-icon qrcode-img" onClick={() => {this.getsShareImg(4,detail)}}>
	    						<i></i>
	    						<div>二维码</div>
	    					</div>
							<div className="share-icon mobile-img" onClick={() => {
								let _url = `https://p.baominggongju.com/detail?eid=${detail.eid}`;
								if(detail.unionid){
									_url += `&referer=${detail.unionid}`;		
								}else if(Config.referer){
									_url += `&referer=${Config.referer}`;							
								}
								WxApi.copeText(_url);
								Toast.success('链接复制成功');
								this.setState({sharePanel: 'none'});
							}}>
	    						<i></i>
	    						<div>H5链接</div>
	    					</div>	
							<div className='share-icon command-img' onClick={()=>{								 
								this.props.myEnrollStore.getSearchToken().then(res=>{
									if(res.sta == 0){									 
										this.setState({
											enroll_token: res.data.search_token,
											sharePanel: 'none',
											showEnrollToken: true
										})
									}else{
										Toast.fail(res.msg);
									}
								})								
							}}>
								<i></i>
								<div>活动口令</div>
							</div>	
							{
								detail.is_owner != 1 ? 
								<div className="share-icon transfor-img" onClick={() => {this.handleTransfor(detail)}}>
									<i></i>
									<div>转载到主页</div>
								</div> : ''
							}												 
	    				</div>
	    				<div className="cancel-btn" onClick={() => { this.showSharePanel('none')}}>取消</div>
	    			</div>
	    		
	    		</Flex>
			)
	}
	
	
	showMinProgram(){
		React.$wx.webLogin()
	}


	showDetailQrCode(type){
		let self = this;
		this.props.enrollStore.getShare(1).then((d) => {
			if(d && d.url) {
				self.setState({
					showDetailQrCode: type,
					detailQrCode: d.url,
				})
			}
		});

	}
	//查看小程序二维码
	renderDetailQrCode(){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.showDetailQrCode}}>
				<div className="share-choice-mask" onClick={() => { this.showDetailQrCode('none')}}></div>
				<div className="minProgram-panel">
				<div className="new-share-minImg">
					<i className="close-btn" onClick={() => { this.showDetailQrCode('none')}}></i>
					<div className="panel-title">请去「报名工具」小程序查看</div>
					<div className="QRCode-img">
						<img src={this.state.detailQrCode} />
					</div>
					<div className="min-tips">长按识别二维码，立即体验</div>
					</div>
				</div>
			</Flex>
		)
	}

	showHomePageQrCode(type, detail){
		let self = this;
		this.props.enrollStore.getHomePageShare(detail.owner_unionid).then((d) => {
			if(d && d.url) {
				self.setState({
					showHomePageQrCode: type,
					detailQrCode: d.url,
				})
			}
		});

	}
	//查看小程序二维码
	renderHomePageQrCode(detail){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.showHomePageQrCode}}>
				<div className="share-choice-mask" onClick={() => { this.showHomePageQrCode('none', detail)}}></div>
				<div className="minProgram-panel">
					<i className="close-btn" onClick={() => { this.showHomePageQrCode('none', detail)}}></i>
					<div className="panel-title">长按识别二维码，查看Ta的主页</div>
					<div className="QRCode-img">
						<img src={this.state.detailQrCode} />
					</div>
				</div>
			</Flex>
		)
	}
	// 查看活动口令
	renderEnrollToken(){
		return(
			<Modal
			visible={this.state.showEnrollToken}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showEnrollToken:false})}}
			title="分享当前活动"				
		 	>
			<div className='modal-address-panel'>
				<p className='token-title'>参与人打开小程序可以直接进入活动页面</p>
				<p className='token-content'>{this.state.enroll_token} 打开报名工具小程序首页搜索</p>
				<Button type="primary" className='copy-btn' onClick={()=>{
					WxApi.copeText(this.state.enroll_token + ' 打开报名工具小程序首页搜索');
					Toast.success('复制成功');
					this.setState({showEnrollToken:false});
				}}>复制口令</Button>
			</div>
		  </Modal>			 
		)
	}

	// 线上活动地址
	renderLink(detail){		 
		return (
			<Modal
			visible={this.state.showLink}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showLink:false})}}
			title="线上活动地址"				
		 	>
			<div className='modal-address-panel'>
				<p className='address-content'>{detail.address}</p>
				<Button type="primary" className='copy-btn' onClick={()=>{
					WxApi.copeText(detail.address);
					Toast.success('复制成功');
					this.setState({showLink:false});
				}}>一键复制</Button>
			</div>
		  </Modal>
		)
	}

	// 复制微信
	renderWxLink(detail){		 
		return (
			<Modal
			visible={this.state.showWxlink}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showWxlink:false})}}
			title="微信号"				
		 	>
			<div className='modal-address-panel'>
				<p className='address-content'>{detail.wx_no}</p>
				<Button type="primary" className='copy-btn' onClick={()=>{
					WxApi.copeText(detail.wx_no);
					Toast.success('复制成功');
					this.setState({showWxlink:false});
				}}>一键复制</Button>
			</div>
		  </Modal>
		)
	}
	
	// 有争议的活动
	renderCensorModal(){
		return (			 
			<Modal
			popup
			visible={this.state.censorModal}
			onClose={()=>{this.setState({censorModal: false })}}
			animationType="slide-up"	
			className='modal-censor-panel'						
			>
				<div className='main-container'>
					<div>
						<img className="warn-img" src="https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg" />
						<h4 className='warn-title'>活动存在有争议的内容</h4>
						<span>请耐心等待协商解决</span>
					</div>
					<div className="footer-panel">
						<Button className='cen-button continue-btn' onClick={()=>{
							this.setState({ censorModal: false });
						}}>继续访问</Button>
						<Button className='cen-button cancel-btn' type="primary" onClick={()=>{
							this.setState({ censorModal: false });
							this.props.history.push('/manage/myEnroll');
						}}>取消</Button>
					</div>
				</div>					
			</Modal>			 
		)
	}

	showFriend(type){
		this.setState({
	        shareFriend: type,
	        sharePanel: 'none',
	    })
	}
	renderShareTips(){
		return(
			<Flex className="Detail-share-tips-panel" style={{display:this.state.shareFriend}}>
				<div className="share-choice-mask" onClick={() => { this.showFriend('none')}}></div>
				<div className="tips-content" onClick={() => { this.showFriend('none')}}>
					<img className="arrow-share" src={img_mask_arrow} />
					<div className="share-text">
						<span>点击右上角“...”</span><br/>
						<span>选择“发送给朋友”或者“分享到朋友圈”</span><br/>
						<span>即可完成分享</span>
					</div>
				</div>
			</Flex>
		)
	}

	// 预约项目
	reservePro(detail) {
		if (detail.temp == 8 && this.state.showReverse) {
            return(
             <div className="reverse-info-panel">
				<div className="mask" onClick={() => {
					this.setState({
						showReverse: false,
						})
					}}>
				</div>
				<div className="reserve-box">
				  <div className="reserve-title">请选择</div>
					<div className="reserve-main">
					{
						<div className="reserve-left-menu">
							{
								this.state.tagList.map((item,index)=>{
									return (
										<div className="menu-item" style={{'backgroundColor':index == this.state.currIndex?'#fff':''}} onClick={()=>{
											const currTags = this.state.tagList[index]
											if(currTags == '全部'){
												this.state.reverseList = detail.items
											}else{
												this.state.reverseList = detail.items.filter(item=>{
													return item.tag == currTags
												})
											}

											this.setState({
												currIndex:index,
												reverseList:this.state.reverseList
											})
										}}>{item}</div>
									)
								})
							}
						</div>
					}
					{
						<div className="reserve-data">
						{
							Array.isArray(this.state.reverseList) ? this.state.reverseList.map((item, index) => {
								let reverse_pic = WxApi.getAutoImage(item.pics[0], 160, 'single')
								return(
									<div className="reserve-item" key={index} onClick={()=>{
										if(!item.pause && detail.status != 2 && detail.status != 0){
											if(!item.timespan.length){
												this.props.history.push('/cert?isOver=1');
											}else{											 
												if(item && item.key){ //如果有分类，则索引需要进行匹配
													let i = detail.items.findIndex(data=>data.key == item.key);
													if(i > -1){ index = i };
												}
												window.reserve_index = index;
												this.props.myEnrollStore.setItemIndex(index);
												this.props.myEnrollStore.setCurrentEnroll(0);
												this.props.history.push('/enrollForm');											 
											}
										}
									}}>
										<img className="reserve-icon" src={reverse_pic} onClick={(e) => {
											e.stopPropagation();
											React.$wx.previewImage({
												current: item.pics[0], // 当前显示图片的http链接
												urls: item.pics
											});
										}
										} />
										<div className="reserve-bd">
										<div className="reserve-name">{item.name}</div>
										<div className="reserve-desc">{item.desc}</div>
										</div>
										{
											this.reserveBtn(detail, item, index)
										}
									</div>
								)
							}) : null
						}
						</div>
					}
					</div>
				</div>
			 </div>
			)
		}
	}
	
	//渲染座位
	renderSeat(detail) {
		if(detail.temp == 10 && detail.items && detail.items.length > 0 && this.state.showSeat){
			const {setItemKey, setSeatFee, setItemIndex} = this.props.myEnrollStore;
			return(
				<div className="seat-info-panel">
					<div className="mask" onClick={() => {
							this.setState({
								showSeat: false,
							})
						}}></div>
					<div className="seat-panel">
						<div className="seat-title">
							<div className="title-text">请选择</div>
							<img onClick={() => {
							this.setState({
								showSeat: false,
							})
						}} src={close_btn}></img>
						</div>
						<div className="seat-content-box">
							{
								detail.items.map((item,index) =>{
									let seat_pics = WxApi.getAutoImage(item.pics[0], 160, 'single');
									return(
										<div className="seat-item" onClick={() => {
											if(item.booked.length == item.seatNum){
												this.props.history.push('/cert?isOver=1');
											}else{
												window.reserve_index = index;
												setItemKey(item.key);
												setItemIndex(index);
												if(item.fee > 0){
													setSeatFee(item.fee);
												}
												this.props.history.push('/seat');
											}
										}}>
											<img className="item-banner" src={seat_pics} onClick={(e)=>{
												e.stopPropagation();
												React.$wx.previewImage({
													current: item.pics[0], // 当前显示图片的http链接
													urls: item.pics
												})
											}}/>
											<div className="item-content">
												<div className="left_panel">
													<div className="item-name">{item.name}</div>
													<div className="seat-num">
													    <span>可选：{item.booked.length}/{item.seatNum}</span>
														{ this.renderFee(item) }
													</div>
												</div>
												{
													item.booked.length == item.seatNum && !item.pause ? 
													<Button size='small' className='over-btn' type="primary">已满</Button> : ''
												}
												{
													item.pause ? <Button size='small' className='pause-btn' type="primary" disabled>已暂停</Button> : null
												}
												<img className="right-arrow" src={right_arrow} />
											</div>
										</div>
									)
									
								})
							}
						</div>
					</div>
				
				</div>
			)
		}
		
	}

	renderFee(item){
	    if(item && item.def_fee){
            if(item.def_fee.fee && item.vip_card_id){
            	return <div className="fee-text">
						<span className="fee-num">￥{item.def_fee.fee/100}&nbsp;</span>
						<span className="vip-fee-text">会员￥{item.def_fee.vip_fee/100}</span>
					</div>
            }else if(item.def_fee.fee){
            	return <span className="fee-text">￥{item.def_fee.fee/100}</span>
            }else if(item.vip_card_id){
            	return <div className="fee-text"><span className="vip-fee-text">会员￥{item.def_fee.vip_fee/100}</span></div>
            }else if(item.fee){
                return 
            }
        }else if(item && item.fee){//旧数据取值
            return <div className='fee-text'>￥{item.fee / 100}</div>
        } 
	}

	render() {
		let {detail} = this.props.enrollStore;		
		let miniBtn = {
			width:'150px',
			height:'40px',
			backgroundColor:"#fff",
			border:'1px solid #ccc',
			borderRadius:'5px',
			cursor:'point'
		}
		if(!detail.title) {
			return(
				<div>
					{this.state.hasActive?
						(<div className="Detail-init-render-result">
							<div className="title">数据加载中</div>
							<div className="message">请稍后...</div>
						</div>
					):
					(
						<div className="Detail-init-render-result">
							<img className="cen-image" src="https://cdn-xcxcustom.weiyoubot.cn/20210526/f6cf229bd87b77f3c7f0e5d6b855ea4d.png"></img>
							<div className="cen-title">活动不存在</div>
							<span className="cen-warn">可能已经被删除</span>
							<div className='back-home-btn'>
								<button type="primary" style={miniBtn} onClick={()=>{
									window._hmt.push(['_trackPageview',  '/manage/myEnroll'])
									this.props.history.push('/manage/myEnroll')
								}}>返回首页</button>
							</div>
						</div>
					)
				}	
                </div>
			)
		}
		
		return(
			<div className="btn_bottom" style={{paddingTop: detail && detail.user_style != 2 ? '50px' : '0'}}>
                {
                    detail.user_style != 2 ? this.renderHeader(detail) : null
                }
				{
					this.renderWxCode(detail)
				}				
                {
                    this.renderBanner(detail)
                }
                {
                    this.renderContent1(detail)
                }
				{
					detail.censor_status == 3 ? this.renderCensorModal(detail) : null
				}
                {
                    detail.user_style != 2 && !detail.co_create ? this.renderOwner(detail) : ''
                }
				{
					this.renderMsgDialog(detail)
				}
				{
                    detail.user_style != 2 && detail.co_create == 1 ? this.renderEnrollGroup(detail) : ''
                }
				{
					this.renderSheet(detail)
				}			 
                {
                    !!detail.group_qr ? <List className="qun-view">
                        <Item
                            arrow="horizontal"
                            thumb={
                                <Badge text={1}>
                                    <img style={{width: 30, height: 30}} src={img_qun}/>
                                </Badge>
                            }
                            multipleLine
                            onClick={() => {
                                React.$wx.previewImage({
                                    current: detail.group_qr, // 当前显示图片的http链接
                                    urls: [detail.group_qr] // 需要预览的图片http链接列表
                                });
                            }}
                            extra={<Button inline={"true"} size="small">加入</Button>}
                        >
                            官方交流群 <Brief>邀请你进群参加交流讨论</Brief>
                        </Item>
                    </List> : null
                }

                {
                    this.renderDescribe(detail)
                }
                {
                	this.renderSharePanel('none',detail)
                }
                {
                	this.renderDetailQrCode()
                }
                {
                	this.renderHomePageQrCode(detail)
                }
				{
					this.renderEnrollToken()
				}
				{
					this.renderLink(detail) //微信短链弹窗
				}
				{
					this.renderWxLink(detail)
				}
                {
                	this.renderShareTips()
                }
                {
                	this.renderMinProgramRole()
                }
                {
                	this.renderTokenPanel(detail)
                }
				{
					detail.is_move && this.state.shareList.length ? this.renderMove(detail) : null
				}
				{
					this.renderSubmitRead(detail)
				}
                {
                    ((detail.user_visible == 1 || detail.user_visible == 2) || detail.is_owner || detail.is_admin) ?
                        <UserList eid={this.props.match.params.eid} themColor={this.state.themColor} is_await={detail.is_await} wxJumpPath={this.wxJumpPath} openMsg={()=>{
							this.child.setDialog('open')
						}}/> : null
                }
                {
					this.renderNotice(detail)
				}
				{
					detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1 && (detail.is_admin || detail.is_owner) ? this.renderWxGroup(detail) : null
				}
                {/*</PullToRefresh>*/}
				{
					!this.state.isWeixin &&!Config.TOKEN ? <div className="fix_bottom_weixin" onClick={() => { 
						if (this.state.isMobile && WxApi.isOtherEnvironments()){ //如果是在支付宝内 or 云闪付置浏览器中打开
							this.props.history.push('/login')
						}else if(this.state.isMobile && !this.state.isUnionPay){
							this.getTicket(detail)
						}else{
							this.showMinProgram()
						}
					}}></div> : null
				}
                <div className="fix_bottom" >
                    <div className="share-panel">
							<button className="create btn" onClick={() => { 
								window._hmt.push(['_trackEvent', 'button_to_homepage','', '活动详情底部菜单点击主页', '']); //百度统计
								React.$wx.jumpNewPath.call(this,'/h/'+detail.owner_unionid)	
							}}>
								<i className="create-icon icon-style"></i>
								<div>主页</div>
							</button>
                    	<button className="share btn" onClick={() => {
								if((detail.share == 2 || detail.share == 3) && (!detail.is_owner && !detail.is_admin)){
									Toast.fail("发起者设置了不允许他人分享", 2);
									return;
								}else if(detail.share == 1 && !detail.info_id){
									Toast.fail("发起者设置了仅参与者才能分享", 2);
									return;
								}else if(detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1 && !(detail.is_owner || detail.is_admin)){
									Toast.fail("发起人设置了“指定群可参与”时，不支持分享活动", 2);
									return;
								}
								this.showSharePanel('block')
							}}>
                    		<i className="share-icon icon-style"></i>
                    		<div>分享</div>
                    	</button>
						<button className="message-box-panel">
							<div className="share btn message-btn" onClick={(e) => {
								e.stopPropagation()
								this.showActionSheet(1) 
								}}>
								<i className="message-icon icon-style"></i>
								<div>留言</div>
							</div>
						</button>

                    </div>
                    {
                        this.renderButton(detail)
                    }
					{
						this.renderSeat(detail)
					}
					{
						this.reservePro(detail)
					}
                </div>
            </div>
		);
	}
}

export default App;
