
import {observable, action, computed} from "mobx";

class LoadingStore {
	
	@observable httpStack;

	@computed get isLoading() {
		return this.httpStack>0;
	};

	constructor() {
		this.httpStack = 0;
	}

	@action
	pushStack = () => {
        this.httpStack++
	};

	@action
	popStack = () => {
        this.httpStack--
	};
}

const loadingStore = new LoadingStore();

export {loadingStore};