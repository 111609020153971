// 多人团队
import React, {Component} from 'react';
import Label from "../label";
import {List, Modal, Toast} from 'antd-mobile';
import { withRouter } from 'react-router-dom';
import right_arrow from '../../../res/img/right_arrow.png';

class Team extends Component {
    state={
        teamReqList:[],
        originalTeamReq:[]
    };
    componentDidMount(){                   
        let teamReqList = sessionStorage.getItem('teamReqList')
        if(teamReqList){ //从添加团队人数页面传过来的数据         
            let teamIfnfo = JSON.parse(teamReqList)
            if(teamIfnfo.field_key == this.props.field_key){
                this.setState({ teamReqList : teamIfnfo.teamList})                
                this.props.onFieldChange(teamIfnfo.teamList, this.props.field_key);  
            }                     
        }   
        this.setState({
            originalTeamReq:this.props.team_req
        })   
    };
    componentWillReceiveProps (nextProps) {          
        this.setState({
            originalTeamReq:nextProps.team_req
        })
    }
    render(){           
        return (    
            <div className="enroll-label" onClick={()=>{ 
                if(this.props.disabled){
                    Toast.info('不支持修改')
                    return
                }              
                if(this.props.currentInfoId && this.props.team_limit_type == 1 && (!this.props.field_value || !this.props.field_value.length)){
                    Modal.alert('温馨提示','此字段的名称已经与报名数量同步扣减，无法增加名额',[{ text: '我知道了' }])
                    return
                }                
                let  field_value =  this.props.field_value || []  
                if(this.state.teamReqList.length){
                    field_value = this.state.teamReqList
                }                  
                let params = {
                    team_limit: this.props.team_limit,
                    team_req: field_value,
                    field_key: this.props.field_key,
                    team_limit_type: this.props.team_limit_type,
                    currentInfoId: this.props.currentInfoId && this.props.team_limit_type == 1,
                }
                                                        
                let data = this.state.originalTeamReq.map(item=>{ //数据需要处理一下                            
                    item.type_value = item.value
                    item.value = ''
                    return item
                })  
                if(!params.team_req || !params.team_req.length){ //如果都没值，则取原数据                      
                    params.team_req.push(data) //原始值
                }      
            
                sessionStorage.setItem('teamPersonalMsg',JSON.stringify(params))                                                              
                sessionStorage.setItem('originalTeamReq',JSON.stringify(data))                                                            
                this.props.history.push('/team-personal')
            }}>                           
                <div className='am-list-header team-header-label'>
                    <div className="gray label-width">
                        <span>{this.props.field_name || "未知字段名"} </span> 
                        {
                            this.props.require ? <span className="Data-entry-require">*</span> : ''
                        }
                        {
                            this.props.field_value && this.props.field_value.length ?
                            <span className="team-num">（{this.props.field_value.length}人{ this.props.team_limit_type ? '，占用名额' : '' }）</span>
                            : ''
                        }   
                        {
                            this.props.field_desc ? <label className="team field-desc label-width">{this.props.field_desc}</label> : ''                    
                        }                                                                
                    </div>                  
                    <img className="right-icon" src={right_arrow}/>
                </div>
                
                <div>
                    {
                        this.props.field_value && this.props.field_value.length ?
                        <div className="team-info flex-start-box">
                            {
                                this.props.field_value.map((item,index)=>{
                                    return index < 3 ? <div key={index} className="team-list">
                                        {item.map((data,i)=>{
                                            if(i < 2){
                                                return <div className="team-msg" key={i}>{data.value}</div>
                                            }                                           
                                        })}
                                    </div> : ''
                                }) 
                            }
                        </div>                       
                        : <div className="team-group-box">点此添加</div>
                    }      
                </div>                                                    
            </div>    
        )
    }
}

export default withRouter(Team);