import Detail from './detail';
import Group from './group';
import MyEnroll from './myEnroll'
import UserCenter from './userCenter'
import HomePage from './homePage'
import Auth from './auth'

export {
    Group,
    Detail,
    MyEnroll,
    UserCenter,
    HomePage,
    Auth
};